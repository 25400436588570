// Global
import {
  GetStaticComponentProps,
  Text,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import graphQLClientFactory from 'lib/graphql-client-factory';
import { useI18n } from 'next-localization';
import { useState, useEffect } from 'react';
import { tv } from 'tailwind-variants';

// Lib
import { ComponentProps } from 'lib/component-props';
import { DartComponents } from 'lib/templates/Feature.Dart.model';
import { BasePageFields } from 'lib/templates/Foundation.Dart.model';
import { Common } from 'lib/templates/Foundation.Dart.model';

// Local
import FooterQuery from './Footer.graphql';
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import GoogleMaterialSymbol from 'helpers/GoogleMaterialSymbol/GoogleMaterialSymbol';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import SVG from 'helpers/SVG/SVG';
import TextTokenReplacer from 'helpers/TextTokenReplacer/TextTokenReplacer';
import { stripHtml } from 'lib/utils/regex';

export type FooterGQLinks = ComponentProps &
  DartComponents.FooterJson &
  DartComponents.Footer & {
    staticProps?: {
      footer?: DartComponents.FooterJson;
    };
  } & { searchInfo?: BasePageFields.Search };

const tailwindVariants = tv({
  compoundSlots: [
    {
      slots: ['mainNavItemLink', 'secondaryNavItemLink'],
      class: [
        'cursor-pointer',
        'flex',
        'group',
        'items-center',
        'self-stretch',
        'md:self-auto',
        'min-h-components-header-spacing-small-nav-main-min-height',
        'px-components-footer-brand-spacing-small-footer-link-padding-x',
        'py-components-footer-brand-spacing-small-footer-link-padding-y',
        'mb-0',
        'md:min-h-0',
        'md:px-0',
        'md:py-0',
        'md:mb-components-footer-brand-spacing-large-link-margin-bottom',
        'md:active:text-components-footer-brand-color-category-link-default',
        'md:hover:text-components-footer-brand-color-category-link-hover',
        'md:focus:text-components-footer-brand-color-category-link-default',
        'md:hover:focus:text-components-footer-brand-color-category-link-default',
        '[&>div]:grow',
      ],
    },
  ],
  slots: {
    base: ['flex', 'flex-col', 'items-center', 'justify-end'],
    brandContainer: [
      'bg-components-footer-brand-color-bg',
      'flex',
      'flex-col',
      'md:flex-row',
      'gap-components-footer-brand-spacing-large-column-margin-bottom',
      'items-center',
      'justify-end',
      'md:justify-between',
      'py-components-footer-brand-spacing-small-padding-y',
      'px-components-footer-brand-spacing-small-padding-x',
      'md:gap-components-footer-brand-spacing-large-logo-margin-bottom',
      'md:py-components-footer-brand-spacing-large-padding-y',
      'md:px-components-footer-brand-spacing-large-padding-x',
    ],
    brandNavContainer: [
      'flex',
      'flex-col',
      'gap-components-footer-brand-spacing-small-column-margin-right',
      'items-start',
      'self-stretch',
      'md:basis-2/3',
    ],
    brandSocialContainer: [
      'flex',
      'flex-col',
      'md:items-start',
      'gap-components-footer-brand-spacing-large-link-margin-bottom',
      'items-center',
      'self-stretch',
    ],
    copyRightTextContainer: [
      'color-components-footer-global-color-disclaimer-text',
      'text-bodySans-xSmall',
      'leading-bodySans-xSmall',
      'font-bodySans-xSmall',
    ],
    footerLinkContainer: [
      'flex',
      'gap-spacing-space-between-large-1',
      'group',
      'items-center',
      'max-w-[141px]',
      'md:max-w-none',
      //'text-components-footer-brand-spacing-category-link-default', // TODO: Token does not exist.
      'text-bodySans-small',
    ],
    footerLinkIcon: [
      'opacity-0',
      'transition-opacity',
      'duration-300',
      'group-hover:opacity-100',
      'group-active:opacity-100',
    ],
    footerLinkWrapper: [
      'flex',
      'font-bodySans-small',
      'group',
      'items-center',
      'text-bodySans-small',
    ],
    footerRawHtmlWrapper: ['cursor-pointer'],
    footerTextItem: [
      'py-components-text-field-input-padding-y',
      '[&>div]:!cursor-default',
      '!self-stretch',
    ],
    footerTextItemWidth: ['w-full'],
    globalContainer: [
      'bg-components-footer-global-color-bg',
      'flex',
      'flex-col',
      'md:flex-row',
      'md:justify-between',
      'gap-components-footer-brand-spacing-small-column-margin-bottom',
      'md:items-start',
      'px-components-footer-global-spacing-small-padding-x',
      'py-components-footer-global-spacing-small-padding-y',
      'md:px-components-footer-global-spacing-large-padding-x',
    ],
    globalContainerLeft: [
      'flex',
      'flex-col',
      'gap-components-footer-global-spacing-small-logo-margin-bottom',
      'justify-between',
      'items-center',
      'md:self-stretch',
      'md:items-start',
    ],
    globalContainerRight: [
      'flex',
      'gap-components-footer-global-spacing-large-column-margin-right',
      'items-start',
      'justify-center',
      'md:self-stretch',
      'md:justify-start',
    ],
    globalContainerRightColumn: [
      'flex',
      'flex-col',
      'gap-components-footer-global-spacing-small-link-margin-bottom',
      'items-start',
    ],
    linkContainer: ['group', 'flex', 'flex-row', 'items-center'],
    linkIcon: [
      'opacity-0',
      'group-hover:opacity-100',
      'group-active:opacity-100',
      'md:group-active:fill-components-footer-brand-color-category-link-default',
      'fill-components-footer-brand-color-category-link-default',
      'md:fill-components-footer-brand-color-category-link-hover',
      'md:group-hover:group-focus:fill-components-footer-brand-color-category-link-default',
    ],
    mainNavContainer: [
      'w-full',
      'md:flex',
      'md:flex-1',
      'pr-components-footer-brand-spacing-small-footer-link-padding-right',
      'pl-components-footer-brand-spacing-small-footer-link-padding-left',
      'md:px-0',
      'justify-end',
    ],
    mainNavItemContainer: ['flex', 'flex-col', 'items-start', 'stretch-self', 'md:basis-1/4'],
    mainNavItemLink: [
      'bg-components-footer-brand-color-bg',
      'border-b',
      'border-components-footer-brand-color-bg',
      'font-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
      'self-stretch',
      'text-components-footer-brand-color-category-link-default',
      'md:pt-0',
      'md:self-auto',
    ],
    navItemLinkIcon: [
      'fill-components-footer-brand-color-icon-icon-default',
      'float-right',
      'md:group-active:fill-components-footer-brand-color-icon-icon-default',
      'md:group-hover:fill-components-footer-brand-color-icon-icon-default',
      'md:group-hover:fill-components-footer-brand-color-category-link-hover',
      'md:group-focus:fill-components-footer-brand-color-icon-icon-default',
      'md:group-hover:group-focus:fill-components-footer-brand-color-icon-icon-default',
      'md:float-none',
      'md:hidden',
    ],
    secondaryNavContainer: ['items-start', 'w-full'],
    tertiaryNavContainer: ['items-start', 'md:hidden'],
    secondaryNavItemContainer: [
      'flex',
      'flex-col',
      'items-start',
      'stretch-self',
      'text-components-footer-brand-color-category-link-default',
    ],
    secondaryNavItemLink: ['font-bodySans-small', 'leading-bodySans-small', 'text-bodySans-small'],
    socialIcon: [
      'fill-components-footer-brand-color-social-icon-default',
      'active:fill-components-footer-brand-color-social-icon-pressed',
      'hover:fill-components-footer-brand-color-social-icon-hover',
      'duration-300',
    ],
    socialIconsContainer: [
      'content-center',
      'flex',
      'flex-wrap',
      'gap-4',
      'items-center',
      'justify-center',
    ],
    socialTitleContainer: [
      'font-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'text-components-footer-brand-color-title-text',
    ],
  },
  variants: {
    isMainOpen: {
      false: {
        secondaryNavContainer: ['hidden', 'md:block'],
      },
      true: {
        secondaryNavContainer: ['block'],
      },
    },
    isSecondaryOpen: {
      false: {
        tertiaryNavContainer: ['hidden'],
      },
      true: {
        tertiaryNavContainer: ['block'],
      },
    },
  },
});

const Footer = (staticProps: FooterGQLinks): JSX.Element => {
  const [activeIndexes, setActiveIndexes] = useState<string[]>([]);
  const [windowSize, setWindowSize] = useState<number>();
  const { sitecoreContext } = useSitecoreContext();

  const searchInfoEnums = staticProps?.searchInfo?.fields?.pageType as Common.Enums.dartEnum;
  const i18n = useI18n();
  const socialIconMsg = i18n.t('FollowBrandOn');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWindowSize(window.innerWidth);
    }

    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    if (windowSize && windowSize > 1440) {
      setActiveIndexes([]);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowSize]);

  if (!staticProps) return <></>;

  const { componentName, dataSource } = staticProps?.rendering || {};
  const {
    copyRightText,
    enableSocial,
    footerLinks,
    logoImage,
    logoUrl,
    mainNavigations,
    socialLinks,
    socialTitle,
  } = staticProps?.staticProps?.footer || {};

  const footerLinksEven = footerLinks?.targetItems?.filter(
    (_footerLink, index: number) => index % 2 === 0
  );

  const footerLinksOdd = footerLinks?.targetItems?.filter(
    (_footerLink, index: number) => index % 2 !== 0
  );

  const {
    base,
    brandContainer,
    brandNavContainer,
    brandSocialContainer,
    copyRightTextContainer,
    footerLinkContainer,
    footerLinkIcon,
    footerLinkWrapper,
    footerRawHtmlWrapper,
    footerTextItem,
    footerTextItemWidth,
    globalContainer,
    globalContainerLeft,
    globalContainerRight,
    globalContainerRightColumn,
    linkContainer,
    linkIcon,
    mainNavContainer,
    mainNavItemContainer,
    mainNavItemLink,
    navItemLinkIcon,
    secondaryNavItemContainer,
    secondaryNavItemLink,
    socialIcon,
    socialIconsContainer,
    socialTitleContainer,
  } = tailwindVariants();

  const handleDropdown = (id: string, event: React.MouseEvent<HTMLAnchorElement>) => {
    if (windowSize && windowSize < 1440) {
      event.preventDefault();
    }
    if (activeIndexes.includes(id)) {
      setActiveIndexes(activeIndexes.filter((item) => item !== id));
    } else {
      setActiveIndexes([...activeIndexes, id]);
    }
  };

  return (
    <div className={base()} data-component="authorable/landmarks/footer">
      <Container fullWidth>
        <>
          <div className={brandContainer()}>
            {enableSocial?.jsonValue && (
              <div className={brandSocialContainer()}>
                <div className={socialTitleContainer()}>
                  <Text encode={false} field={socialTitle?.jsonValue} />
                </div>
                <div className={socialIconsContainer()}>
                  {socialLinks?.targetItems?.map((socialLink) => {
                    const { socialIcon: socialIconImage, socialUrl } = socialLink || {};
                    return (
                      <LinkWrapper
                        field={socialUrl?.jsonValue}
                        gtmEvent={{
                          event: 'link',
                          type: 'footer',
                          'gtm.element.dataset.gtmLinkName': socialTitle?.jsonValue?.value,
                          'gtm.element.dataset.gtmDatasourceId': dataSource,
                          'gtm.element.dataset.gtmComponentName': componentName,
                        }}
                        key={socialLink?.id}
                      >
                        <SVG
                          className={socialIcon()}
                          url={socialIconImage?.jsonValue?.value?.src}
                        />
                        <Text
                          className="sr-only"
                          encode={false}
                          field={{
                            value: `${socialIconMsg.replace(
                              '{BRAND}',
                              `${sitecoreContext.site?.name}`
                            )} ${socialIconImage?.jsonValue?.value?.alt}`,
                          }}
                          tag="span"
                        />
                      </LinkWrapper>
                    );
                  })}
                </div>
              </div>
            )}
            <div className={brandNavContainer()}>
              {mainNavigations?.targetItems?.length ? (
                <ol className={mainNavContainer()}>
                  {mainNavigations?.targetItems?.map((mainNavItem) => {
                    const isMainOpen = activeIndexes.includes(mainNavItem?.id ?? '');
                    const { secondaryNavContainer } = tailwindVariants({
                      isMainOpen: isMainOpen,
                    });
                    return (
                      <li className={mainNavItemContainer()} key={mainNavItem?.id}>
                        {mainNavItem?.secondaryNavigations?.targetItems.length ? (
                          <>
                            {mainNavItem?.navigationLink?.jsonValue?.value?.href ? (
                              <LinkWrapper
                                className={mainNavItemLink()}
                                field={mainNavItem?.navigationLink?.jsonValue}
                                onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
                                  handleDropdown(mainNavItem?.id ?? '', event)
                                }
                              >
                                <GoogleMaterialSymbol
                                  className={`${linkIcon()} hidden md:block`}
                                  icon="chevron_right"
                                />
                                <GoogleMaterialSymbol
                                  className={navItemLinkIcon()}
                                  icon={isMainOpen ? 'expand_less' : 'expand_more'}
                                />
                              </LinkWrapper>
                            ) : (
                              <div
                                className={footerTextItem()}
                                onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                                  handleDropdown(
                                    mainNavItem?.id ?? '',
                                    event as unknown as React.MouseEvent<HTMLAnchorElement>
                                  )
                                }
                              >
                                <div className={mainNavItemLink()}>
                                  <span className={footerTextItemWidth()}>
                                    <Text
                                      field={mainNavItem?.navigationTitle?.jsonValue}
                                      aria-label={stripHtml(
                                        mainNavItem?.navigationTitle?.jsonValue?.value as string
                                      )}
                                    />
                                    <GoogleMaterialSymbol
                                      className={navItemLinkIcon()}
                                      icon={isMainOpen ? 'expand_less' : 'expand_more'}
                                    />
                                  </span>
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          <div className={linkContainer()}>
                            <LinkWrapper
                              className={mainNavItemLink()}
                              field={mainNavItem?.navigationLink?.jsonValue}
                              gtmEvent={{
                                event: 'navigation_click',
                                type: 'footer',
                                'gtm.element.dataset.gtmLinkName':
                                  mainNavItem?.navigationLink?.jsonValue?.value.text,
                                'gtm.element.dataset.gtmLinkUrl':
                                  mainNavItem?.navigationLink?.jsonValue?.value?.href,
                                'gtm.element.dataset.gtmDatasourceId': dataSource,
                                'gtm.element.dataset.gtmComponentName': componentName,
                              }}
                            >
                              <GoogleMaterialSymbol className={linkIcon()} icon="chevron_right" />
                            </LinkWrapper>
                          </div>
                        )}
                        {mainNavItem?.secondaryNavigations?.targetItems?.length ? (
                          <ol className={secondaryNavContainer()}>
                            {mainNavItem?.secondaryNavigations?.targetItems.map(
                              (secondaryNavItem) => {
                                if (
                                  !isMainOpen &&
                                  activeIndexes.includes(secondaryNavItem?.id ?? '')
                                ) {
                                  setActiveIndexes(
                                    activeIndexes.filter((item) => item !== secondaryNavItem?.id)
                                  );
                                }

                                const isSecondaryOpen =
                                  activeIndexes.includes(secondaryNavItem?.id ?? '') && isMainOpen;

                                const { tertiaryNavContainer } = tailwindVariants({
                                  isSecondaryOpen: isSecondaryOpen,
                                });

                                return (
                                  <li
                                    className={secondaryNavItemContainer()}
                                    key={secondaryNavItem?.id}
                                  >
                                    {secondaryNavItem?.navigations?.targetItems.length ? (
                                      <LinkWrapper
                                        className={secondaryNavItemLink()}
                                        field={secondaryNavItem?.navigationLink?.jsonValue}
                                        onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                                          handleDropdown(secondaryNavItem?.id ?? '', event);
                                        }}
                                        aria-label={stripHtml(
                                          secondaryNavItem?.navigationLink?.jsonValue?.value
                                            ?.text as string
                                        )}
                                      >
                                        <GoogleMaterialSymbol
                                          className={`${linkIcon()} hidden md:block`}
                                          icon="chevron_right"
                                        />
                                        <GoogleMaterialSymbol
                                          className={navItemLinkIcon()}
                                          icon={isSecondaryOpen ? 'remove' : 'add'}
                                        />
                                      </LinkWrapper>
                                    ) : (
                                      <div className={linkContainer()}>
                                        {secondaryNavItem?.navigationLink?.jsonValue?.value
                                          ?.href ? (
                                          <LinkWrapper
                                            className={secondaryNavItemLink()}
                                            field={secondaryNavItem?.navigationLink?.jsonValue}
                                            gtmEvent={{
                                              event: 'navigation_click',
                                              type: 'footer',
                                              'gtm.element.dataset.gtmLinkName':
                                                secondaryNavItem?.navigationLink?.jsonValue?.value
                                                  .text,
                                              'gtm.element.dataset.gtmLinkUrl':
                                                secondaryNavItem?.navigationLink?.jsonValue?.value
                                                  ?.href,
                                              'gtm.element.dataset.gtmDatasourceId': dataSource,
                                              'gtm.element.dataset.gtmComponentName': componentName,
                                            }}
                                            aria-label={stripHtml(
                                              secondaryNavItem?.navigationLink?.jsonValue?.value
                                                ?.text as string
                                            )}
                                          >
                                            <GoogleMaterialSymbol
                                              className={linkIcon()}
                                              icon="chevron_right"
                                            />
                                          </LinkWrapper>
                                        ) : (
                                          <div className={footerTextItem()}>
                                            <div className={secondaryNavItemLink()}>
                                              <Text
                                                field={secondaryNavItem?.navigationTitle?.jsonValue}
                                                aria-label={stripHtml(
                                                  secondaryNavItem?.navigationTitle?.jsonValue
                                                    ?.value as string
                                                )}
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                    {secondaryNavItem?.navigations?.targetItems.length ? (
                                      <ol className={tertiaryNavContainer()}>
                                        {secondaryNavItem?.navigations?.targetItems?.map(
                                          (secondaryNavItemChild) => (
                                            <li className="flex" key={secondaryNavItemChild.id}>
                                              <div className={linkContainer()}>
                                                <LinkWrapper
                                                  className={secondaryNavItemLink()}
                                                  field={
                                                    secondaryNavItemChild?.navigationLink?.jsonValue
                                                  }
                                                  gtmEvent={{
                                                    event: 'navigation_click',
                                                    type: 'footer',
                                                    'gtm.element.dataset.gtmLinkName':
                                                      secondaryNavItemChild?.navigationLink
                                                        ?.jsonValue?.value.text,
                                                    'gtm.element.dataset.gtmLinkUrl':
                                                      secondaryNavItemChild?.navigationLink
                                                        ?.jsonValue?.value?.href,
                                                    'gtm.element.dataset.gtmDatasourceId':
                                                      dataSource,
                                                    'gtm.element.dataset.gtmComponentName':
                                                      componentName,
                                                  }}
                                                  aria-label={stripHtml(
                                                    secondaryNavItemChild?.navigationLink?.jsonValue
                                                      ?.value?.text as string
                                                  )}
                                                >
                                                  <GoogleMaterialSymbol
                                                    className={linkIcon()}
                                                    icon="chevron_right"
                                                  />
                                                </LinkWrapper>
                                              </div>
                                            </li>
                                          )
                                        )}
                                      </ol>
                                    ) : null}
                                  </li>
                                );
                              }
                            )}
                          </ol>
                        ) : null}
                      </li>
                    );
                  })}
                </ol>
              ) : null}
            </div>
          </div>
          <div className={globalContainer()}>
            <div className={globalContainerLeft()}>
              <div>
                <LinkWrapper
                  field={logoUrl?.jsonValue}
                  gtmEvent={{
                    event: 'navigation_click',
                    'gtm.element.dataset.gtmDatasourceId': dataSource,
                    'gtm.element.dataset.gtmComponentName': componentName,
                  }}
                >
                  <ImageWrapper field={logoImage?.jsonValue} />
                </LinkWrapper>
              </div>
              <div className={copyRightTextContainer()}>
                <TextTokenReplacer
                  field={copyRightText?.jsonValue}
                  tokens={['{year}']}
                  tokenValues={[new Date().getFullYear().toString()]}
                />
              </div>
            </div>
            <div className={globalContainerRight()}>
              <div className={globalContainerRightColumn()}>
                {footerLinksEven?.map((footerLink) => {
                  const { ctaLink: navigationLink, rawHtml } = footerLink || {};

                  if (rawHtml?.jsonValue?.value) {
                    return (
                      <div className={footerLinkContainer()} key={footerLink?.id}>
                        <div
                          className={footerRawHtmlWrapper()}
                          dangerouslySetInnerHTML={{ __html: rawHtml?.jsonValue?.value }}
                        />
                        <GoogleMaterialSymbol className={footerLinkIcon()} icon="chevron_right" />
                      </div>
                    );
                  }

                  return (
                    <>
                      {navigationLink?.jsonValue?.value?.href && (
                        <div className={footerLinkContainer()} key={footerLink?.id}>
                          <LinkWrapper
                            className={footerLinkWrapper()}
                            field={navigationLink?.jsonValue}
                            gtmEvent={{
                              event: 'navigation_click',
                              type: 'footer',
                              'gtm.element.dataset.gtmLinkName':
                                navigationLink?.jsonValue?.value.text,
                              'gtm.element.dataset.gtmLinkUrl':
                                navigationLink?.jsonValue?.value?.href,
                              'gtm.element.dataset.gtmDatasourceId': dataSource,
                              'gtm.element.dataset.gtmComponentName': componentName,
                            }}
                          >
                            <GoogleMaterialSymbol
                              className={footerLinkIcon()}
                              icon="chevron_right"
                            />
                          </LinkWrapper>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
              <div className={globalContainerRightColumn()}>
                {footerLinksOdd?.map((footerLink) => {
                  const { ctaLink: navigationLink, rawHtml } = footerLink || {};

                  if (rawHtml?.jsonValue?.value) {
                    return (
                      <div className={footerLinkContainer()} key={footerLink?.id}>
                        <div
                          className={footerRawHtmlWrapper()}
                          dangerouslySetInnerHTML={{ __html: rawHtml?.jsonValue?.value }}
                        />
                        <GoogleMaterialSymbol className={footerLinkIcon()} icon="chevron_right" />
                      </div>
                    );
                  }
                  return (
                    <>
                      {navigationLink?.jsonValue?.value?.href && (
                        <div className={footerLinkContainer()} key={footerLink?.id}>
                          <LinkWrapper
                            className={footerLinkWrapper()}
                            field={navigationLink?.jsonValue}
                            gtmEvent={{
                              event: 'navigation_click',
                              type: 'footer',
                              'gtm.element.dataset.gtmLinkName':
                                navigationLink?.jsonValue?.value.text,
                              'gtm.element.dataset.gtmLinkUrl':
                                navigationLink?.jsonValue?.value?.href,
                              'gtm.element.dataset.gtmDatasourceId': dataSource,
                              'gtm.element.dataset.gtmComponentName': componentName,
                            }}
                          >
                            <GoogleMaterialSymbol
                              className={footerLinkIcon()}
                              icon="chevron_right"
                            />
                          </LinkWrapper>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      </Container>
      <input
        type="hidden"
        name="hdnPageType"
        id="hdnPageTypeName"
        value={searchInfoEnums?.fields?.value?.value}
      />
      <input
        type="hidden"
        name="hdnhideFromSearch"
        id="hdnhideFromSearch"
        value={staticProps?.searchInfo?.fields?.hideFromSearch?.value?.toString()}
      />
    </div>
  );
};

export default Footer;

export const getStaticProps: GetStaticComponentProps = async (rendering, layoutData) => {
  const graphQLClient = graphQLClientFactory({});
  const result = await graphQLClient.request<unknown>(FooterQuery, {
    datasource: rendering.dataSource,
    params: rendering.params,
    language: layoutData?.sitecore?.context?.language,
  });

  return {
    staticProps: result,
    searchInfo: layoutData?.sitecore?.route as BasePageFields.Search,
  };
};

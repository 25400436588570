// Global
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';

// Lib
import { ComponentProps } from 'lib/component-props';
import { CardComponents, SearchComponents } from 'lib/templates/Feature.Dart.model';
import { ItemEx } from 'lib/templates/_.Sitecore.Override';

// Local
import PDFListWithSearchWidget from 'components/PDFListWithSearch';
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import { useRealPathName } from 'lib/utils/use-real-pathname';
import { GetStaticComponentProps, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import graphQLClientFactory from 'lib/graphql-client-factory';
import ArticleListingItemQuery from './PDFResultsListwithSearch.graphql';
import { ArticleResultsListingItemName } from './PDFResultsListwithSearch.types';
import { sendGTMEvent } from '@next/third-parties/google';

export type PDFResultsListProps = ComponentProps &
  SearchComponents.PdfResultsListWithSearch.PdfResultsListWithSearch & {
    ArticleResultsListingItem: ArticleResultsListingItemName;
  };

export type Badge = ItemEx & CardComponents.ProductCard.Badge;

const PDFResultsListwithSearch = (props: PDFResultsListProps): JSX.Element => {
  const [count, setCount] = useState(0);
  const { sitecoreContext } = useSitecoreContext();
  const router = useRouter();
  const path = useRealPathName();

  useEffect(() => {
    setCount((c) => c + 1);
  }, [router]);

  // Add ref for the GTM Event
  const hasFired = useRef(false);

  useEffect(() => {
    // Function to send the GTM event
    sendGTMEvent({ event: 'switcher', type: 'click', value: 'grid' });
    hasFired.current = true;
  }, []); // Empty dependency array ensures it runs only once on mount

  if (!props?.fields) return <></>;

  const PDFResultsData = {
    resultsPerPage: props?.fields?.resultsPerPage,
    noSearchResult: props?.fields?.noSearchResult,
    filterLabel: props?.fields?.filterLabel,
    sortLabel: props?.fields?.sortLabel,
    filterButtonLabel: props?.fields?.filterButtonLabel,
    sortByLabel: props?.fields?.sortByLabel,
    primaryCTAColor: props?.fields?.primaryCTAColor,
    primaryCTAType: props?.fields?.primaryCTAType,
    listingPageName:
      sitecoreContext?.route?.templateId === '75873e02-6de2-467f-bce6-1ec481e48829'
        ? sitecoreContext?.route?.name
        : props?.ArticleResultsListingItem?.layout?.item?.ancestors[0]?.name,
  };

  // Unique id for component
  const id = props?.params?.RenderingIdentifier;

  return (
    <Container>
      <div
        data-component="layout/pdfresultslistwithsearch"
        id={id ? id : undefined}
        tabIndex={id ? -1 : 1}
      >
        <div>
          <PDFListWithSearchWidget {...PDFResultsData} key={path + count} rfkId={'rfkid_13'} />
        </div>
      </div>
    </Container>
  );
};

export const getStaticProps: GetStaticComponentProps = async (_rendering, layoutData) => {
  const graphQLClient = graphQLClientFactory({});
  const replacementString =
    layoutData?.sitecore?.context?.itemPath?.toString().substring(0, 7) || '';
  const currentLanguage = `/${layoutData?.sitecore?.context?.language?.toLocaleLowerCase()}/`;
  const result = await graphQLClient.request<unknown>(ArticleListingItemQuery, {
    routePath:
      replacementString === currentLanguage
        ? layoutData?.sitecore?.context?.itemPath?.toString().replace(replacementString, '/')
        : layoutData?.sitecore?.context?.itemPath,
    language: layoutData?.sitecore?.context?.language,
    site: layoutData?.sitecore?.context?.site?.name,
  });

  return {
    ArticleResultsListingItem: result,
  };
};

export default PDFResultsListwithSearch;

// Global
import { RichTextField, Text, TextField } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';

// Lib
import { ComponentProps } from 'lib/component-props';
import { DartComponents } from 'lib/templates/Feature.Dart.model';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';

export type MediaVideoProps = ComponentProps & DartComponents.MediaVideo.MediaVideo;

const MediaVideo = (props: MediaVideoProps): JSX.Element => {
  const id = props?.params?.RenderingIdentifier;

  return (
    <div data-component="authorable/MedioVideo" id={id ? id : undefined}>
      <Container>
        <div>
          <div>
            {props?.fields ? (
              <>
                <Text encode={false} field={props?.fields?.caption as TextField} tag="h2" />
                <RichTextA11yWrapper field={props?.fields?.description as RichTextField} />
              </>
            ) : null}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default MediaVideo;

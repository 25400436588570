// Global
import { useSitecoreContext, LinkField, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { Options, Splide } from '@splidejs/react-splide';
import React, { useEffect, useRef, useState } from 'react';
import { tv } from 'tailwind-variants';

// Lib
import { ComponentProps } from 'lib/component-props';
import { ProductAndArticleComponent, CardComponents } from 'lib/templates/Feature.Dart.model';
import { DartPages } from 'lib/templates/Project.Dart.model';
import { ItemEx } from 'lib/templates/_.Sitecore.Override';

// Local
import ProductCarousel from 'components/authorable/Carousel/ProductCarousel';
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import Label from 'helpers/Label/Label';
import ProductEan from 'helpers/MikMak/ProductEan';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import ShareButton from 'helpers/ShareButton/ShareButton';
import { useRealPathName } from 'lib/utils/use-real-pathname';
import Adimo from 'helpers/Adimo/Adimo';
import LegalDisclaimer from 'helpers/LegalDisclaimer/LegalDisclaimer';

export type ProductInformationProps = ComponentProps;

export type ProductInformation = ItemEx &
  ProductAndArticleComponent.ProductInformation.ProductInformation;

export type BadgeInformation = ItemEx & CardComponents.ProductCard.Badge;

export type CategoryInfo = ItemEx & DartPages.CategoryListPage;

export type SecondaryImages = ProductAndArticleComponent.ProductInformation.SecondaryImage &
  ProductAndArticleComponent.ProductInformation.SecondaryVideo;

const tailwindconstiants = tv({
  slots: {
    base: [
      'product-information-wrapper',
      'flex-col',
      'flex',
      'gap-components-product-information-spacing-small-carousel-space-between',
      'px-components-product-information-spacing-small-padding-x',
      'py-components-product-information-spacing-small-padding-y',
      'md:flex-row',
      'md:gap-components-product-information-spacing-large-carousel-space-between',
      'md:px-components-product-information-spacing-large-padding-x',
      'md:py-components-product-information-spacing-large-padding-y',
    ],
    productInformationBG: ['bg-components-product-information-color-bg'],
    labelContainer: ['product-label'],
    productInformationLeftWrapper: ['product-information-left', 'md:max-w-[50%]'],
    productInformationRightWrapper: [
      'product-information-right',
      'flex',
      'flex-col',
      'justify-center',
      'md:max-w-[50%]',
    ],
    productInformationTitleWrapper: [
      'product-title',
      'font-header-small-large',
      'leading-header-small-large',
      'text-header-small-large',
      'text-components-product-information-color-headline',
      'mb-components-product-information-spacing-small-title-margin-bottom',
      'md:font-header-large-large',
      'md:leading-header-large-large',
      'md:text-header-large-large',
      'md:mb-components-product-information-spacing-large-title-margin-bottom',
    ],
    productInformationTopRightWrapper: [
      'productInformationTopRightWrapper',
      'flex',
      'justify-between',
      'items-center',
      'mb-components-product-information-spacing-small-title-margin-bottom',
      'md:mb-components-product-information-spacing-large-label-margin-bottom',
    ],
    productInformationMiddleRightWrapper: [],
    productInformationBottomRightWrapper: [],
    productInformationCTAWrapper: [
      'py-components-product-information-spacing-large-button-margin-y',
    ],
    productInformationDescription: [
      'product-description',
      'font-bodySans-medium',
      'leading-bodySans-medium',
      'text-bodySans-medium',
      'text-components-product-information-color-body',
      'pt-components-product-information-spacing-small-body-margin-top',
      '[&_p]:p-0',
      'md:pt-components-product-information-spacing-large-body-margin-top',
    ],
    productInformationAdditionalDetails: [
      'font-bodySans-medium',
      'leading-bodySans-medium',
      'text-bodySans-medium',
      'mb-components-product-information-spacing-large-details-margin-bottom',
      'text-components-product-information-color-detail-text',
      '[&>strong]:font-bodySans-medium-semibold',
      '[&>strong]:leading-bodySans-medium-semibold',
      '[&>strong]:text-bodySans-medium-semibold',
    ],
    productInformationTopSlider: [
      'mb-components-product-information-spacing-large-image-margin-bottom',
      'w-full',
      'md:max-w-[630px]',
    ],
    productInformationBottomSlider: [
      'mb-spacing-space-between-large-4',
      'thumbnail-slider',
      'w-full',
      'md:max-w-[630px]',
    ],
    productInformationProgressBar: [
      'progress-bar',
      'bg-components-pagination-on-white-bg',
      'h-[4px]',
      'w-[160px]',
    ],
    btn: [
      'group',
      '[&>button]:h-[40px]',
      '[&>button]:w-[40px]',
      '[&>button]:sm:py-[0.1.5rem]',
      '[&>button]:sm:px-[0.1.5rem]',
      '[&>button]:py-[0.7rem]',
      '[&>button]:px-[0.7rem]',
      '[&>button]:!min-h-0',
      'md:[&>button]:w-8',
      'md:[&>button]:h-8',
    ],
    productInformationProgress: ['bg-components-pagination-on-white-accent', 'h-[4px]'],
    productPrimaryImage: ['product-primary-image'],
    productRoundedImages: ['rounded-themes-radius-large-image'],
    productInformationImages: ['!h-full', 'block', 'object-cover'],
    productInformationVideoThumbnail: [
      'absolute',
      'fill-components-product-information-color-play-icon',
      'left-1/2',
      'top-1/2',
      'h-[50px]',
      'transform -translate-x-1/2 -translate-y-1/2',
      'w-[50px]',
      '[&>*]:h-full',
      '[&>*]:w-full',
    ],
    productInformationProgressbarWrapper: [
      'flex',
      'flex-row',
      'gap-6',
      'items-center',
      'justify-center',
    ],
    productInformationSplideControls: ['splide__arrows', 'flex', 'flex-row', 'gap-2'],
    productInformationVideo: [
      'aspect-video',
      'h-full',
      '[&>*]:!h-full',
      '[&>*]:!w-full',
      '[&>*]:!rounded-themes-radius-small-image',
      'rounded-themes-radius-small-image',
      'max-w-[1200px]',
      'my-0',
      'mx-auto',
      'w-full',
      'md:rounded-themes-radius-large-image',
    ],
    productInformationRatingReviewWrap: [
      'flex',
      'flex-col',
      'gap-components-product-information-spacing-small-space-between',
      'items-start',
      'review-rating-wrapper',
      'mb-components-product-information-spacing-small-details-margin-bottom',
      'md:flex-row',
      'md:gap-0',
      'md:items-center',
    ],
    productInformationRatingWrap: [
      'rating-wrapper',
      'flex',
      'flex-row',
      'gap-spacing-space-between-large-2',
      'items-center',
      'mr-components-product-information-spacing-large-space-between',
    ],
    productInformationStarRating: ['flex', 'flex-row', 'ratings'],
    productInformationRatingNumber: [
      'product-rating',
      'rating-number',
      'text-bodySans-medium-semibold',
      'font-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'text-components-product-information-color-detail-text',
      'mt-0.5',
    ],
    productInformationReviews: [
      'review-wrapper',
      'text-components-button-color-outline-brand-default-text',
      'text-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'font-bodySans-medium-semibold',
      'md:mx-components-product-information-spacing-large-space-between',
    ],
    productInformationSubmitReviews: [
      'submit-wrapper',
      'text-components-button-color-outline-brand-default-text',
      'text-bodySans-medium-semibold',
      'font-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'md:ml-components-product-information-spacing-large-space-between',
    ],
    socialShareAlignment: ['ml-auto'],
    buyNowBtnWrapper: ['flex', 'items-center', '[&>button]:my-0'],
    legalDisclaimerText: [
      'pt-spacing-spacing-5',
      'self-start',
      'text-components-promo-color-default-body',
    ],
  },
});

const ProductInformation = (props: ProductInformationProps): JSX.Element => {
  const path = useRealPathName();
  const { sitecoreContext } = useSitecoreContext();

  const { badge, category, productInformation, subCategory } = sitecoreContext?.route?.fields || {};

  const badgeInfo = badge as BadgeInformation;
  const categoryInfo = category as CategoryInfo;
  const prodInfo = productInformation as ProductInformation;
  const subCategoryInfo = subCategory as CategoryInfo;
  const isMikMak = sitecoreContext?.integrationMikMak?.isMikMak === '1';
  const adimoMultiTouchPoint = sitecoreContext?.integrationEvidon?.isAdimoMultiTouchpoint === '1';
  const isAdimoPriceFirst = sitecoreContext?.integrationEvidon?.isAdimoPriceFirst === '1';
  const [initialLoad, setInitialLoad] = useState(0);

  useEffect(() => {
    const randomValue = Math.floor(Math.random() * 100) + 1;

    setInitialLoad(randomValue);
  }, [path]);

  const {
    headline,
    description,
    label,
    additionalDetails,
    socialShare,
    ratingAndReview,
    disclaimerText,
  } = prodInfo?.fields || {};

  const {
    base,
    productInformationLeftWrapper,
    productInformationRightWrapper,
    labelContainer,
    productInformationTitleWrapper,
    productInformationTopRightWrapper,
    productInformationMiddleRightWrapper,
    productInformationDescription,
    productInformationAdditionalDetails,
    productInformationRatingReviewWrap,
    productInformationRatingWrap,
    productInformationStarRating,
    productInformationBG,
    socialShareAlignment,
    buyNowBtnWrapper,
    legalDisclaimerText,
  } = tailwindconstiants({});

  const mainSliderRef = useRef<Splide>(null);
  const thumbnailSliderRef = useRef<Splide>(null);

  const mainSlider: Options = {
    type: 'fade',
    rewind: false,
    pagination: false,
    arrows: false,
    autoplay: false,
    resetProgress: false,
  };

  const thumbSlider: Options = {
    arrows: false,
    fixedWidth: 120,
    fixedHeight: 120,
    gap: 16,
    rewind: false,
    isNavigation: true,
    perPage: 3,
    pagination: false,
    breakpoints: {
      600: {
        fixedWidth: 84,
        fixedHeight: 84,
        gap: 10,
      },
    },
  };

  const id = props?.params?.RenderingIdentifier;
  const labelLinkInfo = label?.fields?.link as LinkField;

  const upcs = [
    ...new Set(prodInfo?.fields?.variantItems?.map((vi) => vi?.fields?.ean?.value)),
  ].join(',');
  // const adimoMultiTouchpointValues = prodInfo?.fields?.wheretoBuy?.value;
  const combinedValues = [
    prodInfo?.fields?.addtoCart?.value,
    prodInfo?.fields?.buyOnline?.value,
    prodInfo?.fields?.wheretoBuy?.value,
  ].join(', ');
  const buyOnline = prodInfo?.fields?.buyOnline?.value;
  return (
    <div
      className={productInformationBG()}
      data-component="authorable/productinformation"
      id={id ? id : undefined}
      tabIndex={id ? -1 : 1}
    >
      <Container>
        <div className={base()}>
          <div className={productInformationLeftWrapper()}>
            <ProductCarousel
              mainSliderRef={mainSliderRef}
              thumbnailSliderRef={thumbnailSliderRef}
              props={prodInfo}
              mainSlider={mainSlider}
              thumbSlider={thumbSlider}
              key={path + initialLoad}
            ></ProductCarousel>
          </div>
          <div className={productInformationRightWrapper()}>
            <div className={productInformationTopRightWrapper()}>
              {labelLinkInfo?.value?.text && (
                <Label
                  className={labelContainer()}
                  backgroundColor="bg-components-product-information-color-bg"
                  color={'white'}
                  link={labelLinkInfo}
                />
              )}
              {socialShare?.value && (
                <div className={socialShareAlignment()}>
                  {' '}
                  <ShareButton />
                </div>
              )}
            </div>
            <div className={productInformationMiddleRightWrapper()}>
              {headline?.value && (
                <div className={productInformationTitleWrapper()}>
                  <Text encode={false} field={headline} tag="h2" />
                </div>
              )}
              {ratingAndReview?.value && (
                <div className={productInformationRatingReviewWrap()}>
                  <div className={productInformationRatingWrap()}>
                    <div className={productInformationStarRating()}>
                      <div id="pr-reviewsnippet"></div>
                    </div>
                  </div>
                </div>
              )}
              {additionalDetails?.value && (
                <div className={productInformationAdditionalDetails()}>
                  <RichTextA11yWrapper field={additionalDetails}></RichTextA11yWrapper>
                </div>
              )}
              {isMikMak && (
                <div className={buyNowBtnWrapper()}>
                  <ProductEan eans={upcs} />
                </div>
              )}
              {!isMikMak && (
                <>
                  {adimoMultiTouchPoint && (
                    <div className={buyNowBtnWrapper()}>
                      <Adimo touchpointIDs={combinedValues} isMultiTouchPoint={true} />
                    </div>
                  )}
                  {isAdimoPriceFirst && (
                    <div className={buyNowBtnWrapper()}>
                      {' '}
                      <Adimo touchpointIDs={buyOnline} isMultiTouchPoint={false} />
                    </div>
                  )}
                </>
              )}
            </div>
            <div className={productInformationDescription()}>
              {description && <RichTextA11yWrapper field={description} />}
            </div>
            {disclaimerText?.value != '' && (
              <LegalDisclaimer
                disclaimerText={disclaimerText}
                disclaimerClasses={legalDisclaimerText()}
              />
            )}
          </div>
        </div>
        <input
          type="hidden"
          name="hdnProductBadge"
          id="hdnProductBadge"
          value={badgeInfo?.fields?.badgeTitle?.value}
        />
        <input
          type="hidden"
          name="hdnProductCategory"
          id="hdnProductCategory"
          value={categoryInfo?.fields?.categoryNameForSearchIndex?.value}
        />
        <input
          type="hidden"
          name="hdnProductSubCategory"
          id="hdnProductSubCategory"
          value={subCategoryInfo?.fields?.categoryNameForSearchIndex?.value}
        />
        <input
          type="hidden"
          name="hdnProductInfoItemId"
          id="hdnProductInfoItemId"
          value={prodInfo?.id}
        />
        <input
          type="hidden"
          name="hdnProductPageItemId"
          id="hdnProductPageItemId"
          value={sitecoreContext?.route?.itemId}
        />
        <input
          type="hidden"
          name="hdnAdimoAddtoCart"
          id="hdnAdimoAddtoCart"
          value={prodInfo?.fields?.addtoCart?.value}
        />
        <input
          type="hidden"
          name="hdnAdimoBuyOnline"
          id="hdnAdimoBuyOnline"
          value={prodInfo?.fields?.buyOnline?.value}
        />
        <input
          type="hidden"
          name="hdnAdimoWheretoBuy"
          id="hdnAdimoWheretoBuy"
          value={prodInfo?.fields?.wheretoBuy?.value}
        />
      </Container>
      {/* <>
        <div id="pr-reviewdisplay">
          <h2 className="prReviewHeading" id="prReviewHeading">
            Ratings &amp; Review
          </h2>
        </div>
      </> */}
    </div>
  );
};

export default ProductInformation;

// Global
import { ImageField, LinkField, RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import { tv } from 'tailwind-variants';

// Lib
import { ComponentProps } from 'lib/component-props';
import { useTheme } from 'lib/context/ThemeContext';
import { DartComponents } from 'lib/templates/Feature.Dart.model';

// Local
import Button from 'helpers/Button/Button';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import SVG from 'helpers/SVG/SVG';
import fallback from 'lib/fallback/fallback';

export type MosquitoForecastThankYouProps = ComponentProps &
  DartComponents.MosquitoForecastThankYou.MosquitoForecastThankYou;

const tailwindVariants = tv({
  slots: {
    bodyContainer: [
      'flex-col',
      'flex',
      'gap-components-contact-us-promo-spacing-small-title-margin-bottom',
      'items-center',
      'p-0',
      'pb-10',
      'px-10',
      'md:gap-components-contact-us-promo-spacing-large-margin-y',
    ],
    titleText: [
      'font-header-small-medium',
      'text-header-small-medium',
      'leading-header-small-medium',
      'md:font-header-large-medium',
      'md:text-header-large-medium',
      'md:leading-header-large-medium',
      'text-components-contact-us-promo-color-default-title',
      'md:mb-components-promo-spacing-large-title-margin-bottom',
    ],
    bodyText: [
      'font-bodySans-medium',
      'text-bodySans-medium',
      'leading-bodySans-medium',
      'md:font-bodySans-large',
      'md:text-bodySans-large',
      'md:leading-bodySans-large',
      'text-center',
      'text-components-contact-us-promo-color-default-body',
      '[&_a]:font-bodySans-large-underline-semibold',
      '[&_a]:text-bodySans-large-underline-semibold',
      '[&_a]:leading-bodySans-large-underline-semibold',
      '[&_a]:underline',
      '[&_a]:text-components-contact-us-promo-color-default-body',
      '[&_a]:inline-block',
    ],
    bottomLeftBg: ['absolute', 'bottom-0', 'hidden', 'left-0', 'md:block'],
    bottomRightBg: ['absolute', 'bottom-0', 'hidden', 'right-0', 'md:block'],
    iconContainer: ['flex', 'gap-4'],
    mainContainer: [
      'flex-col',
      'flex',
      'items-center',
      'justify-center',
      'py-spacing-spacing-5',
      'relative',
      'lg:pb-components-contact-us-promo-spacing-large-padding-x',
      'lg:pt-components-contact-us-promo-spacing-large-padding-y',
      'lg:px-components-contact-us-promo-spacing-large-padding-x',
      'md:gap-10',
    ],
    offcastLogo: ['text-center'],
    shareIcon: [
      'bg-components-button-color-filled-brand-default-bg',
      'p-components-button-spacing-large-icon-only-padding-x',
      'rounded-themes-radius-large-button',
      '[&_*]:!fill-components-button-color-filled-brand-default-icon',
      '[&_svg]:!h-6',
      '[&_svg]:!w-6',
    ],
    shareText: [
      'font-header-small-medium',
      'leading-header-small-medium',
      'text-center',
      'text-components-contact-us-promo-color-default-title',
      'text-header-small-medium',
    ],
    shareTextContainer: [
      'flex-col',
      'flex',
      'gap-12',
      'items-center',
      'px-10',
      'py-6',
      'md:flex-row',
      'md:p-spacing-padding-large-3',
    ],
    topLeftBg: ['absolute', 'hidden', 'left-0', 'top-0', 'md:block'],
    topRightBg: ['absolute', 'hidden', 'right-0', 'top-0', 'md:block'],
  },
});

// Add fallback component variant color
const fallbackComponentVariantColor = fallback?.componentVariants?.value;
const fallbackComponentVariantType = fallback?.componentVariants?.type;

const MosquitoForecastThankYou = (props: MosquitoForecastThankYouProps): JSX.Element => {
  const { themeName } = useTheme();

  if (!props.fields) return <>MosquitoForecastPromo Component</>;

  const {
    forecastLogo,
    title,
    description,
    socialTitle,
    enableSocial,
    socialLinks,
    primaryCTA,
    primaryCTAColor,
    primaryCTAType,
  } = props?.fields;

  const id = props?.params?.RenderingIdentifier;

  const {
    bodyContainer,
    bodyText,
    bottomLeftBg,
    bottomRightBg,
    iconContainer,
    mainContainer,
    offcastLogo,
    shareIcon,
    shareText,
    shareTextContainer,
    titleText,
    topLeftBg,
    topRightBg,
  } = tailwindVariants();

  return (
    <div
      className={mainContainer()}
      data-component="authorable/mosquitoforecastthankyouprops"
      id={id || undefined}
      tabIndex={id ? -1 : 1}
    >
      <SVG className={topLeftBg()} svg={`OffCastThankYou/Bg=TopLeft,Brand=${themeName}`} />
      <SVG className={topRightBg()} svg={`OffCastThankYou/Bg=TopRight,Brand=${themeName}`} />
      <ImageWrapper className={offcastLogo()} field={forecastLogo} />
      <div className={bodyContainer()}>
        {title?.value && (
          <div className={titleText()}>
            <Text field={title} tag="h2" />
          </div>
        )}
        {title?.value && (
          <div className={bodyText()}>
            <RichText field={description} />
          </div>
        )}
        {primaryCTA?.value.text && (
          <LinkWrapper field={primaryCTA} showLinkTextWithChildrenPresent={false}>
            <Button
              //As the design requires a filled CTA, we do not need to add a fallback since
              //the button component has a default variant that displays a filled CTA.
              label={primaryCTA?.value.text}
              type={primaryCTAType?.value || fallbackComponentVariantType}
              color={primaryCTAColor?.value || fallbackComponentVariantColor}
            />
          </LinkWrapper>
        )}
      </div>
      {socialTitle?.value && (
        <div className={shareTextContainer()}>
          <div className={shareText()}>
            <Text field={socialTitle} tag="h2" />
          </div>
          <div className={iconContainer()}>
            {enableSocial?.value &&
              socialLinks?.map((socialLink, index) => {
                const { socialIcon: socialIconImage, socialUrl } = socialLink.fields || {};
                const url = socialIconImage as ImageField;

                return (
                  <LinkWrapper
                    className={shareIcon()}
                    field={socialUrl as LinkField}
                    key={index}
                    showLinkTextWithChildrenPresent={false}
                  >
                    <SVG url={url?.value?.src} />
                  </LinkWrapper>
                );
              })}
          </div>
        </div>
      )}
      <SVG className={bottomLeftBg()} svg={`OffCastThankYou/Bg=BottomLeft,Brand=${themeName}`} />
      <SVG className={bottomRightBg()} svg={`OffCastThankYou/Bg=BottomRight,Brand=${themeName}`} />
    </div>
  );
};

export default MosquitoForecastThankYou;

// Global
import { sendGTMEvent } from '@next/third-parties/google';
import { Field, Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import {
  FacetPayloadType,
  FilterEqual,
  SearchResultsInitialState,
  SearchResultsStoreState,
  WidgetDataType,
  useSearchResults,
  useSearchResultsSelectedFilters,
  widget,
} from '@sitecore-search/react';
import {
  AccordionFacets,
  CardViewSwitcher,
  FacetItem,
  Pagination,
  Presence,
  SearchResultsAccordionFacets,
  SortSelect,
} from '@sitecore-search/ui';
import { SearchResultsStoreSelectedFacet } from '@sitecore-search/widgets';
import Parser from 'html-react-parser';
import { useI18n } from 'next-localization';
import { useEffect, useState, useRef, useCallback } from 'react';

// Lib
import { DartPages } from 'lib/templates/Project.Dart.model';
import { getStaticAssetUrl } from 'lib/utils/public-url-utils';
import { useTheme } from 'lib/context/ThemeContext';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import Button from 'helpers/Button/Button';
import GoogleMaterialSymbol from 'helpers/GoogleMaterialSymbol/GoogleMaterialSymbol';
import Label, { ColorTypes } from 'helpers/Label/Label';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import PowerReviewsPrductListWithSearch from 'helpers/PowerReviews/PowerReviwsProductListWithSearch';
import { ComponentVariants } from 'lib/context/ComponentVariants';

import tailwindVariantProduct, {
  tailwindMain,
  tailwindProductList,
  tailwindProductTopBar,
  tailwindVariantProductSearch,
  tailwindVariants,
} from './tailwindVariant';
import { focusTrap } from 'src/stories/helpers/CustomeHelpers/FocusTrap';
import Adimo from 'helpers/Adimo/Adimo';
import SVG from 'helpers/SVG/SVG';

export type ProductModel = {
  id: string;
  name: string;
  productbadge: string;
  productcategory: string;
  productsubcategory: string;
  producttitle: string;
  description: string;
  productlabel: string;
  productrating: string;
  image_url: string;
  url: string;
  source_id?: string;
  product_information_item_id: string;
  product_page_item_id: string;
  tool_tip_title?: string;
  label_url?: string;
  adimo_add_to_cart?: string;
  adimo_buy_online?: string;
  adimo_where_to_buy?: string;
};

type SearchResultsProps = {
  defaultSortType?: SearchResultsStoreState['sortType'];
  defaultPage?: SearchResultsStoreState['page'];
  defaultItemsPerPage?: SearchResultsStoreState['itemsPerPage'];
  defaultKeyphrase?: SearchResultsStoreState['keyphrase'];
  resultsPerPage?: Field<number>;
  filterLabel?: Field<string>;
  noSearchResult?: Field<string>;
  sortLabel?: Field<string>;
  filterButtonLabel?: Field<string>;
  sortByLabel?: Field<string>;
  isBadgeFullWidth?: string | undefined;
  primaryCTAColor?: Field<string>;
  primaryCTAType?: Field<string>;
};

type InitialState = SearchResultsInitialState<
  'itemsPerPage' | 'keyphrase' | 'page' | 'sortType' | 'selectedFacets'
>;

export type CategoryListPage = DartPages.CategoryListPage;
type SearchSettings = {
  previewSource: string;
  source: string;
};
const defaultCardView = CardViewSwitcher.CARD_VIEW_GRID;

const buildRangeLabel = (min: number | undefined, max: number | undefined): string => {
  return typeof min === 'undefined'
    ? `< $${max}`
    : typeof max === 'undefined'
    ? ` > $${min}`
    : `$${min} - $${max}`;
};

const buildFacetLabel = (
  selectedFacet: SearchResultsStoreSelectedFacet & { type: FacetPayloadType }
) => {
  if ('min' in selectedFacet || 'max' in selectedFacet) {
    return `${selectedFacet.facetLabel}: ${buildRangeLabel(selectedFacet.min, selectedFacet.max)}`;
  }
  return `${selectedFacet.facetLabel}: ${selectedFacet.valueLabel}`;
};

export const ProductListWithSearchComponent = ({
  defaultSortType = 'featured_desc',
  defaultPage = 1,
  defaultKeyphrase = '',
  defaultItemsPerPage = 20,
  filterLabel,
  noSearchResult,
  resultsPerPage,
  sortLabel,
  filterButtonLabel,
  sortByLabel,
  isBadgeFullWidth,
  primaryCTAColor,
  primaryCTAType,
}: SearchResultsProps) => {
  const selectedFacetsFromApi = useSearchResultsSelectedFilters();
  const { sitecoreContext } = useSitecoreContext();

  const categoryPage = sitecoreContext?.route as CategoryListPage;
  const isMikMakEnabled = sitecoreContext?.integrationMikMak?.isMikMak === '1';
  const adimoMultiTouchPoint = sitecoreContext?.integrationEvidon?.isAdimoMultiTouchpoint === '1';
  const isAdimoPriceFirst = sitecoreContext?.integrationEvidon?.isAdimoPriceFirst === '1';

  const searchSettings = sitecoreContext?.searchDetails as SearchSettings;
  //let searchSourceIds = searchSettings?.source?.split('|') || [];
  const searchSourceIds = process.env.IS_PREVIEW_SITE
    ? searchSettings?.previewSource?.split('|') || []
    : searchSettings?.source?.split('|') || [];

  const preSelectFacetValue =
    categoryPage?.fields?.isCategoryBasedOnSearchResult?.value &&
    categoryPage?.fields?.categoryNameForSearchIndex?.value
      ? {
          facetId:
            categoryPage?.fields?.categoryType?.value == 'Category'
              ? 'productcategory'
              : categoryPage?.fields?.categoryType?.value == 'Subcategory'
              ? 'productsubcategory'
              : '',
          facetValueText: categoryPage?.fields?.categoryNameForSearchIndex?.value,
        }
      : null;

  const {
    widgetRef,
    actions: { onPageNumberChange, onRemoveFilter, onSortChange, onFacetClick, onClearFilters },
    state: { sortType, page, itemsPerPage },
    queryResult: {
      isFetching,
      data: {
        total_item: totalItems = 0,
        sort: { choices: sortChoices = [] } = {},
        facet: facets = [],
        content: products = [],
      } = {},
    },
  } = useSearchResults<ProductModel, InitialState>({
    query: (query) => {
      query
        .getRequest()
        .setSources(searchSourceIds)
        .setSearchFilter(new FilterEqual('pagetype', 'Product'))
        .setSearchFacetAll(false)
        .setSearchFacetTypes([
          {
            name: 'productcategory',
          },
          {
            name: 'productsubcategory',
          },
        ])
        .getSearchFacet();
    },
    config: {
      facets: {
        productcategory: {
          type: 'text',
        },
        productsubcategory: {
          type: 'text',
        },
      },
    },
    state: {
      sortType: defaultSortType,
      page: defaultPage,
      itemsPerPage: (resultsPerPage?.value as number) || defaultItemsPerPage,
      keyphrase: defaultKeyphrase,
      selectedFacets: preSelectFacetValue != null ? [preSelectFacetValue] : [],
    },
  });

  const [dir, setDir] = useState(defaultCardView);
  const onToggle = (value = defaultCardView) => setDir(value);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const selectedSortIndex = sortChoices.findIndex((s) => s.name === sortType);
  const selectedOption = sortChoices.find((s) => s.name === sortType);

  type WindowType = 'Desktop' | 'Mobile';
  type filterVisible = 0 | 1;

  const [windowSize, setWindowSize] = useState<number>();

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState<string[]>([]);

  const [isPreviousButtonDisabled, setPreviousButtonDisabled] = useState<boolean>(false);
  const [isNextButtonDisabled, setNextButtonDisabled] = useState<boolean>(false);
  const componentVariants = ComponentVariants();

  useEffect(() => {
    const initPreviousButton = (): void => {
      if (page === 1) {
        return setPreviousButtonDisabled(true);
      } else if (page !== 1 && totalPages > 1) {
        return setPreviousButtonDisabled(false);
      } else {
        return setPreviousButtonDisabled(true);
      }
    };

    const initNextButton = (): void => {
      if ((page === 1 && totalPages === 1) || (page !== 1 && page === totalPages)) {
        return setNextButtonDisabled(true);
      } else if ((page === 1 && totalPages > 1) || (page > 1 && page !== totalPages)) {
        return setNextButtonDisabled(false);
      } else {
        return setNextButtonDisabled(true);
      }
    };
    initPreviousButton();
    initNextButton();
  }, [page, totalPages]);

  const refSearchResults = useRef<HTMLDivElement>(null);
  const firstCardFocus = useRef<HTMLDivElement>(null);

  const header = typeof window !== 'undefined' ? window.document.getElementById('header') : null;

  const scrollToSearchResults = (page: number) => {
    onPageNumberChange({ page });
    const { current } = refSearchResults;
    if (firstCardFocus.current) {
      firstCardFocus.current.tabIndex = 0; // Set tabIndex to make it focusable
      firstCardFocus.current.focus();
    }
    if (current !== null) {
      setTimeout(() => {
        const currentScrollPosition = window.scrollY || window.pageYOffset;
        const elementTop = current.getBoundingClientRect().top;
        if (header) {
          const newPosition = currentScrollPosition + elementTop - header?.offsetHeight;
          window.scrollTo({ top: newPosition, behavior: 'smooth' });
        }
      }, 300);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWindowSize(window.innerWidth);
    }

    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowSize]);

  const i18n = useI18n();
  const i18n_all = i18n.t('All');
  const i18n_buyNow = i18n.t('BuyNow');
  const i18n_clearFilters = i18n.t('ClearFilters');
  let i18n_displayingCountForSearch = i18n.t('DisplayingCountForSearch');
  let i18n_displayingMobileCountForSearch = i18n.t('DisplayingMobileCountForSearch');
  const i18n_next = i18n.t('Next');
  const i18n_previous = i18n.t('Previous');

  const {
    productListContainer,
    cardWrapper,
    badgeBG,
    badgeContainer,
    cardMediaWrapper,
    cardMedia,
    cardMediaImage,
    cardContentWrapper,
    cardContainer,
    productInfoContainer,
    labelBg,
    productTitle,
    productDescription,
    productCardListing,
    productCard,
    ctaWrapper,
  } = tailwindVariantProduct();

  const {
    paginationContainer,
    paginationIcon,
    paginationRoot,
    paginationPage,
    paginationPageWrapper,
    paginationDisplayPage,
    paginationGroup,
  } = tailwindVariantProductSearch();

  const {
    base,
    categoryWrapper,
    categoryList,
    categoryLabelBG,
    categoryLabel,
    category,
    filterContainer,
    layoutsButtonContainer,
    viewIcon,
    filterWrapper,
    svgWrapper,
    gradientDesktop,
    gradientMobile,
    categoryItem,
  } = tailwindProductTopBar();

  const {
    productCardList,
    cardWrapperList,
    cardMediaWrapperList,
    cardContentWrapperList,
    cardContainerList,
    badgeContainerList,
    productCardListButton,
  } = tailwindProductList();

  const filterVisible = isFilterVisible ? 1 : 0;

  const { mainContainer, loaderWrapper, searchLoaderAnimation, filterBodyBG } = tailwindMain({
    isFilterVisible: filterVisible as filterVisible,
  });
  const refSearchLeftPart = useRef<HTMLDivElement>(null);
  const toggleFilterMenu = useCallback(() => {
    setIsFilterVisible(!isFilterVisible);
    setIsAccordionOpen([]);
  }, [isFilterVisible]);

  useEffect(() => {
    if (isFilterVisible) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [isFilterVisible]);

  useEffect(() => {
    const previouslyFocussed = document?.activeElement;
    isFilterVisible &&
      refSearchLeftPart?.current &&
      focusTrap(refSearchLeftPart.current, toggleFilterMenu);
    return () => {
      previouslyFocussed && (previouslyFocussed as HTMLElement).focus();
    };
  }, [isFilterVisible, toggleFilterMenu]);

  const deviceName = windowSize && windowSize >= 996 ? 'Desktop' : 'Mobile';

  const {
    searchResultsLeftWrapper,
    searchResultsLeft,
    refineTitleWrapper,
    refineTitle,
    refineTitleIconWrapper,
    refineTitleIcon,
    sortFilterWrapper,
    sortEyebrowText,
    sortFilter,
    sortFilterIconWrapper,
    sortFilterIcon,
    sortFilterContentWrapper,
    sortFilterContent,
    sortFilterOptionWrapper,
    selectionFilterWrapper,
    selectedFilterWrapper,
    selectedFilter,
    selectedFilterText,
    selectedFilterIcon,
    accordionFacetsWrapper,
    accordionFacetTriggerClass,
    accordionFacetsHeaderWrapper,
    accordionFacetsHeader,
    accordionFacetsHeaderIconWrapper,
    accordionFacetsHeaderIcon,
    accordionFacetsContentWrapper,
    accordionFacetsContentItem,
    accordionFacetsContentItemIconWrapper,
    accordionFacetsContentItemIcon,
    accordionFacetsItemLabelContainer,
    accordionFacetsItemLabelWrapper,
    accordionFacetsItemLabel,
    accordionContent,
  } = tailwindVariants({
    deviceDetect: deviceName as WindowType,
  });

  const { themeName } = useTheme();
  const badgeSVG = `ProductCard/Breakpoint=Medium,Brand=${themeName}`;
  const svgBG = `${getStaticAssetUrl()}/assets/${badgeSVG}.svg`;

  const paginationOf = `${itemsPerPage * (page - 1) + 1} - ${
    itemsPerPage * (page - 1) + products.length
  }`;
  const toalProductPages = totalItems as unknown as string;
  i18n_displayingCountForSearch = i18n_displayingCountForSearch
    .replace('{0}', paginationOf)
    .replace('{1}', toalProductPages);

  i18n_displayingMobileCountForSearch = i18n_displayingMobileCountForSearch
    .replace('{0}', `${page}`)
    .replace('{1}', `${totalPages}`);

  return (
    <Container>
      <div ref={widgetRef}>
        <div className={mainContainer()} ref={refSearchResults}>
          {isFetching && (
            <div className={loaderWrapper()}>
              <Presence present={true}>
                <div className={searchLoaderAnimation()} />
              </Presence>
            </div>
          )}
          {totalItems > 0 && (
            <>
              {isFilterVisible && (
                <>
                  <span className={filterBodyBG()} onClick={toggleFilterMenu}></span>
                  <section
                    className={searchResultsLeftWrapper({ isFilterVisible: isFilterVisible })}
                    ref={refSearchLeftPart}
                  >
                    <div className={searchResultsLeft()}>
                      <div className={refineTitleWrapper()}>
                        <p className={refineTitle()}>{filterLabel?.value}</p>
                        <button className={refineTitleIconWrapper()} onClick={toggleFilterMenu}>
                          <GoogleMaterialSymbol
                            className={refineTitleIcon()}
                            icon={'close'}
                            variant="outlined"
                          />
                        </button>
                      </div>
                      <div className={sortFilterWrapper()}>
                        <SortSelect.Root
                          onValueChange={onSortChange}
                          onOpenChange={() => {
                            setIsDropdownOpen(!isDropdownOpen);
                          }}
                        >
                          <span className={sortEyebrowText()}>{sortLabel?.value}</span>
                          <SortSelect.Trigger className={sortFilter()}>
                            <SortSelect.SelectValue placeholder={sortByLabel?.value}>
                              {selectedSortIndex > -1 ? sortChoices[selectedSortIndex].label : ''}
                            </SortSelect.SelectValue>
                            <div className={sortFilterIconWrapper()}>
                              <GoogleMaterialSymbol
                                className={sortFilterIcon()}
                                icon={isDropdownOpen ? 'arrow_drop_up' : 'arrow_drop_down'}
                                variant="outlined"
                              />
                            </div>
                          </SortSelect.Trigger>
                          <div className={sortFilterContentWrapper()}>
                            <SortSelect.Content className={sortFilterContent()}>
                              <SortSelect.Viewport>
                                {sortChoices?.map((option) => (
                                  <SortSelect.Option
                                    value={option}
                                    key={option.name}
                                    className={sortFilterOptionWrapper({
                                      isSelectedOption: selectedOption?.name === option?.name,
                                    })}
                                  >
                                    <SortSelect.OptionText>{option?.label}</SortSelect.OptionText>
                                    {selectedOption?.name === option?.name && (
                                      <GoogleMaterialSymbol icon="done" />
                                    )}
                                  </SortSelect.Option>
                                ))}
                              </SortSelect.Viewport>
                            </SortSelect.Content>
                          </div>
                        </SortSelect.Root>
                      </div>
                      <div className={selectionFilterWrapper()}>
                        {!!selectedFacetsFromApi?.length && (
                          <div className={selectedFilterWrapper()}>
                            {selectedFacetsFromApi.map((selectedFacet, index) => (
                              <div
                                key={`${selectedFacet.facetId}${selectedFacet.facetLabel}${selectedFacet.valueLabel}`}
                                className={selectedFilter()}
                                onClick={() => onRemoveFilter(selectedFacet)}
                                onKeyDown={(event) => {
                                  if (event.key === 'Enter') {
                                    onRemoveFilter(selectedFacet);
                                  }
                                }}
                                tabIndex={0}
                                data-index={index}
                              >
                                <span className={selectedFilterText()}>
                                  {Parser(buildFacetLabel(selectedFacet))}
                                </span>
                                <GoogleMaterialSymbol
                                  className={selectedFilterIcon()}
                                  icon={'close'}
                                  variant="outlined"
                                />
                              </div>
                            ))}
                            {selectedFacetsFromApi.length > 0 && (
                              <div
                                onClick={onClearFilters}
                                className={selectedFilter()}
                                onKeyDown={(event) => {
                                  if (event.key === 'Enter') {
                                    onClearFilters();
                                  }
                                }}
                                tabIndex={0}
                              >
                                <span className={selectedFilterText()}>{i18n_clearFilters}</span>
                                <GoogleMaterialSymbol
                                  className={selectedFilterIcon()}
                                  icon={'close'}
                                  variant="outlined"
                                />
                              </div>
                            )}
                          </div>
                        )}
                        <SearchResultsAccordionFacets
                          defaultFacetTypesExpandedList={[]}
                          onFacetValueClick={onFacetClick}
                        >
                          {facets.map((f) => (
                            <AccordionFacets.Facet
                              facetId={f.name}
                              key={f.name}
                              className={accordionFacetsWrapper({
                                isAccordionOpen: isAccordionOpen.length > 0,
                              })}
                              id={f.name}
                            >
                              <AccordionFacets.Trigger
                                className={accordionFacetTriggerClass()}
                                onClick={() => {
                                  setIsAccordionOpen([...isAccordionOpen, f.name]);
                                  if (isAccordionOpen.includes(f.name)) {
                                    setIsAccordionOpen(
                                      isAccordionOpen.filter((item) => item !== f.name)
                                    );
                                  }
                                }}
                              >
                                <div className={accordionFacetsHeaderWrapper()}>
                                  <AccordionFacets.Header className={accordionFacetsHeader()}>
                                    {f.label}
                                  </AccordionFacets.Header>
                                  <div className={accordionFacetsHeaderIconWrapper()}>
                                    <GoogleMaterialSymbol
                                      className={accordionFacetsHeaderIcon()}
                                      icon={
                                        isAccordionOpen?.includes(f.name)
                                          ? 'expand_less'
                                          : 'expand_more'
                                      }
                                      variant="outlined"
                                    />
                                  </div>
                                </div>
                              </AccordionFacets.Trigger>
                              <AccordionFacets.Content className={accordionContent()}>
                                <AccordionFacets.ValueList
                                  className={accordionFacetsContentWrapper()}
                                >
                                  {f.value.map((v, index) => (
                                    <FacetItem
                                      {...{
                                        index,
                                        facetValueId: v.id,
                                      }}
                                      key={v.id}
                                      className={accordionFacetsContentItem()}
                                    >
                                      <AccordionFacets.ItemCheckbox
                                        className={accordionFacetsContentItemIconWrapper()}
                                        key={`${v.id}-facet-item-checkbox`}
                                        tabIndex={0}
                                      >
                                        <AccordionFacets.ItemCheckboxIndicator
                                          key={`${v.id}-facet-item-checkbox-indicator`}
                                          tabIndex={0}
                                        >
                                          <GoogleMaterialSymbol
                                            className={accordionFacetsContentItemIcon()}
                                            icon={'check_small'}
                                            variant="rounded"
                                          />
                                        </AccordionFacets.ItemCheckboxIndicator>
                                      </AccordionFacets.ItemCheckbox>
                                      <AccordionFacets.ItemLabel
                                        className={accordionFacetsItemLabelContainer()}
                                        key={`${v.id}-facet-item-label`}
                                      >
                                        <div className={accordionFacetsItemLabelWrapper()}>
                                          <span className={accordionFacetsItemLabel()}>
                                            {Parser(v.text)}
                                          </span>
                                          <span className={accordionFacetsItemLabel()}>
                                            {' '}
                                            {v.count && `(${v.count})`}
                                          </span>
                                        </div>
                                      </AccordionFacets.ItemLabel>
                                    </FacetItem>
                                  ))}
                                </AccordionFacets.ValueList>
                              </AccordionFacets.Content>
                            </AccordionFacets.Facet>
                          ))}
                        </SearchResultsAccordionFacets>
                      </div>
                    </div>
                  </section>
                </>
              )}
              <section>
                <div className={base()}>
                  <div className={categoryWrapper()}>
                    <SearchResultsAccordionFacets
                      defaultFacetTypesExpandedList={['productcategory']}
                      onFacetValueClick={onFacetClick}
                    >
                      {facets?.map((facet) => {
                        return facet.name === 'productcategory' ? (
                          <AccordionFacets.Facet facetId={facet.name} key={facet.name}>
                            <AccordionFacets.Content>
                              <AccordionFacets.ValueList
                                className={categoryList()}
                                data-orientation="horizontal"
                              >
                                {selectedFacetsFromApi.length <= 0 ? (
                                  <button onClick={onClearFilters}>
                                    <Label
                                      text={{ value: i18n_all }}
                                      color={
                                        componentVariants?.searchListing?.labelColor as ColorTypes
                                      }
                                      backgroundColor={categoryLabelBG()}
                                      buttonType="rounded"
                                      className={categoryLabel()}
                                    />
                                  </button>
                                ) : (
                                  <button onClick={onClearFilters} className={category()}>
                                    {i18n_all}
                                  </button>
                                )}

                                {facet.value.map((facetValue, index) => {
                                  function handleGTMEvent(): void {
                                    sendGTMEvent({
                                      event: 'tab',
                                      type: 'click',
                                      'gtm.element.dataset.gtmLinkName': facetValue.text,
                                    });
                                  }
                                  return (
                                    <FacetItem
                                      {...{
                                        index,
                                        facetValueId: facetValue.id,
                                      }}
                                      key={facetValue.id}
                                      className={categoryItem()}
                                    >
                                      {selectedFacetsFromApi.some(
                                        (item) => item.valueLabel === facetValue.text
                                      ) ? (
                                        <></>
                                      ) : (
                                        <AccordionFacets.ItemCheckbox
                                          className=""
                                          key={`${facetValue.id}-facet-item-checkbox`}
                                          onClick={handleGTMEvent}
                                        >
                                          <AccordionFacets.ItemLabel
                                            className={category()}
                                            key={`${facetValue.id}-facet-item-label`}
                                          >
                                            <Text
                                              encode={false}
                                              field={{ value: facetValue.text }}
                                              tag="span"
                                            />
                                          </AccordionFacets.ItemLabel>
                                        </AccordionFacets.ItemCheckbox>
                                      )}

                                      {selectedFacetsFromApi.map((selectedFacet) => {
                                        return (
                                          <>
                                            {facetValue.text === selectedFacet.valueLabel && (
                                              <div onClick={() => onRemoveFilter(selectedFacet)}>
                                                <Label
                                                  text={{ value: selectedFacet.valueLabel }}
                                                  color={
                                                    componentVariants?.searchListing
                                                      ?.labelColor as ColorTypes
                                                  }
                                                  backgroundColor={categoryLabelBG()}
                                                  buttonType="rounded"
                                                  className={categoryLabel()}
                                                />
                                              </div>
                                            )}
                                          </>
                                        );
                                      })}
                                    </FacetItem>
                                  );
                                })}
                              </AccordionFacets.ValueList>
                            </AccordionFacets.Content>
                          </AccordionFacets.Facet>
                        ) : null;
                      })}
                    </SearchResultsAccordionFacets>
                  </div>
                  <div className={filterWrapper()}>
                    <div
                      className={gradientDesktop()}
                      style={{
                        background:
                          'linear-gradient(90deg, rgba(255, 255, 255, 0.00) -4.17%, #FFF 104.17%)',
                      }}
                    ></div>
                    <section className={filterContainer()}>
                      <CardViewSwitcher.Root
                        onValueChange={onToggle}
                        defaultValue={defaultCardView}
                        className={layoutsButtonContainer()}
                      >
                        <CardViewSwitcher.Item
                          value="grid"
                          aria-label="Grid View"
                          className={viewIcon({
                            selected: dir === CardViewSwitcher.CARD_VIEW_GRID,
                          })}
                          onClick={() =>
                            sendGTMEvent({ event: 'switcher', type: 'click', value: 'grid' })
                          }
                        >
                          <GoogleMaterialSymbol icon={'grid_view'} />
                        </CardViewSwitcher.Item>
                        <CardViewSwitcher.Item
                          value="list"
                          aria-label="List View"
                          className={viewIcon({
                            selected: dir !== CardViewSwitcher.CARD_VIEW_GRID,
                          })}
                          onClick={() =>
                            sendGTMEvent({ event: 'switcher', type: 'click', value: 'list' })
                          }
                        >
                          <GoogleMaterialSymbol icon={'list'} />
                        </CardViewSwitcher.Item>
                      </CardViewSwitcher.Root>
                      <div className="" onClick={toggleFilterMenu}>
                        <Button label={filterButtonLabel?.value} type="outline" iconLeft="tune" />
                      </div>
                    </section>
                  </div>
                  <div
                    className={gradientMobile()}
                    style={{
                      background:
                        'linear-gradient(90deg, rgba(255, 255, 255, 0.00) -4.17%, #FFF 104.17%)',
                    }}
                  ></div>
                </div>
                <div className={svgWrapper()}>
                  <SVG svg={`ProductResultSearch/Brand=${themeName}`} />
                </div>
                <div className={productListContainer()}>
                  {dir === CardViewSwitcher.CARD_VIEW_GRID ? (
                    <div className={productCardListing()} ref={firstCardFocus}>
                      {products?.map((product, index) => (
                        <>
                          <div
                            className={productCard()}
                            key={index}
                            data-component="widgets/productlistwithsearch/productcard"
                          >
                            <div className={cardWrapper()} key={product.id}>
                              <LinkWrapper
                                aria-label={product?.tool_tip_title}
                                suppressLinkText
                                field={{
                                  value: {
                                    href: product?.url,
                                    text: product?.producttitle,
                                    title: product?.tool_tip_title,
                                  },
                                }}
                                gtmEvent={{
                                  event: 'media',
                                  type: 'image',
                                  'gtm.element.dataset.gtmDatasourceName': product.name,
                                }}
                              >
                                <div className={cardMediaWrapper()}>
                                  {product.productbadge && (
                                    <div
                                      className={badgeContainer({
                                        isBadgeFullWidth: isBadgeFullWidth as '1' | undefined,
                                      })}
                                      aria-hidden="true"
                                    >
                                      <span
                                        className={badgeBG({
                                          isBadgeFullWidth: isBadgeFullWidth as '1' | undefined,
                                        })}
                                        style={{
                                          maskImage: `url(${svgBG})`,
                                          maskSize: 'cover',
                                          maskRepeat: isBadgeFullWidth && 'no-repeat',
                                          maskPosition: isBadgeFullWidth && 'center top',
                                        }}
                                      >
                                        {product.productbadge}
                                      </span>
                                    </div>
                                  )}
                                  {product?.image_url && (
                                    <div className={cardMedia()}>
                                      {' '}
                                      <img
                                        src={product?.image_url}
                                        alt={product?.name}
                                        className={cardMediaImage()}
                                      />
                                    </div>
                                  )}
                                </div>
                              </LinkWrapper>
                              <div className={cardContentWrapper()}>
                                <div className={cardContainer()}>
                                  {product?.productlabel && (
                                    <Label
                                      link={{
                                        value: {
                                          href: product?.label_url,
                                          text: product?.productlabel,
                                        },
                                      }}
                                      color="white"
                                      backgroundColor={labelBg()}
                                      buttonType="default"
                                    />
                                  )}
                                  <div className={productInfoContainer()}>
                                    {product?.producttitle && (
                                      <span className={productTitle()}>
                                        <LinkWrapper
                                          aria-label={product?.tool_tip_title}
                                          field={{
                                            value: {
                                              href: product?.url,
                                              text: product?.producttitle,
                                              title: product?.tool_tip_title,
                                            },
                                          }}
                                          gtmEvent={{
                                            event: 'link',
                                            type: 'title',
                                            'gtm.element.dataset.gtmDatasourceName': product.name,
                                          }}
                                        />
                                      </span>
                                    )}
                                    <div id={`search-rating-card-snippet-${index}`}></div>
                                    {product?.description && (
                                      <div className={productDescription()}>
                                        {Parser(product?.description)}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                {product?.url && (
                                  <div className={ctaWrapper()}>
                                    {isMikMakEnabled && (
                                      <Button
                                        //As the design requires a filled CTA, we do not need to add a fallback since
                                        //the button component has a default variant that displays a filled CTA.
                                        srOnlyText={product?.tool_tip_title}
                                        label={i18n_buyNow}
                                        type={primaryCTAType?.value as string}
                                        color={primaryCTAColor?.value}
                                        tag="a"
                                        href={`${product?.url}${
                                          isMikMakEnabled ? '?wtbopen=1' : ''
                                        }`}
                                        gtmEvent={{
                                          event: 'cta_click',
                                          type: 'primary',
                                          'gtm.element.dataset.gtmLinkUrl': product.url,
                                          'gtm.element.dataset.gtmLinkName': product.producttitle,
                                          'gtm.element.dataset.gtmDatasourceId': product.id,
                                          'gtm.element.dataset.gtmDatasourceName': product.name,
                                        }}
                                      />
                                    )}
                                    {!isMikMakEnabled && (
                                      <>
                                        {adimoMultiTouchPoint && (
                                          <Adimo
                                            touchpointIDs={[
                                              product?.adimo_add_to_cart,
                                              product?.adimo_buy_online,
                                              product?.adimo_where_to_buy,
                                            ]
                                              .filter(Boolean)
                                              .join(',')}
                                            isMultiTouchPoint={true}
                                            type={primaryCTAType?.value}
                                            color={primaryCTAColor?.value}
                                          />
                                        )}
                                        {isAdimoPriceFirst && (
                                          <Adimo
                                            touchpointIDs={product?.adimo_buy_online}
                                            isMultiTouchPoint={false}
                                            type={primaryCTAType?.value}
                                            color={primaryCTAColor?.value}
                                          />
                                        )}
                                      </>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  ) : (
                    deviceName !== 'Mobile' && (
                      <div className={productCardListing()} ref={firstCardFocus}>
                        {products.map((product, index) => (
                          <>
                            <div className={productCardList()} key={index}>
                              <div className={cardWrapperList()} key={product.id}>
                                <LinkWrapper
                                  suppressLinkText
                                  field={{
                                    value: {
                                      href: product?.url,
                                      text: product?.producttitle,
                                      title: product?.tool_tip_title,
                                    },
                                  }}
                                  className={cardMediaWrapperList()}
                                  gtmEvent={{
                                    event: 'media',
                                    type: 'image',
                                    'gtm.element.dataset.gtmDatasourceName': product.name,
                                  }}
                                >
                                  <div>
                                    {product.productbadge && (
                                      <div
                                        className={badgeContainerList({
                                          isBadgeFullWidth: isBadgeFullWidth as '1' | undefined,
                                        })}
                                        aria-hidden="true"
                                      >
                                        <span
                                          className={badgeBG({
                                            isBadgeFullWidth: isBadgeFullWidth as '1' | undefined,
                                          })}
                                          style={{
                                            maskImage: `url(${svgBG})`,
                                            maskSize: 'cover',
                                            maskRepeat: isBadgeFullWidth && 'no-repeat',
                                            maskPosition: isBadgeFullWidth && 'center top',
                                          }}
                                        >
                                          {product.productbadge}
                                        </span>
                                      </div>
                                    )}

                                    {product?.image_url && (
                                      <div className={cardMedia()}>
                                        {' '}
                                        <img
                                          src={product?.image_url}
                                          alt={product?.name}
                                          className={cardMediaImage()}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </LinkWrapper>
                                <div className={cardContentWrapperList()}>
                                  <div className={cardContainerList()}>
                                    {product?.productlabel && (
                                      <Label
                                        link={{
                                          value: {
                                            href: product?.label_url,
                                            text: product?.productlabel,
                                          },
                                        }}
                                        color="white"
                                        backgroundColor={labelBg()}
                                        buttonType="default"
                                      />
                                    )}
                                    <div className={productInfoContainer()}>
                                      {product?.producttitle && (
                                        <span className={productTitle()}>
                                          <LinkWrapper
                                            field={{
                                              value: {
                                                href: product?.url,
                                                text: product?.producttitle,
                                                title: product?.tool_tip_title,
                                              },
                                            }}
                                            gtmEvent={{
                                              event: 'link',
                                              type: 'title',
                                              'gtm.element.dataset.gtmDatasourceName': product.name,
                                            }}
                                          />
                                        </span>
                                      )}

                                      <div id={`search-rating-list-snippet-${index}`}></div>

                                      {product?.description && (
                                        <div className={productDescription()}>
                                          {Parser(product?.description)}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  {product?.url && (
                                    <div className={productCardListButton()}>
                                      {isMikMakEnabled && (
                                        <Button
                                          //As the design requires a filled CTA, we do not need to add a fallback since
                                          //the button component has a default variant that displays a filled CTA.
                                          srOnlyText={product?.tool_tip_title}
                                          label={i18n_buyNow}
                                          type={primaryCTAType?.value as string}
                                          color={primaryCTAColor?.value}
                                          tag="a"
                                          href={`${product?.url}${
                                            isMikMakEnabled ? '?wtbopen=1' : ''
                                          }`}
                                          gtmEvent={{
                                            event: 'cta_click',
                                            type: 'primary',
                                            'gtm.element.dataset.gtmLinkUrl': product.url,
                                            'gtm.element.dataset.gtmLinkName': product.producttitle,
                                            'gtm.element.dataset.gtmDatasourceId': product.id,
                                            'gtm.element.dataset.gtmDatasourceName': product.name,
                                          }}
                                        />
                                      )}
                                      {!isMikMakEnabled && (
                                        <>
                                          {adimoMultiTouchPoint && (
                                            <Adimo
                                              touchpointIDs={[
                                                product?.adimo_add_to_cart,
                                                product?.adimo_buy_online,
                                                product?.adimo_where_to_buy,
                                              ]
                                                .filter(Boolean)
                                                .join(',')}
                                              isMultiTouchPoint={true}
                                              type={primaryCTAType?.value}
                                              color={primaryCTAColor?.value}
                                            />
                                          )}
                                          {isAdimoPriceFirst && (
                                            <Adimo
                                              touchpointIDs={product?.adimo_buy_online}
                                              isMultiTouchPoint={false}
                                              type={primaryCTAType?.value}
                                              color={primaryCTAColor?.value}
                                            />
                                          )}
                                        </>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                      </div>
                    )
                  )}
                </div>
                <div className={paginationContainer()}>
                  <Pagination.Root
                    currentPage={page}
                    defaultCurrentPage={1}
                    totalPages={totalPages}
                    onPageChange={(pagination) => {
                      scrollToSearchResults(pagination);
                    }}
                    className={paginationRoot()}
                  >
                    <Pagination.PrevPage
                      onClick={(e) => {
                        e.preventDefault();
                        scrollToSearchResults(page - 1);
                      }}
                      className={paginationIcon()}
                      tabIndex={-1}
                    >
                      <Button
                        type="text"
                        iconLeft="chevron_left"
                        label={deviceName === 'Desktop' ? i18n_previous : ''}
                        disabled={isPreviousButtonDisabled}
                        tabIndex={isPreviousButtonDisabled ? -1 : 0}
                      />
                    </Pagination.PrevPage>
                    <div className={paginationGroup()}>
                      <Pagination.Pages className={paginationPageWrapper()}>
                        {(pagination) =>
                          Pagination.paginationLayout(pagination, {
                            boundaryCount: 1,
                            siblingCount: 1,
                          }).map(({ page, type }) =>
                            type === 'page' ? (
                              <Pagination.Page
                                key={page}
                                aria-label={`Page ${page}`}
                                page={page as number}
                                onClick={(e) => e.preventDefault()}
                                className={paginationPage({
                                  currentPage: page == pagination.currentPage,
                                })}
                              >
                                {page}
                              </Pagination.Page>
                            ) : (
                              <span key={type}>...</span>
                            )
                          )
                        }
                      </Pagination.Pages>
                      {totalItems && (
                        <div
                          className={paginationDisplayPage({
                            isMobile: (windowSize && windowSize <= 996) as boolean,
                          })}
                        >
                          {windowSize && windowSize > 996
                            ? i18n_displayingCountForSearch
                            : i18n_displayingMobileCountForSearch}
                        </div>
                      )}
                      {totalItems && (
                        <div
                          className={paginationDisplayPage({
                            isMobile: (windowSize && windowSize > 996) as boolean,
                          })}
                        >
                          {i18n_displayingCountForSearch}
                        </div>
                      )}
                    </div>
                    <Pagination.NextPage
                      onClick={(e) => {
                        e.preventDefault();
                        scrollToSearchResults(page + 1);
                      }}
                      className={paginationIcon()}
                      tabIndex={-1}
                    >
                      {' '}
                      <Button
                        type="text"
                        label={deviceName === 'Desktop' ? i18n_next : ''}
                        iconRight="chevron_right"
                        disabled={isNextButtonDisabled}
                        tabIndex={isNextButtonDisabled ? -1 : 0}
                      />
                    </Pagination.NextPage>
                  </Pagination.Root>
                </div>
              </section>
            </>
          )}
          {totalItems <= 0 && !isFetching && (
            <div>
              <h3>{noSearchResult?.value}</h3>
            </div>
          )}
        </div>
      </div>
      <PowerReviewsPrductListWithSearch products={products} />
    </Container>
  );
};
const ProductListWithSearchWidget = widget(
  ProductListWithSearchComponent,
  WidgetDataType.SEARCH_RESULTS,
  'content'
);

export default ProductListWithSearchWidget;

// Global
import { Text, TextField } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useEffect, useState } from 'react';
import { tv } from 'tailwind-variants';

// Lib
import { ComponentProps } from 'lib/component-props';
import { CardComponents } from 'lib/templates/Feature.Dart.model';
import { ItemEx } from 'lib/templates/_.Sitecore.Override';

// Local
import Button from 'helpers/Button/Button';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import fallback from 'lib/fallback/fallback';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import SVG from 'helpers/SVG/SVG';
import { useTheme } from 'lib/context/ThemeContext';

export type BrandCard = ItemEx & CardComponents.BrandCard.BrandCardItem & NumOfCards;

interface NumOfCards {
  numOfCards: number | undefined;
}

export type BrandCardProps = ComponentProps & CardComponents.BrandCard.BrandCardsList;

export type BrandCategoryType = ItemEx &
  CardComponents.BrandCard.BrandCardCategoryItem &
  NumOfCards;

const tailwindVariantsBrandCard = tv({
  defaultVariants: {},
  slots: {
    componentBG: ['bg-components-tab-bg-2'],
    base: [
      'md:py-components-media-gallery-spacing-large-padding-y',
      'md:px-components-media-gallery-spacing-large-padding-x',
      'px-components-media-gallery-spacing-small-padding-x',
      'py-components-media-gallery-spacing-small-padding-y',
    ],
    contentWrapper: ['flex', 'flex-col', 'gap-spacing-spacing-7'],
    titleAndDescriptionWrapperOuter: [
      'w-full',
      'flex',
      'flex-col',
      'justify-between',
      'items-start',
      'self-stretch',
      'md:flex-row',
      'gap-spacing-spacing-7',
      'md:gap-spacing-spacing-0',
    ],
    titleAndDescriptionWrapperInner: ['flex', 'flex-col', 'flex-1'],
    titleText: [
      'font-header-large-medium',
      'leading-header-large-medium',
      'text-header-large-medium',
      'text-themes-text-color-dark',
    ],
    descriptionText: [
      'font-bodySans-small',
      'leading-bodySans-small',
      'text-bodySans-small',
      'text-components-article-card-listing-color-default-copy',
      'md:font-bodySans-medium',
      'md:leading-bodySans-medium',
      'md:text-bodySans-medium',
      'mb-components-top-title-spacing-small-body-margin-bottom',
      'md:mb-0',
    ],
    brandContainer: ['brand-Container', 'flex', 'flex-col', 'gap-spacing-spacing-7', 'md:flex-row'],
    brandTitle: [
      'rounded-themes-radius-large-button',
      'text-components-button-group-color-button-fg-active',
      'font-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'cursor-pointer',
      'text-nowrap',
      'hover:bg-components-button-group-color-button-bg-hover',
      'px-components-dropdown-item-padding-x',
      'py-components-dropdown-item-padding-y',
    ],
    brandContainerLeftWrapper: [
      'brand-information-left',
      'self-stretch',
      'flex',
      'flex-row',
      'overflow-x-auto',
      'pb-spacing-spacing-4',
      'md:pb-spacing-spacing-0',
      'gap-spacing-spacing-1',
      'md:max-w-[13rem]',
      'md:min-w-[10rem]',
      'w-full',
      'md:flex-col',
    ],
    brandContainerRightWrapper: [
      'brand-information-right',
      'justify-items-center',
      'md:justify-items-start',
      'grid',
      'md:grid-cols-6',
      'sm:grid-cols-3',
      'grid-rows-1',
      'gap-x-components-media-gallery-spacing-large-gallery-default-container-grid-gap-x',
      'gap-y-components-media-gallery-spacing-large-gallery-default-container-grid-gap-y',
    ],
    cardImageWrapper: [
      'justify-items-center',
      'md:justify-items-start',
      'md:h-[160px]',
      'content-center',
      'rounded-themes-radius-large-card',
      'hover:border-[1px]',
      'hover:border-themes-radius-large-card',
      'hover:border-themes-stroke-color-brand2',
      'cursor-pointer',
      'bg-color-system-brands-bg',
      'hover:bg-color-system-brands-bg-hover',
    ],
    ctaWrapper: [],
    buttonClasses: [
      'group',
      'w-[2.5rem]',
      'h-[2.5rem]',
      'fill-components-button-color-outline-brand-default-stroke',
      'border-components-button-color-outline-brand-default-stroke',
      'disabled:border-components-button-color-outline-brand-disabled-stroke',
      'disabled:bg-transparent',
      'disabled:fill-components-button-color-outline-brand-disabled-stroke',
      'border-components-button-color-outline-white-outline-stroke',
      'border-[3px]',
      'rounded-themes-radius-large-button',
      'mr-2',
      'hover:fill-components-button-color-outline-brand-hover-text',
      'hover:border-components-button-color-outline-brand-hover-bg',
      'hover:bg-components-button-color-outline-brand-hover-bg',
      'active:bg-components-button-color-outline-white-pressed-bg',
      'active:border-components-button-color-outline-white-pressed-stroke',
      'disabled:pointer-events-none',
      'items-center',
      'justify-center',
      'inline-flex',
    ],
    disclaimerClasses: ['pt-spacing-spacing-5'],
    filterTabcomponentBG: ['bg-components-product-results-list-with-search-color-bg'],
    filterTabcontentWrapper: ['flex', 'flex-col'],
    filterTabBrandTitle: [
      'rounded-themes-radius-large-button',
      'text-components-button-group-color-button-fg-default',
      'font-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'cursor-pointer',
      'text-nowrap',
      'hover:bg-components-button-group-color-button-bg-hover',
      'py-components-button-spacing-large-default-padding-y',
      'px-components-button-spacing-large-default-padding-x',
    ],
    filterTabButtons: [
      'flex',
      'flex-row',
      'overflow-x-auto',
      'gap-x-components-button-group-spacing-space-between',
      'pt-spacing-spacing-5',
      'pb-components-product-results-list-with-search-spacing-large-filter-space-between',
    ],
    filterTabContainer: [
      'filterTab-Container',
      'flex',
      'flex-col',
      'gap-spacing-spacing-7',
      'md:flex-row',
      'pt-components-top-title-spacing-large-margin-bottom',
    ],
    filterTabContainerWrapper: [
      'filterTab-Container-Wrapper',
      'grid',
      'md:grid-cols-4',
      'grid-rows-1',
      'w-full',
      'pb-components-product-results-list-with-search-spacing-large-card-container-margin-bottom',
      'gap-x-components-media-gallery-spacing-large-gallery-default-container-grid-gap-x',
      'gap-y-components-media-gallery-spacing-large-gallery-default-container-grid-gap-y',
    ],
    svgClassLarge: [
      'block',
      'lg:hidden',
      '[&>*]:h-full',
      '[&>*]:w-full',
      'fill-colors-accent-1-400',
    ],
    svgClassExtraLarge: [
      'hidden',
      'lg:block',
      '[&>*]:h-full',
      '[&>*]:w-full',
      'fill-colors-accent-1-400',
    ],
    cardWrapper: [
      'flex',
      'flex-col',
      'rounded-themes-radius-large-card',
      'bg-components-product-card-color-bg',
      'md:h-auto',
      'cursor-pointer',
      'hover:border-[1px]',
      'hover:border-themes-radius-large-card',
      'hover:border-themes-stroke-color-brand2',
      'hover:rounded-tr-themes-radius-large-image-card',
      'hover:rounded-tl-themes-radius-large-image-card',
      '[&_img]:hover:bg-color-system-brands-bg-hover',
      '[&_h2]:hover:text-themes-text-color-brand1',
      '[&_h2]:hover:underline',
    ],
    imageTab: [
      '!overflow-hidden',
      'rounded-tr-themes-radius-large-image-card',
      'rounded-tl-themes-radius-large-image-card',
      'h-auto',
      'w-full',
      'bg-color-system-brands-bg',
    ],
    filterTabContent: [
      'flex',
      'flex-col',
      'gap-components-product-card-spacing-large-title-margin-bottom',
      'px-components-product-card-spacing-large-padding-content-x',
      'py-components-product-card-spacing-large-padding-content-y',
    ],
    cardTitleText: [
      'font-header-large-xxSmall',
      'text-header-large-xxSmall',
      'leading-header-large-xxSmall',
      'text-components-product-card-color-title',
      'w-fit',
    ],
    cardDescriptionText: [
      'font-bodySans-medium',
      'leading-bodySans-medium',
      'text-bodySans-medium',
      'pb-[4px]',
      'line-clamp-2',
      'text-components-product-card-color-description',
    ],
  },
});

// Add fallback component variant color
const fallbackComponentVariantColor = fallback?.componentVariants?.value;
const fallbackComponentVariantType = fallback?.componentVariants?.type;

const BrandSingleCard = (props: BrandCard): JSX.Element => {
  const { brandImage, brandLink } = props?.fields || {};

  const { cardImageWrapper } = tailwindVariantsBrandCard({});

  return (
    <div className={cardImageWrapper()}>
      {brandImage?.value?.src && brandLink?.value?.href && (
        <LinkWrapper field={brandLink} suppressLinkText ignoreEE>
          <ImageWrapper field={brandImage} layout="intrinsic" />
        </LinkWrapper>
      )}
    </div>
  );
};

const BrandCategory = (props: BrandCategoryType): JSX.Element => {
  const { brands } = props?.fields || {};

  return (
    <>
      {brands?.map((item: BrandCard, key) => {
        return <BrandSingleCard key={key} {...item} numOfCards={brands?.length} />;
      })}
    </>
  );
};

const BrandCard = (props: BrandCardProps): JSX.Element => {
  const { componentName, dataSource } = props?.rendering || {};
  const {
    brandCategories,
    allBrandsText,
    description,
    primaryCTA,
    title,
    primaryCTAColor,
    primaryCTAType,
  } = props?.fields || {};

  const isBrowser = typeof window !== 'undefined';

  const [breakpoints, setBreakpoints] = useState('xs');
  const [windowSize, setWindowSize] = useState({
    width: isBrowser ? window.innerWidth : 0,
    height: isBrowser ? window.innerHeight : 0,
  });

  const [showAllBrands, setShowAllBrands] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  useEffect(() => {
    if (!isBrowser) {
      return; // Exit if it's not running in the browser
    }

    // Handle window resize
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Listen Event
    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();

    if (isBrowser) {
      if (windowSize.width >= 1440) {
        setBreakpoints('lg');
      } else if (windowSize.width >= 996) {
        setBreakpoints('md');
      } else if (windowSize.width >= 320) {
        setBreakpoints('sm');
      }
    }

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isBrowser, breakpoints, windowSize.width]);

  const handleAllBrandsClick = () => {
    setShowAllBrands(!showAllBrands);
    setSelectedCategory(null);
  };

  const handleCategoryClick = (categoryTitle: string) => {
    setSelectedCategory(categoryTitle);
    setShowAllBrands(false);
  };

  if (!props.fields) return <></>;

  const {
    componentBG,
    base,
    contentWrapper,
    titleAndDescriptionWrapperOuter,
    titleAndDescriptionWrapperInner,
    titleText,
    descriptionText,
    brandContainer,
    brandContainerLeftWrapper,
    brandContainerRightWrapper,
    brandTitle,
  } = tailwindVariantsBrandCard({});

  // Unique id for component
  const id = props?.params?.RenderingIdentifier;

  return (
    <div className={componentBG()} id={id ? id : undefined} tabIndex={id ? -1 : 1}>
      <div className={base()} data-component="authorable/general/brandcard" data-testid="brandcard">
        <div className={contentWrapper()}>
          {title?.value && primaryCTA?.value?.href && (
            <div className={titleAndDescriptionWrapperOuter()}>
              <div className={titleAndDescriptionWrapperInner()}>
                {title?.value && <Text className={titleText()} encode={false} field={title} />}
                {description?.value && (
                  <Text className={descriptionText()} encode={false} field={description} />
                )}
              </div>
              {primaryCTA?.value?.href && (
                <Button
                  href={primaryCTA?.value?.href}
                  label={primaryCTA?.value?.text}
                  tag="a"
                  color={primaryCTAColor?.value || fallbackComponentVariantColor}
                  type={primaryCTAType?.value || fallbackComponentVariantType}
                  size="compressed"
                  gtmEvent={{
                    event: 'cta_click',
                    type: 'primary',
                    'gtm.element.dataset.gtmLinkUrl': primaryCTA?.value?.href,
                    'gtm.element.dataset.gtmLinkName': primaryCTA?.value?.text,
                    'gtm.element.dataset.gtmDatasourceId': dataSource,
                    'gtm.element.dataset.gtmComponentName': componentName,
                  }}
                />
              )}
            </div>
          )}
          <div className={brandContainer()}>
            <div className={brandContainerLeftWrapper()}>
              <div
                className={`${brandTitle()} ${
                  showAllBrands ? 'bg-components-button-group-color-button-bg-active' : ''
                }`}
                onClick={handleAllBrandsClick}
              >
                {allBrandsText?.value && <Text encode={false} field={allBrandsText} />}
              </div>
              {brandCategories?.map((item: BrandCategoryType, key) => (
                <div
                  key={key}
                  className={`${brandTitle()} ${
                    selectedCategory === item?.fields?.title?.value
                      ? 'bg-components-button-group-color-button-bg-active'
                      : ''
                  }`}
                  onClick={() => handleCategoryClick(item?.fields?.title?.value || '')}
                >
                  <Text encode={false} field={item?.fields?.title as TextField | undefined} />
                </div>
              ))}
            </div>
            <div className={brandContainerRightWrapper()}>
              {showAllBrands
                ? brandCategories?.flatMap((item: BrandCategoryType) =>
                    item.fields?.brands?.map((brandItem: BrandCard, key) => (
                      <BrandSingleCard
                        key={key}
                        {...brandItem}
                        numOfCards={brandCategories?.length}
                      />
                    ))
                  )
                : selectedCategory
                ? brandCategories
                    ?.filter(
                      (item: BrandCategoryType) => item.fields?.title?.value === selectedCategory
                    )
                    ?.flatMap((item: BrandCategoryType) =>
                      item.fields?.brands?.map((brandItem: BrandCard, key) => (
                        <BrandSingleCard
                          key={key}
                          {...brandItem}
                          numOfCards={brandCategories?.length}
                        />
                      ))
                    )
                : brandCategories?.map((item: BrandCategoryType, key) => {
                    return (
                      <BrandCategory key={key} {...item} numOfCards={brandCategories?.length} />
                    );
                  })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FilterTabSingleCard = (props: BrandCard): JSX.Element => {
  const { brandImage, brandLink, brandName, brandDescription } = props?.fields || {};

  const { cardWrapper, filterTabContent, imageTab, cardTitleText, cardDescriptionText } =
    tailwindVariantsBrandCard({});
  return (
    <div className={cardWrapper()}>
      {brandImage?.value?.src && brandLink?.value?.href && (
        <LinkWrapper field={brandLink} suppressLinkText ignoreEE>
          <ImageWrapper className={imageTab()} field={brandImage} layout="intrinsic" />
          <div className={filterTabContent()}>
            <div className={cardTitleText()}>
              {brandName?.value && <Text tag="h2" encode={false} field={brandName} />}
            </div>

            <div className={cardDescriptionText()}>
              {brandDescription?.value && <Text encode={false} field={brandDescription} />}
            </div>
          </div>
        </LinkWrapper>
      )}
    </div>
  );
};

export const FilterTab = (props: BrandCardProps): JSX.Element => {
  const { brandCategories, allBrandsText } = props?.fields || {};

  const isBrowser = typeof window !== 'undefined';

  const [breakpoints, setBreakpoints] = useState('xs');
  const [windowSize, setWindowSize] = useState({
    width: isBrowser ? window.innerWidth : 0,
    height: isBrowser ? window.innerHeight : 0,
  });

  const [showAllBrands, setShowAllBrands] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  const { themeName } = useTheme();

  useEffect(() => {
    if (!isBrowser) {
      return; // Exit if it's not running in the browser
    }

    // Handle window resize
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Listen Event
    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();

    if (isBrowser) {
      if (windowSize.width >= 1440) {
        setBreakpoints('lg');
      } else if (windowSize.width >= 996) {
        setBreakpoints('md');
      } else if (windowSize.width >= 320) {
        setBreakpoints('sm');
      }
    }

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isBrowser, breakpoints, windowSize.width]);

  const handleAllBrandsClick = () => {
    setShowAllBrands(!showAllBrands);
    setSelectedCategory(null);
  };

  const handleCategoryClick = (categoryTitle: string) => {
    setSelectedCategory(categoryTitle);
    setShowAllBrands(false);
  };

  if (!props.fields) return <></>;

  const {
    filterTabcomponentBG,
    base,
    filterTabcontentWrapper,
    filterTabButtons,
    filterTabBrandTitle,
    filterTabContainer,
    filterTabContainerWrapper,
    svgClassLarge,
    svgClassExtraLarge,
  } = tailwindVariantsBrandCard({});

  // Unique id for component
  const id = props?.params?.RenderingIdentifier;

  return (
    <div className={filterTabcomponentBG()} id={id ? id : undefined}>
      <div className={base()} data-component="authorable/general/filterTab" data-testid="filterTab">
        <div className={filterTabcontentWrapper()}>
          <div className={filterTabButtons()}>
            <div
              className={`${filterTabBrandTitle()} ${
                showAllBrands ? 'bg-components-button-group-color-button-bg-hover' : ''
              }`}
              onClick={handleAllBrandsClick}
            >
              {allBrandsText?.value && <Text encode={false} field={allBrandsText} />}
            </div>
            {brandCategories?.map((item: BrandCategoryType, key) => (
              <div
                key={key}
                className={`${filterTabBrandTitle()} ${
                  selectedCategory === item?.fields?.title?.value
                    ? 'bg-components-button-group-color-button-bg-hover'
                    : ''
                }`}
                onClick={() => handleCategoryClick(item?.fields?.title?.value || '')}
              >
                <Text encode={false} field={item?.fields?.title as TextField | undefined} />
              </div>
            ))}
          </div>
          <SVG className={svgClassLarge()} svg={`rule-lines/Breakpoint=Large,Brand=${themeName}`} />
          <SVG
            className={svgClassExtraLarge()}
            svg={`rule-lines/Breakpoint=ExtraLarge,Brand=${themeName}`}
          />
          <div className={filterTabContainer()}>
            <div className={filterTabContainerWrapper()}>
              {showAllBrands
                ? brandCategories?.flatMap((item: BrandCategoryType) =>
                    item.fields?.brands?.map((brandItem: BrandCard, key) => (
                      <FilterTabSingleCard
                        key={key}
                        {...brandItem}
                        numOfCards={brandCategories?.length}
                      />
                    ))
                  )
                : selectedCategory
                ? brandCategories
                    ?.filter(
                      (item: BrandCategoryType) => item.fields?.title?.value === selectedCategory
                    )
                    ?.flatMap((item: BrandCategoryType) =>
                      item.fields?.brands?.map((brandItem: BrandCard, key) => (
                        <FilterTabSingleCard
                          key={key}
                          {...brandItem}
                          numOfCards={brandCategories?.length}
                        />
                      ))
                    )
                : brandCategories?.map((item: BrandCategoryType, key) => {
                    return (
                      <BrandCategory key={key} {...item} numOfCards={brandCategories?.length} />
                    );
                  })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandCard;

// Global
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useCallback } from 'react';
import type { PreviewSearchInitialState } from '@sitecore-search/react';
import { WidgetDataType, usePreviewSearch, widget } from '@sitecore-search/react';
import { ArticleCard, Presence, PreviewSearch } from '@sitecore-search/ui';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { useRouter } from 'next/navigation';
import { tv } from 'tailwind-variants';
import { sendGTMEvent } from '@next/third-parties/google';

// Local
import GoogleMaterialSymbol from 'helpers/GoogleMaterialSymbol/GoogleMaterialSymbol';
import getHighlightText from 'components/utils/getHighlightText';
import { useI18n } from 'next-localization';
import { stripHtml } from 'lib/utils/regex';

interface SearchPreviewProps {
  autoSuggestionItems: number;
  resultSuggestionItems: number;
  searchPlaceholderText?: string | '';
  defaultValue: string | '';
}

type ProductModel = {
  id: string;
  name: string;
  image_url: string;
  price: string;
  url: string;
  source_id?: string;
  author?: string;
  highlight?: HighlightModel;
};

type HighlightModel = {
  name?: Array<string>;
};

type SearchSettings = {
  previewSource: string;
  source: string;
  searchPageLink: string;
};

type InitialState = PreviewSearchInitialState<'itemsPerPage' | 'suggestionsList'>;

const tailwindVariants = tv({
  slots: {
    previewSearchRootWrapper: [
      'previewSearchRoot-wrapper',
      'relative',
      'w-full',
      'flex',
      'items-center',
      'pr-components-text-field-input-padding-x',
      'py-components-text-field-input-padding-y',
      'bg-components-text-field-bg',
      'max-w-[519px]',
      'rounded',
      'border-2',
      'border-components-text-field-border-default',
      'rounded-themes-radius-large-form-field-input',
    ],
    searchInputIconWrapper: ['flex', 'items-center'],
    searchInputIconBtn: ['flex', 'justify-center', 'items-center', 'w-[48px]', 'h-[48px] '],
    searchInputIcon: ['fill-components-text-field-icon-default'],
    searchInputFocusedIcon: ['fill-colors-grayscale-400'],
    searchInput: [
      'w-full',
      'box-border',
      'font-bodySans-medium',
      'text-bodySans-medium',
      'leading-bodySans-medium',
      'bg-components-text-field-bg',
      'focus:!outline-none',
      'focus-visible:!outline-none',
    ],
    closeInputIconBtn: ['flex', 'justify-center', 'items-center', 'w-[48px]', 'h-[48px]'],
    closeInputIcon: ['fill-components-text-field-icon-default'],
    previewSearchContentWrapper: [
      '[&>*]:!absolute',
      '[&>*]:!min-w-full',
      '[&>*]:!top-[103%]',
      '[&>*]:left-0',
      '[&>*]:!w-full',
      'md:[&>*]:!w-[100%]',
      '[&>*]:!transform-none',
      '[&>*]:!z-20',
      '[&>*]:!shadow-md',
    ],
    previewSearchContentMain: [
      'content-wrapper',
      'bg-components-global-search-color-omnibox-list-bg',
      'py-components-global-search-spacing-omnibox-list-padding-y',
      'rounded-spacing-spacing-1',
      'max-h-[425px]',
      'overflow-y-scroll',
    ],
    searchContentSuggestionsHeading: [
      'font-bodySans-xSmall',
      'text-bodySans-xSmall',
      'text-components-global-search-color-omnibox-label-text-default',
      'bg-components-global-search-color-omnibox-item-bg-default',
      'leading-bodySans-xSmall',
      'p-components-global-search-spacing-omnibox-label-padding',
    ],
    searchResultsItemWrapper: [
      'flex',
      'gap-[12px]',
      'items-center',
      'justify-between',
      'cursor-pointer',
      'py-components-global-search-spacing-omnibox-item-padding-y',
      'px-components-global-search-spacing-omnibox-item-padding-x',
    ],
    searchResultsItemTitleWrapper: ['w-full'],
    searchResultsItemTitle: [
      'font-bodySans-medium',
      'text-bodySans-medium',
      'text-components-global-search-color-omnibox-item-text-default',
      'leading-bodySans-medium',
    ],
    searchResultsItemIconWrapper: ['icon-wrapper', 'h-[24px]', 'w-[24px]'],
    searchResultsItemIcon: ['fill-components-global-search-color-omnibox-item-text-default'],
    searchProductSuggestionWrapper: [
      'mb-2',
      'border-b-[1px]',
      'border-components-global-search-color-omnibox-divider',
      'mx-components-global-search-spacing-omnibox-category-padding-x',
    ],
    searchProductSuggestionHeading: [
      'font-bodySans-small-semibold',
      'text-bodySans-small-semibold',
      'text-components-global-search-color-omnibox-category-text-default',
      'leading-bodySans-small-semibold',
      'py-components-global-search-spacing-omnibox-category-padding-y',
    ],
    searchProductSuggestionItemWrapper: [
      'flex',
      'gap-[12px]',
      'items-center',
      'justify-between',
      'cursor-pointer',
      'py-components-global-search-spacing-omnibox-item-padding-y',
    ],
    searchArticleSuggestionWrapper: [
      'mx-components-global-search-spacing-omnibox-category-padding-x',
    ],
    searchResultsShowMoreItem: [
      'font-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'py-components-global-search-spacing-omnibox-item-padding-y',
      'text-components-global-search-color-omnibox-item-text-default',
    ],
    searchLoaderContainer: ['flex', 'flex-1', 'items-center'],
    searchLoaderAnimation: [
      'animate-spin',
      'block',
      'h-[80px]',
      'w-[80px]',
      'm-auto',
      'bg-components-omnibox-color-loader-icon-fill-default',
      '[clip-path:path("M73.3333_40.0038C73.3333_58.4115_58.411_73.3338_40.0033_73.3338C21.5957_73.3338_6.67334_58.4115_6.67334_40.0038C6.67334_21.5962_21.5957_6.67383_40.0033_6.67383C58.411_6.67383_73.3333_21.5962_73.3333_40.0038ZM13.3393_40.0038C13.3393_54.73_25.2772_66.6678_40.0033_66.6678C54.7295_66.6678_66.6673_54.73_66.6673_40.0038C66.6673_25.2777_54.7295_13.3398_40.0033_13.3398C25.2772_13.3398_13.3393_25.2777_13.3393_40.0038Z")]',
    ],
  },
  variants: {
    isInputFocused: {
      true: {
        previewSearchRootWrapper: ['border-2', 'border-components-text-field-border-focused'],
        searchInput: [
          'placeholder-components-text-field-input-field-default',
          'text-components-text-field-input-field-focused',
        ],
      },
      false: {
        previewSearchRootWrapper: [],
        searchInput: [
          'placeholder-components-text-field-input-field-default',
          'text-components-text-field-input-field-default',
        ],
      },
    },
  },
});

export const SearchPreviewComponent = (props: SearchPreviewProps) => {
  const [inputValue, setValue] = useState(props.defaultValue || '');
  const [isInputFocused, setInputFocused] = useState(false);

  const { sitecoreContext } = useSitecoreContext();
  const searchSettings = sitecoreContext?.searchDetails as SearchSettings;
  //const searchSourceIds = searchSettings?.source?.split('|') || [];
  const searchSourceIds = process.env.IS_PREVIEW_SITE
    ? searchSettings?.previewSource?.split('|') || []
    : searchSettings?.source?.split('|') || [];
  const refSearchInput = useRef<HTMLInputElement>(null);
  const refSearchDefault = useRef<HTMLDivElement>(null);

  const i18n = useI18n();
  const ShowAllResults = i18n.t('ShowAllResults');
  const Suggestions = i18n.t('Suggestions');
  const Articles = i18n.t('Articles');
  const Products = i18n.t('Products');

  const router = useRouter();

  const {
    previewSearchRootWrapper,
    searchInputIconWrapper,
    searchInputIconBtn,
    searchInputIcon,
    searchInputFocusedIcon,
    searchInput,
    closeInputIconBtn,
    closeInputIcon,
    previewSearchContentWrapper,
    previewSearchContentMain,
    searchContentSuggestionsHeading,
    searchResultsItemWrapper,
    searchResultsItemTitleWrapper,
    searchResultsItemTitle,
    searchResultsItemIconWrapper,
    searchResultsItemIcon,
    searchProductSuggestionWrapper,
    searchProductSuggestionHeading,
    searchProductSuggestionItemWrapper,
    searchArticleSuggestionWrapper,
    searchResultsShowMoreItem,
    searchLoaderContainer,
    searchLoaderAnimation,
  } = tailwindVariants({});
  const {
    actions: { onItemClick, onKeyphraseChange, onSuggestionClick },
    queryResult,
    queryResult: {
      isFetching,
      isLoading,
      data: {
        suggestion: {
          dart_auto_suggester: articleSuggestions = [],
          dart_product_auto_suggester: productSuggestions = [],
        } = {},
      } = {},
    },
  } = usePreviewSearch<ProductModel, InitialState>({
    query: (query) => {
      query
        .getRequest()
        .setSources(searchSourceIds)
        .setSearchFacetAll(false)
        .setSearchQueryHighlightFragmentSize(500)
        .setSearchQueryHighlightFields(['name', 'title'])
        .setSearchQueryHighlightPreTag('<b>')
        .setSearchQueryHighlightPostTag('</b>');
    },
    state: {
      suggestionsList: [
        {
          suggestion: 'dart_product_auto_suggester',
          max: props.autoSuggestionItems,
        },
        {
          suggestion: 'dart_auto_suggester',
          max: props.autoSuggestionItems,
        },
      ],
      itemsPerPage: props.resultSuggestionItems,
    },
  });
  const loading = isLoading || isFetching;

  const keyphraseHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const target = event.target;
      onKeyphraseChange({
        keyphrase: target.value,
      });
    },
    [onKeyphraseChange]
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setValue(inputValue);
  };

  const handleSearch = (handleInputValue: string) => {
    if (handleInputValue)
      return router.push(`${searchSettings.searchPageLink}?q=${handleInputValue}`);
    if (!handleInputValue) {
      refSearchInput?.current?.focus();
      setInputFocused(true);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch(inputValue);
      setInputFocused(false);
      setValue(inputValue);
      sendGTMEvent({
        event: 'search',
        type: 'query',
        'gtm.element.dataset.gtmLinkName': inputValue,
      });
    }
  };

  const handleSearchButton = (handleInputValue: string) => {
    if (handleInputValue)
      return router.push(`${searchSettings.searchPageLink}?q=${handleInputValue}`);
    if (!handleInputValue) {
      refSearchInput?.current?.focus();
      setInputFocused(true);
    }
  };

  const handleClose = () => {
    setValue(props.defaultValue);
    setInputFocused(false);
    refSearchInput?.current?.focus;
  };

  const regex = /(<([^>]+)>)/gi;

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent): void => {
      const targetNode = e.target as Node;
      if (refSearchDefault?.current && !refSearchDefault?.current?.contains(targetNode)) {
        setInputFocused(false);
      }
    };

    const handleTabKey = (event: KeyboardEvent): void => {
      const targetNode = 'target' in event ? (event.target as Node) : null;

      if (
        event.key === 'Tab' &&
        refSearchDefault?.current &&
        !refSearchDefault?.current?.contains(targetNode)
      ) {
        setInputFocused(false);
        setValue(props.defaultValue);
        return;
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('keyup', handleTabKey);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('keyup', handleTabKey);
    };
  }, [refSearchDefault, isInputFocused, props.defaultValue]);

  return (
    <PreviewSearch.Root>
      <div
        className={previewSearchRootWrapper({ isInputFocused: isInputFocused })}
        ref={refSearchDefault}
      >
        <div className={searchInputIconWrapper()}>
          <button
            aria-label="search"
            className={searchInputIconBtn()}
            type="submit"
            onClick={() => {
              handleSearchButton(inputValue);
            }}
          >
            <GoogleMaterialSymbol
              className={`${isInputFocused ? searchInputFocusedIcon() : searchInputIcon()}`}
              icon="search"
              variant="outlined"
            />
          </button>
        </div>
        <label htmlFor="searchboxh" className="hidden">
          {props?.searchPlaceholderText}
        </label>
        <PreviewSearch.Input
          onChange={(e) => {
            setInputFocused(true);
            keyphraseHandler(e);
            handleChange(e);
          }}
          onClick={() => {
            !isInputFocused && setInputFocused(true);
          }}
          ref={refSearchInput}
          id="searchboxh"
          autoComplete="off"
          placeholder={props.searchPlaceholderText}
          className={searchInput({ isInputFocused: isInputFocused })}
          value={inputValue ?? ''}
          onKeyUp={handleKeyPress}
          key={'search-preview-input'}
        />
        {isInputFocused && (
          <button
            aria-label="search"
            className={closeInputIconBtn()}
            type="submit"
            onClick={handleClose}
          >
            <GoogleMaterialSymbol className={closeInputIcon()} icon="close" variant="outlined" />
          </button>
        )}
        {isInputFocused && (
          <div className={previewSearchContentWrapper()}>
            <div data-loading={loading} className={previewSearchContentMain()}>
              <Presence present={loading && isInputFocused}>
                <div className={searchLoaderContainer()}>
                  <div className={searchLoaderAnimation()} />
                </div>
              </Presence>
              <Presence present={!loading && isInputFocused}>
                <>
                  <h2 className={searchContentSuggestionsHeading()}>{Suggestions}</h2>
                  <PreviewSearch.Results defaultQueryResult={queryResult}>
                    {({ data: { content: articles = [] } = {} }) => {
                      return (
                        <PreviewSearch.Items>
                          {articles.map((article: ProductModel, index: number) => (
                            <PreviewSearch.Item key={article.id} asChild>
                              <a
                                href={article.url}
                                onClick={(e) => {
                                  e.preventDefault();
                                  onItemClick({
                                    id: article.id,
                                    index,
                                    sourceId: article.source_id,
                                  });
                                  const baseUrl = new URL(article.url);
                                  const path = baseUrl.pathname;
                                  setValue(props.defaultValue);
                                  setInputFocused(false);
                                  return router.push(path);
                                }}
                                className={searchResultsItemWrapper()}
                              >
                                <ArticleCard.Root className={searchResultsItemTitleWrapper()}>
                                  <ArticleCard.Title className={searchResultsItemTitle()}>
                                    {inputValue
                                      ? getHighlightText(
                                          article.name.replaceAll(regex, ''),
                                          inputValue
                                        )
                                      : article.name.replaceAll(regex, '')}
                                  </ArticleCard.Title>
                                </ArticleCard.Root>
                                <div className={searchResultsItemIconWrapper()}>
                                  <GoogleMaterialSymbol
                                    className={searchResultsItemIcon()}
                                    icon="chevron_right"
                                    variant="outlined"
                                  />
                                </div>
                              </a>
                            </PreviewSearch.Item>
                          ))}
                        </PreviewSearch.Items>
                      );
                    }}
                  </PreviewSearch.Results>
                  {productSuggestions.length > 0 && (
                    <PreviewSearch.SuggestionsGroup
                      id="dart_product_auto_suggester"
                      filterAttribute="category_id"
                      className={searchProductSuggestionWrapper()}
                    >
                      <h2 className={searchProductSuggestionHeading()}>{Products}</h2>
                      {productSuggestions.map(({ text }) => (
                        <PreviewSearch.SuggestionItem id={text} key={text} asChild>
                          <a
                            onClick={() => {
                              onSuggestionClick({
                                name: 'dart_product_auto_suggester',
                                title: 'Products',
                                value: text,
                                displayName: text,
                              });
                              const path = `${searchSettings.searchPageLink}?q=${stripHtml(text)}`;
                              setValue(stripHtml(text) as string);
                              setInputFocused(false);
                              return router.push(path);
                            }}
                            href={`${searchSettings.searchPageLink}?q=${stripHtml(text)}`}
                            className={searchProductSuggestionItemWrapper()}
                          >
                            <div className={searchResultsItemTitleWrapper()}>
                              <span className={searchResultsItemTitle()}>
                                {getHighlightText(text.replaceAll(regex, ''), inputValue)}
                              </span>
                            </div>
                            <div className={searchResultsItemIconWrapper()}>
                              <GoogleMaterialSymbol
                                className={searchResultsItemIcon()}
                                icon="chevron_right"
                                variant="outlined"
                              />
                            </div>
                          </a>
                        </PreviewSearch.SuggestionItem>
                      ))}
                    </PreviewSearch.SuggestionsGroup>
                  )}
                  {articleSuggestions.length > 0 && (
                    <PreviewSearch.SuggestionsGroup
                      id="dart_auto_suggester"
                      className={searchProductSuggestionWrapper()}
                    >
                      <h2 className={searchProductSuggestionHeading()}>{Articles}</h2>
                      {articleSuggestions.map(({ text }) => (
                        <PreviewSearch.SuggestionItem id={text} key={text} asChild className={''}>
                          <a
                            onClick={() => {
                              onSuggestionClick({
                                name: 'dart_auto_suggester',
                                title: 'Articles',
                                value: text,
                                displayName: text,
                              });
                              const path = `${searchSettings.searchPageLink}?q=${stripHtml(text)}`;
                              setValue(stripHtml(text) as string);
                              setInputFocused(false);
                              return router.push(path);
                            }}
                            href={`${searchSettings.searchPageLink}?q=${stripHtml(text)}`}
                            className={searchProductSuggestionItemWrapper()}
                          >
                            <div className={searchResultsItemTitleWrapper()}>
                              <span className={searchResultsItemTitle()}>
                                {getHighlightText(text.replaceAll(regex, ''), inputValue)}
                              </span>
                            </div>
                            <div className={searchResultsItemIconWrapper()}>
                              <GoogleMaterialSymbol
                                className={searchResultsItemIcon()}
                                icon="chevron_right"
                                variant="outlined"
                              />
                            </div>
                          </a>
                        </PreviewSearch.SuggestionItem>
                      ))}
                    </PreviewSearch.SuggestionsGroup>
                  )}
                  {inputValue && (
                    <div className={searchArticleSuggestionWrapper()}>
                      <a
                        onClick={() => {
                          const path = `${searchSettings.searchPageLink}?q=${stripHtml(
                            inputValue
                          )}`;
                          setValue(props.defaultValue);
                          setInputFocused(false);
                          return router.push(path);
                        }}
                        href={`${searchSettings.searchPageLink}?q=${stripHtml(inputValue)}`}
                        className={searchProductSuggestionItemWrapper()}
                      >
                        <div className={searchResultsItemTitleWrapper()}>
                          <span className={searchResultsShowMoreItem()}>{ShowAllResults}</span>
                        </div>
                        <div className={searchResultsItemIconWrapper()}>
                          <GoogleMaterialSymbol
                            className={searchResultsItemIcon()}
                            icon="chevron_right"
                            variant="outlined"
                          />
                        </div>
                      </a>
                    </div>
                  )}
                </>
              </Presence>
            </div>
          </div>
        )}
      </div>
    </PreviewSearch.Root>
  );
};
const SearchPreview = widget(SearchPreviewComponent, WidgetDataType.PREVIEW_SEARCH, 'content');
export default SearchPreview;

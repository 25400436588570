// Global
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { Splide } from '@splidejs/react-splide';
import { isArray } from 'lodash';
import React, { createContext, useEffect, useState } from 'react';
import { tv } from 'tailwind-variants';

// Lib
import { ComponentProps } from 'lib/component-props';
import { ComponentVariants } from 'lib/context/ComponentVariants';
import { ALL_THEMES, useTheme } from 'lib/context/ThemeContext';
import { CardComponents, ProductAndArticleComponent } from 'lib/templates/Feature.Dart.model';
import { ItemEx } from 'lib/templates/_.Sitecore.Override';
import { Common } from 'lib/templates/Foundation.Dart.model';

// Local
import ProductSingleCard from './Component/ProductSingleCard';
import Carousel from 'components/authorable/Carousel/Carousel';
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import Button from 'helpers/Button/Button';
import Label from 'helpers/Label/Label';
import LegalDisclaimer from 'helpers/LegalDisclaimer/LegalDisclaimer';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import SVG from 'helpers/SVG/SVG';

export type Badge = CardComponents.ProductCard.Badge;
export type Label = Common.Label.Label;
export type ProductInfo = ProductAndArticleComponent.ProductInformation.ProductInformation;
export type ProductCard = ItemEx & CardComponents.ProductCard.ProductCardItem & Badge;
export type ProductCardProps = ComponentProps & CardComponents.ProductCard.ProductCardsList;

export const ProductCardContext = createContext('');

const themeVariants = ALL_THEMES.reduce(
  (acc, curr) => ((acc[curr] = {}), acc),
  {} as Record<string, object>
);

const tailwindVariants = tv({
  slots: {
    background: ['overflow-hidden', 'relative'],
    base: [
      'px-components-category-card-listing-spacing-small-padding-x',
      'py-components-category-card-listing-spacing-small-padding-y',
      'md:px-components-product-card-listing-spacing-large-padding-x',
      'md:py-components-product-card-listing-spacing-large-padding-y',
    ],
    buttonClasses: [
      'border-[3px]',
      'border-components-button-color-outline-brand-default-stroke',
      'group',
      'h-[2.5rem]',
      'inline-flex',
      'items-center',
      'justify-center',
      'mr-2',
      'rounded-themes-radius-large-button',
      'w-[2.5rem]',
      'active:bg-components-button-color-outline-brand-pressed-bg',
      'active:border-components-button-color-outline-brand-pressed-stroke',
      'disabled:bg-transparent',
      'disabled:border-components-button-color-outline-brand-disabled-stroke',
      'disabled:pointer-events-none',
      'disabled:text-components-button-color-outline-white-disabled-text',
      'hover:bg-components-button-color-outline-brand-hover-bg',
      'hover:border-components-button-color-outline-brand-hover-stroke',
    ],
    container: ['relative', 'z-10'],
    contentCarousel: [
      'flex',
      'flex-1',
      'flex-col',
      '-mr-[calc(theme(spacing.components-category-card-listing-spacing-small-padding-x))]',
      'order-2',
      'lg:order-1',
      'max-lg:px-0',
      'max-lg:py-0',
      'md:-mr-[calc(theme(spacing.components-product-card-listing-spacing-large-padding-x))]',
    ],
    contentCarouselSingle: [
      'flex',
      'flex-1',
      'flex-col',
      'order-2',
      'lg:order-1',
      'max-lg:px-0',
      'max-lg:py-0',
      'sm:w-1/2',
      'md:w-1/3',
    ],
    contentCarouselTrack: [],
    controlContainerClasses: [
      'flex',
      'flex-row',
      'gap-6',
      'items-center',
      'justify-center',
      'md:justify-end',
      'md:px-components-hero-homepage-spacing-large-padding-x',
    ],
    ctaWrapper: ['flex', 'items-center'],
    descriptionText: [
      'font-bodySans-small',
      'leading-bodySans-small',
      'text-bodySans-small',
      'text-components-top-title-color-body-light',
      'md:font-bodySans-medium',
      'md:leading-bodySans-medium',
      'md:text-bodySans-medium',
    ],
    iconClasses: [
      'fill-components-button-color-outline-brand-default-text',
      'group-disabled:fill-components-button-color-outline-brand-disabled-text',
      'group-hover:fill-components-button-color-outline-brand-hover-text',
    ],
    iconNext: ['chevron_right'],
    iconPrev: ['chevron_left'],
    legalDisclaimerText: ['pt-spacing-spacing-5'],
    playPauseFill: ['fill-components-button-color-outline-white-default-icon'],
    productCardListingCarousel: ['product-card-listing', 'w-full', '[&_li]:flex'],
    svgBg: [
      'absolute',
      'fill-white',
      'left-2/4',
      '-mt-[155px]', // MAGIC NUMBER: The desired offset from the center of the container
      'top-0',
      '-translate-x-2/4',
      '-translate-y-2/4',
      'w-[1168px]',
      'md:-mt-[745px]', // MAGIC NUMBER: The desired offset from the center of the container
      'md:w-[2488px]',
    ],
    svgDecorLower: ['absolute', 'bottom-0', 'hidden', 'left-0', 'w-[132px]', 'md:block'],
    svgDecorUpper: [
      'absolute',
      'hidden',
      'left-0',
      'm-auto',
      'mt-[110px]',
      'right-0',
      'top-0',
      'w-full',
      '[&>svg]:m-auto',
      '[&>svg]:w-full',
      'md:block',
    ],
    titleAndDescriptionWrapperInner: [
      'flex',
      'flex-col',
      'mb-components-top-title-spacing-small-body-margin-bottom',
      'md:mb-0',
    ],
    titleAndDescriptionWrapperOuter: [
      'flex',
      'flex-col',
      'justify-between',
      'mb-components-top-title-spacing-small-margin-bottom',
      'text-center',
      'w-full',
      'md:flex-row',
      'md:text-start',
      'md:mb-components-top-title-spacing-large-margin-bottom',
    ],
    titleText: [
      'font-header-small-large',
      'leading-header-small-large',
      'text-header-small-large',
      'text-components-product-card-listing-color-title',
      'mb-components-top-title-spacing-small-title-margin-bottom',
      'md:font-header-large-large',
      'md:leading-header-large-large',
      'md:text-header-large-large',
      'md:mb-components-top-title-spacing-large-title-margin-bottom',
    ],
  },
  variants: {
    addMarginBottom: {
      undefined: {
        contentCarouselTrack: [
          'mb-components-product-card-listing-spacing-large-card-margin-bottom',
        ],
      },
      3: {
        contentCarouselTrack: [
          'md:mb-0',
          'mb-components-product-card-listing-spacing-large-card-margin-bottom',
        ],
      },
      2: {
        contentCarouselTrack: [
          'sm:mb-0',
          'mb-components-product-card-listing-spacing-large-card-margin-bottom',
        ],
      },
      1: {
        contentCarouselTrack: ['mb-0'],
      },
    },
    brand: {
      ...themeVariants,
      Off: {
        background: ['bg-colors-brand-1-400'],
        ctaWrapper: ['justify-center'],
        descriptionText: ['text-components-top-title-color-title-dark'],
        productCardListingCarousel: ['m-auto', 'max-w-[1280px]'],
        titleAndDescriptionWrapperInner: [
          'md:mb-components-top-title-spacing-large-body-margin-bottom',
          'md:text-center',
        ],
        titleAndDescriptionWrapperOuter: ['md:flex-col'],
        titleText: ['text-components-top-title-color-title-dark'],
        svgDecorUpper: 'mt-[76px]',
        svgBg: ['md:-mt-[778px]', '-mt-[155px]'],
      },
      Autan: {
        background: ['bg-colors-brand-1-400'],
        ctaWrapper: ['justify-center'],
        descriptionText: ['text-components-top-title-color-title-dark'],
        productCardListingCarousel: ['m-auto', 'max-w-[1280px]'],
        titleAndDescriptionWrapperInner: [
          'md:mb-components-top-title-spacing-large-body-margin-bottom',
          'md:text-center',
        ],
        titleAndDescriptionWrapperOuter: ['md:flex-col'],
        titleText: ['text-components-top-title-color-title-dark'],
        svgDecorUpper: ['mt-[92px]'],
      },
      AutanDefense: {
        background: ['components-product-card-listing-color-bg'],
        ctaWrapper: ['justify-center'],
        descriptionText: ['text-components-top-title-color-title-dark'],
        productCardListingCarousel: ['m-auto', 'max-w-[1280px]'],
        titleAndDescriptionWrapperInner: [
          'md:mb-components-top-title-spacing-large-body-margin-bottom',
          'md:text-center',
        ],
        titleAndDescriptionWrapperOuter: ['md:flex-col'],
        titleText: ['text-components-top-title-color-title-dark'],
      },
    },
  },
});

const ProductCard = (props: ProductCardProps): JSX.Element => {
  const { description, disclaimerText, primaryCTA, title, primaryCTAColor, primaryCTAType } =
    props?.fields || {};
  const { componentName, dataSource } = props?.rendering || {};
  const { autoPlay, isBadgeFullWidth } = props?.params || {};

  const contentRef = React.useRef<Splide>(null);
  const isBrowser = typeof window !== 'undefined';

  const [breakpoints, setBreakpoints] = useState('xs');
  const [themeBg, setThemeBg] = useState({});
  const [windowSize, setWindowSize] = useState({
    width: isBrowser ? window.innerWidth : 0,
    height: isBrowser ? window.innerHeight : 0,
  });

  const componentVariants = ComponentVariants();
  const { themeName } = useTheme();

  useEffect(() => {
    if (!isBrowser) {
      return; // Exit if it's not running in the browser
    }

    // Handle window resize
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Listen Event
    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();

    if (isBrowser) {
      if (windowSize.width >= 1440) {
        setBreakpoints('lg');
      } else if (windowSize.width >= 996) {
        setBreakpoints('md');
      } else if (windowSize.width >= 320) {
        setBreakpoints('sm');
      }
    }

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isBrowser, breakpoints, windowSize.width]); // Include isBrowser in the dependency array

  useEffect(() => {
    switch (themeName) {
      case 'Off':
        setThemeBg({});
        break;
      case 'Ziploc':
        setThemeBg({
          background: 'linear-gradient(180deg, #032C87 33.13%, #0241CD 55%, #0CF 100%, #032C87)',
        });
        break;
      default:
        setThemeBg({});
        break;
    }
  }, [themeName]);

  if (!props.fields) return <></>;

  const {
    background,
    base,
    buttonClasses,
    container,
    contentCarousel,
    contentCarouselSingle,
    contentCarouselTrack,
    controlContainerClasses,
    ctaWrapper,
    descriptionText,
    iconClasses,
    iconNext,
    iconPrev,
    legalDisclaimerText,
    playPauseFill,
    productCardListingCarousel,
    svgBg,
    svgDecorLower,
    svgDecorUpper,
    titleAndDescriptionWrapperInner,
    titleAndDescriptionWrapperOuter,
    titleText,
    /* eslint-disable  @typescript-eslint/ban-ts-comment */
    // @ts-ignore
  } = tailwindVariants({ brand: themeName as string });

  // Unique id for component
  const id = props?.params?.RenderingIdentifier;

  return (
    <ProductCardContext.Provider value={props.rendering.uid ?? ''}>
      <div
        className={background()}
        data-component="authorable/cards/productcard"
        style={themeBg}
        id={id ? id : undefined}
        tabIndex={id ? -1 : 1}
      >
        {componentVariants?.productCardListing?.hasDecor && (
          <>
            <SVG className={svgBg()} svg={`ProductCard/Bg,Brand=${themeName}`} />
            <SVG className={svgDecorUpper()} svg={`ProductCard/Decor_Upper,Brand=${themeName}`} />
            <SVG className={svgDecorLower()} svg={`ProductCard/Decor_Lower,Brand=${themeName}`} />
          </>
        )}
        <Container className={container()}>
          <div
            className={base()}
            data-component="authorable/general/ProductCard"
            data-testid="ProductCard"
          >
            {title && (
              <div className={titleAndDescriptionWrapperOuter()}>
                <div className={titleAndDescriptionWrapperInner()}>
                  {title?.value && (
                    <Text className={titleText()} encode={false} field={title} tag="h2" />
                  )}
                  {description?.value && (
                    <div className={descriptionText()}>
                      <RichTextA11yWrapper field={description} />
                    </div>
                  )}
                </div>
                {primaryCTA?.value && (
                  <div className={ctaWrapper()}>
                    <Button
                      type={
                        primaryCTAType?.value ||
                        componentVariants?.productCardListing?.primaryCta?.type
                      }
                      color={
                        primaryCTAColor?.value ||
                        componentVariants?.productCardListing?.primaryCta?.color
                      }
                      href={primaryCTA?.value?.href}
                      gtmEvent={{
                        event: 'cta_click',
                        type: 'primary',
                        'gtm.element.dataset.gtmLinkUrl': primaryCTA?.value?.href,
                        'gtm.element.dataset.gtmLinkName': primaryCTA?.value?.text,
                        'gtm.element.dataset.gtmDatasourceId': dataSource,
                        'gtm.element.dataset.gtmComponentName': componentName,
                      }}
                      label={primaryCTA?.value.text}
                      tag="a"
                    />
                  </div>
                )}
              </div>
            )}
            {isArray(props.fields?.productCards) && props?.fields?.productCards?.length > 1 ? (
              <div className={productCardListingCarousel()}>
                <Carousel
                  key={props.rendering.dataSource}
                  autoPlay={autoPlay}
                  hasTrack={false}
                  ref={contentRef}
                  carouselClasses={contentCarousel()}
                  trackClasses={contentCarouselTrack({
                    addMarginBottom: props.fields.productCards.length as 3 | 2 | 1 | undefined,
                  })}
                  gtmEvent={{
                    'gtm.element.dataset.gtmDatasourceId': dataSource,
                    'gtm.element.dataset.gtmComponentName': componentName,
                  }}
                  options={{
                    arrows: props.fields?.productCards?.length > 1,
                    type: 'slide',
                    autoplay: true,
                    drag: true,
                    isNavigation: false,
                    rewind: false,
                    interval: 3600,
                    perPage: 1,
                    trimSpace: false,
                    gap: 10,
                    padding: { left: 0, right: props.fields?.productCards?.length > 1 ? 24 : 0 },
                    pagination: false,
                    breakpoints: {
                      576: {
                        arrows: props.fields?.productCards?.length > 2,
                        perPage: 2,
                        gap: 10,
                        padding: { left: 0, right: 24 },
                      },
                      996: {
                        arrows: props.fields?.productCards?.length > 3,
                        perPage: props.fields?.productCards?.length > 2 ? 3 : 2,
                        gap: 24,
                        padding: {
                          left: 0,
                          right: props.fields?.productCards?.length > 3 ? '6.5%' : '',
                        },
                      },
                    },
                  }}
                  carouselControls={{
                    controlContainerClasses: controlContainerClasses(),
                    buttonClasses: buttonClasses(),
                    buttons: {
                      classes: iconClasses(),
                      prev: {
                        icon: iconPrev(),
                      },
                      next: {
                        icon: iconNext(),
                      },
                      playPause: playPauseFill(),
                    },
                    paginationVariant: 'color',
                  }}
                >
                  {props?.fields?.productCards?.map((item: ProductCard, key) => {
                    return (
                      <React.Fragment key={key}>
                        <ProductSingleCard
                          {...item}
                          indexOfItem={key}
                          isBadgeFullWidth={isBadgeFullWidth}
                        />
                      </React.Fragment>
                    );
                  })}
                </Carousel>
              </div>
            ) : (
              <div className={productCardListingCarousel()}>
                <div className={contentCarouselSingle()}>
                  {props?.fields?.productCards && (
                    <React.Fragment key={0}>
                      <ProductSingleCard
                        {...props?.fields?.productCards[0]}
                        indexOfItem={0}
                        isBadgeFullWidth={isBadgeFullWidth}
                      />
                    </React.Fragment>
                  )}
                </div>
              </div>
            )}

            {disclaimerText?.value ? (
              <LegalDisclaimer
                disclaimerText={disclaimerText}
                disclaimerClasses={legalDisclaimerText()}
              />
            ) : undefined}
          </div>
        </Container>
      </div>
    </ProductCardContext.Provider>
  );
};

export default ProductCard;

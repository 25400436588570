// components/GoogleMaterialSymbol.js
import { convertHtmlToReact } from '@hedgedoc/html-to-react';
import React, { ReactElement, useEffect, useState } from 'react';

export type Variant = 'outlined' | 'rounded' | 'sharp';

export type GoogleMaterialSymbolProps = {
  className?: string;
  fill?: boolean;
  icon: string;
  variant?: Variant;
  'aria-hidden'?: boolean;
};

// Create an in-memory cache
const iconCache: Record<string, string> = {};

const GoogleMaterialSymbol = ({
  className,
  fill,
  icon,
  variant = 'sharp',
  ...props
}: GoogleMaterialSymbolProps): ReactElement => {
  const [fetchedIcon, setFetchedIcon] = useState('');

  useEffect(() => {
    if (!icon || !variant) return;

    const cacheKey = `${icon}-${variant}-${fill}`;

    // Check if the icon is already in the cache
    if (iconCache[cacheKey]) {
      setFetchedIcon(iconCache[cacheKey]);
      return;
    }

    async function fetchIcon() {
      try {
        const res = await fetch(
          `/api/googleMaterialIcon?variant=${variant}&icon=${icon}${fill ? '&fill=-fill' : ''}`
        );
        const data = await res.json();

        if (data.icon) {
          // Store the fetched icon in the cache
          iconCache[cacheKey] = data.icon;
          setFetchedIcon(data.icon);
        }
      } catch (error) {
        console.error('Error fetching icon:', error);
      }
    }

    fetchIcon();
  }, [icon, fill, variant]);

  return (
    <React.Fragment>
      <span className={className} data-component="helpers/general/googlematerialsymbol" {...props}>
        {convertHtmlToReact(fetchedIcon)}
      </span>
    </React.Fragment>
  );
};

export default GoogleMaterialSymbol;

const IngredientQuery = `query Ingredient($datasource: String!, $language: String!, $after: String!) {
  ingredient: item(path: $datasource, language: $language) {
    ... on AccordionList {
      accordions {
        targetItems {
          name
          id
          ...AccordionDetails
        }
      }
    }
  }
}
fragment Details on Item {
  title: field(name: "title") {
    value
  }
  definition: field(name: "definition") {
    value
  }
  active: field(name: "active") {
    value
  }
}
fragment AccordionDetails on Accordion_3648abf7d0fe4f8baaa65a402d88e725 {
  ingredients {
    id
    targetItems {
      id
      field(name: "title") {
        value
      }
      children(first: 800, after:$after) {
        total
        pageInfo {
          endCursor
          hasNext
        }

        results {
          ... on Allergen {
            ...Details
          }
          ... on Fragrance {
            ...Details
          }
          ... on Ingredient {
            ...Details
          }
        }
      }
    }
  }
}
`;

export default IngredientQuery;

// Global
import {
  Placeholder,
  ComponentRendering,
  ComponentParams,
} from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import { tv } from 'tailwind-variants';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';

export type DartLayoutProps = {
  rendering: ComponentRendering & { params: ComponentParams };
  params: ComponentParams;
};

type ContentOrientationType =
  | 'half'
  | 'quarter'
  | 'quarterReverse'
  | 'oneThird'
  | 'oneThirdReverse'
  | undefined;

const tailwindVariants = tv({
  compoundSlots: [
    {
      slots: ['layoutLeftWrapper', 'layoutRightWrapper'],
      class: ['flex', 'flex-col', 'gap-spacing-spacing-9', 'group', 'layoutWrapper', 'w-full'],
    },
  ],
  slots: {
    base: [
      'component',
      'layout-component',
      'flex',
      'flex-col',
      'gap-spacing-spacing-5',
      'md:flex-row',
      'p-spacing-spacing-5',
      'md:pt-spacing-spacing-10',
      'md:px-spacing-spacing-10',
      'md:pb-spacing-spacing-9',
    ],
    layoutLeftWrapper: [],
    layoutRightWrapper: [],
    fullWidthBG: ['bg-components-layout-bg-default'],
  },
  variants: {
    orientation: {
      half: {
        layoutLeftWrapper: ['md:w-2/4'],
        layoutRightWrapper: ['md:w-2/4'],
      },
      quarter: {
        layoutLeftWrapper: ['md:w-1/4'],
        layoutRightWrapper: ['md:w-3/4'],
      },
      quarterReverse: {
        layoutLeftWrapper: ['md:w-3/4'],
        layoutRightWrapper: ['md:w-1/4'],
      },
      oneThird: {
        layoutLeftWrapper: ['md:w-1/3', 'md:mr-spacing-spacing-10'],
        layoutRightWrapper: ['md:w-2/3'],
      },
      oneThirdReverse: {
        layoutLeftWrapper: ['md:w-2/3'],
        layoutRightWrapper: ['md:w-1/3'],
      },
    },
  },
});

const DartLayout = (props: DartLayoutProps): JSX.Element => {
  const { ContentOrientation } = props?.params || {};
  const orientationMapping: { [key: string]: string } = {
    '50-50': 'half',
    '25-75': 'quarter',
    '75-25': 'quarterReverse',
    '33-66': 'oneThird',
    '66-33': 'oneThirdReverse',
  };
  const orientation = orientationMapping[ContentOrientation];
  const { base, layoutLeftWrapper, layoutRightWrapper, fullWidthBG } = tailwindVariants({
    orientation: orientation as ContentOrientationType,
  });
  const id = props?.params?.RenderingIdentifier;

  const phKeyleft = `dartlayout-left`;
  const phKeyright = `dartlayout-right`;

  return (
    <div className={fullWidthBG()}>
      <Container>
        <div
          className={base()}
          data-component="layout/dartlayout"
          id={id ? id : undefined}
          tabIndex={id ? -1 : 1}
        >
          <div className={`${layoutLeftWrapper()} ${phKeyleft}`}>
            <Placeholder name={phKeyleft} rendering={props.rendering} />
          </div>
          <div className={`${layoutRightWrapper()} ${phKeyright}`}>
            <Placeholder name={phKeyright} rendering={props.rendering} />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default DartLayout;

// SEE https://w3c.github.io/web-share
// SEE https://caniuse.com/?search=navigator.share

// Global
import { RouteData, Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { tv } from 'tailwind-variants';
import { sendGTMEvent } from '@next/third-parties/google';

// Lib
import { DartPages } from 'lib/templates/Project.Dart.model';

// Local
import SVG from 'helpers/SVG/SVG';
import GoogleMaterialSymbol from 'helpers/GoogleMaterialSymbol/GoogleMaterialSymbol';
import { useRealPathName } from 'lib/utils/use-real-pathname';

type PageRoute = RouteData & DartPages.Base.DartBasePage;

interface ShareItem {
  clickedText: string;
  displayText: string;
  shareFunction: {
    shareUrl: string;
  };
  shareIcon: string;
}
interface Props {
  text?: string;
  title?: string;
  url?: string;
  shareList?: {
    heading: string;
    shareOptions: ShareItem[];
  };
  route?: RouteData;
}

type NativeAttrs = Omit<React.ButtonHTMLAttributes<undefined>, keyof Props>;

export type ShareButtonProps = Props & NativeAttrs;

const tailwindVariants = tv({
  slots: {
    base: ['print:hidden', 'relative', 'z-10'],
    closeIcon: ['cursor-pointer', 'fill-components-share-list-color-close-icon'],
    content: ['flex', 'flex-col'],
    header: ['flex', 'items-center', 'justify-between'],
    headerTitle: [
      'font-header-large-xxSmall',
      'leading-header-large-xxSmall',
      'text-components-share-list-color-title',
      'text-header-large-xxSmall',
    ],
    shareIcon: ['fill-themes-text-color-brand1', 'hover:fill-colors-brand-1-500'],
    shareListButton: [
      'cursor-pointer',
      'gap-components-share-list-spacing-link-link-space-between',
      'flex',
      'flex-wrap',
      'group',
      'items-center',
      'px-components-share-list-spacing-link-link-padding-x',
      'py-components-share-list-spacing-link-link-padding-y',
      'text-components-share-list-color-link-default',
      'rounded-components-share-list-spacing-link-link-radius',
      'hover:bg-components-share-list-color-bg-link-hover',
      'hover:text-components-share-list-color-link-hover',
      'hover:fill-components-share-list-color-link-hover',
    ],
    shareListButtonIcon: [
      'fill-components-share-list-color-basic-icon-default',
      'group-hover:fill-components-share-list-color-icon-hover',
    ],
    shareListButtonText: [
      'text-bodySans-medium-semibold',
      'font-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
    ],
    shareListButtonIconAlt: [
      'group-hover:fill-components-share-list-color-icon-hover',
      'fill-components-share-list-color-facebook-icon',
    ],
    shareListPanel: [
      'border',
      'border-components-share-list-color-border',
      'absolute',
      'bg-components-share-list-color-bg',
      'flex',
      'flex-col',
      'gap-2',
      'shadow-components-share-list',
      // 1/2 of 200 - 1/2 of share icon width (24px)
      // 100 - 12
      'left-[-88px]',
      'px-components-share-list-spacing-padding-x',
      'py-components-share-list-spacing-padding-y',
      'rounded-components-share-list-spacing-radius',
      'top-8',
      'min-w-[200px]',
    ],
  },
});

const ShareButton = (): ReactElement => {
  const context = useSitecoreContext();

  const route = context.sitecoreContext.route as PageRoute;
  const { shareList } = context.sitecoreContext as Props;
  const { heading, shareOptions } = shareList || { heading: '', shareOptions: [] };
  const isBrowser = typeof window !== 'undefined';
  const [currentUrl, setCurrentUrl] = useState('');
  const { ogImage, ogTitle, ogDescription, canonicalUrl } = route?.fields || {};

  const {
    base,
    closeIcon,
    content,
    header,
    headerTitle,
    shareIcon,
    shareListButton,
    shareListButtonIcon,
    shareListButtonText,
    shareListPanel,
  } = tailwindVariants();

  const componentRef = useRef<HTMLElement>(null);

  const [toggleShareMenu, setToggleShareMenu] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const pathname = useRealPathName();

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      componentRef.current &&
      !(componentRef.current as HTMLElement).contains(event.target as Node)
    ) {
      setToggleShareMenu(false);
    }
  };

  const handleShareClick = (share: string) => {
    // Print
    if (share === 'print') {
      window.print();
    }
    // Copy Link
    if (share === 'copy') {
      setIsClicked(!isClicked);
      navigator.clipboard
        .writeText(currentUrl as string)
        .then(() => {
          setTimeout(() => {
            setIsClicked(false);
            setToggleShareMenu(false);
          }, 3000);
        })
        .catch((err) => {
          console.error('Error copying text to clipboard', err);
        });
    }
    // Email
    if (share === 'email') {
      const mailtoLink = `mailto:?subject=${encodeURIComponent(
        ogTitle?.value as string
      )}&body=${encodeURIComponent(canonicalUrl?.value as string)}`;
      window.location.href = mailtoLink;
    }

    sendGTMEvent({
      event: 'social',
      type: 'share',
      'gtm.element.dataset.gtmLinkName': share,
      'gtm.element.dataset.gtmLinkUrl': pathname,
    });
  };

  const handleOnClick = async () => {
    if (navigator.share) {
      const payload = {
        url: canonicalUrl?.value,
        text: ogDescription?.value,
        ogImage,
        title: ogTitle?.value,
      };

      const canShare = navigator.canShare(payload);

      if (!canShare) return;

      navigator.share(payload).catch(console.error);
    } else {
      setToggleShareMenu(!toggleShareMenu);
    }
  };

  const handleTabKey = (e: KeyboardEvent) => {
    const targetNode = 'target' in e ? (e.target as Node) : null;
    if (e.key === 'Tab' && componentRef?.current && !componentRef?.current?.contains(targetNode)) {
      setToggleShareMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick, true);
    document.addEventListener('keyup', handleTabKey, true);

    return () => {
      document.removeEventListener('click', handleOutsideClick, true);
      document.removeEventListener('keyup', handleTabKey, true);
    };
  }, []);

  useEffect(() => {
    if (isBrowser) {
      setCurrentUrl(window.location.href);
    }
  }, [isBrowser, currentUrl]);

  return (
    <span className={base()} data-component="helpers/general/sharebutton" ref={componentRef}>
      <button
        aria-label="Share"
        onClick={() => handleOnClick()}
        onKeyDown={(e) => {
          if (e.key === 'Escape' && toggleShareMenu) {
            setToggleShareMenu(false);
          }
        }}
      >
        <GoogleMaterialSymbol icon="share" fill className={shareIcon()} />
      </button>
      {toggleShareMenu && (
        <div
          className={shareListPanel()}
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              setToggleShareMenu(false);
            }
          }}
        >
          <div className={header()}>
            <div>
              <Text className={headerTitle()} encode={false} field={{ value: heading }} tag="p" />
            </div>
            <a href="javascript:void(0)" onClick={() => setToggleShareMenu(!toggleShareMenu)}>
              <GoogleMaterialSymbol icon="close" className={closeIcon()} />
            </a>
          </div>
          <div className={content()}>
            {shareOptions?.map((shareListItem: ShareItem, index: number) => {
              const tailwindVariantsExtended = tv({
                extend: tailwindVariants,
                slots: {
                  shareListButtonIconAlt: [
                    `fill-components-share-list-color-${shareListItem.displayText.toLowerCase()}-icon`,
                  ],
                },
              });

              const { shareListButtonIconAlt } = tailwindVariantsExtended();

              return (
                <React.Fragment key={index}>
                  {!shareListItem.shareFunction.shareUrl.includes('http') ? (
                    <a
                      className={shareListButton()}
                      href="javascript:void(0)"
                      onClick={(e) => {
                        e.preventDefault();
                        handleShareClick(shareListItem.shareFunction.shareUrl);
                      }}
                    >
                      <SVG className={shareListButtonIcon()} url={shareListItem.shareIcon} />
                      <Text
                        className={shareListButtonText()}
                        encode={false}
                        field={{
                          value:
                            !isClicked || shareListItem.clickedText === ''
                              ? shareListItem.displayText
                              : shareListItem.clickedText,
                        }}
                        tag="span"
                      />
                    </a>
                  ) : (
                    <a
                      className={shareListButton()}
                      href={`${shareListItem.shareFunction.shareUrl}${currentUrl}`}
                      target="_blank"
                      onClick={() => {
                        sendGTMEvent({
                          event: 'social',
                          type: 'share',
                          'gtm.element.dataset.gtmLinkName': shareListItem.displayText,
                          'gtm.element.dataset.gtmLinkUrl': pathname,
                        });
                      }}
                    >
                      {/* 
                        fill-components-share-list-color-facebook-icon
                        fill-components-share-list-color-pinterest-icon
                        fill-components-share-list-color-twitter-icon
                      */}
                      <SVG className={shareListButtonIconAlt()} url={shareListItem.shareIcon} />
                      <Text
                        className={shareListButtonText()}
                        encode={false}
                        field={{ value: shareListItem.displayText }}
                        tag="span"
                      />
                    </a>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      )}
    </span>
  );
};

export default ShareButton;

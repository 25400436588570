// Global
import React from 'react';

// Lib
import { ComponentProps } from 'lib/component-props';
import { CardComponents } from 'lib/templates/Feature.Dart.model';
import { ItemEx } from 'lib/templates/_.Sitecore.Override';

// Local

import CategoryCardListingGrid from './Components/Grid';
import CategoryCardListingSideBySide from './Components/SideBySide';
import Container from 'components/authorable/Layout/DartContainer/DartContainer';

export type CategoryCard = ItemEx & CardComponents.CategoryCard.CategoryCardItem;
export type CategoryCardProps = ComponentProps & CardComponents.CategoryCard.CategoryCardsList;

// Category Card
/**
 * Category Card Grid Layout
 * 1. 2 Column
 * 2. 3 Column
 * 3. 4 Column
 * 4. 2/3 Side by Side
 *
 * Category Card Layouvrajvt
 * 1. Grid Layout
 * 2. Side By Side Layout
 *
 */

const CategoryCard = (props: CategoryCardProps): JSX.Element => {
  if (!props.fields) return <></>;

  const { alignContent } = props?.params || {};
  // Unique id for component
  const id = props?.params?.RenderingIdentifier;
  return (
    //Not used tailwind variant as we only need one class
    <div
      className="bg-components-category-card-listing-color-bg"
      data-component="authorable/cards/categorycard"
      id={id ? id : undefined}
      tabIndex={id ? -1 : 1}
    >
      <Container>
        {alignContent === 'Stacked' && <CategoryCardListingGrid {...props} />}
        {alignContent === 'SidebySide' && <CategoryCardListingSideBySide {...props} />}
      </Container>
    </div>
  );
};

export default CategoryCard;

// Global
import type {
  FacetPayloadType,
  SearchResultsInitialState,
  SearchResultsStoreState,
} from '@sitecore-search/react';
import {
  WidgetDataType,
  useSearchResults,
  useSearchResultsSelectedFilters,
  widget,
} from '@sitecore-search/react';
import {
  AccordionFacets,
  FacetItem,
  Pagination,
  Presence,
  SearchResultsAccordionFacets,
  SortSelect,
} from '@sitecore-search/ui';
import { SearchResultsStoreSelectedFacet } from '@sitecore-search/widgets';
import { Field, RichText, Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'next/navigation';
import { tv } from 'tailwind-variants';
import { useI18n } from 'next-localization';

// Lib
import { DartPages } from 'lib/templates/Project.Dart.model';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import GoogleMaterialSymbol from 'helpers/GoogleMaterialSymbol/GoogleMaterialSymbol';
import TextTokenReplacer from 'helpers/TextTokenReplacer/TextTokenReplacer';
import Button from 'helpers/Button/Button';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import { focusTrap } from 'src/stories/helpers/CustomeHelpers/FocusTrap';

export type SearchPage = DartPages.SearchPage;

type ArticleModel = {
  id: string;
  type?: string;
  description?: string;
  content_text?: string;
  source_id?: string;
  name: string;
  image_url?: string;
  url: string;
  pagetype: string;
};

type ArticlesSearchResultsProps = {
  defaultSortType?: SearchResultsStoreState['sortType'];
  defaultPage?: SearchResultsStoreState['page'];
  defaultItemsPerPage?: SearchResultsStoreState['itemsPerPage'];
  defaultKeyphrase?: SearchResultsStoreState['keyphrase'];
  resultsPerPage?: Field<number>;
  filterTitle?: Field<string>;
  noSearchResult?: Field<string>;
  searchTitle?: Field<string>;
  sortDropdownLabel?: Field<string>;
  sortLabel?: Field<string>;
};

type SearchSettings = {
  previewSource: string;
  source: string;
};

type InitialState = SearchResultsInitialState<'itemsPerPage' | 'keyphrase' | 'page' | 'sortType'>;
type WindowType = 'Desktop' | 'Mobile';

const tailwindVariants = tv({
  compoundSlots: [
    {
      slots: [
        'refineTitleWrapper',
        'refineTitleIconWrapper',
        'sortFilter',
        'sortFilterIconWrapper',
        'selectedFilterWrapper',
        'selectedFilter',
        'selectedFilterIcon',
        'accordionFacetsHeaderWrapper',
        'accordionFacetsContentItem',
        'accordionFacetsContentItemIconWrapper',
        'accordionFacetsItemLabelWrapper',
        'loaderWrapper',
      ],
      class: ['flex', 'items-center'],
    },
  ],
  slots: {
    searchComponentWrapper: [
      'search-component-wrapper',
      'bg-components-site-search-bg',
      'relative',
    ],
    searchMainSection: ['w-full', 'flex'],
    searchResultsLeftWrapper: ['bg-components-site-search-refine-bg', 'focus:outline-none'],
    searchResultsLeft: ['flex', 'flex-col'],
    refineTitleWrapper: ['justify-between'],
    refineTitle: ['refineTitle', 'text-components-site-search-refine-title'],
    refineTitleIconWrapper: [
      'justify-center',
      'cursor-pointer',
      'bg-components-button-color-filled-white-default-bg',
    ],
    refineTitleIcon: [
      'fill-components-button-color-filled-white-default-icon',
      'py-components-button-spacing-compressed-default-padding-y',
      'px-components-button-spacing-compressed-icon-only-padding-x',
    ],
    sortFilterWrapper: ['sort-option-selection-wrapper', 'relative', 'mb-spacing-spacing-5'],
    sortEyebrowText: [
      'font-bodySans-xSmall',
      'text-bodySans-xSmall',
      'text-components-supporting-text-default',
      'leading-bodySans-xSmall',
      'block',
      'mb-components-supporting-text-padding-bottom',
    ],
    sortFilter: [
      'h-[48px]',
      'justify-between',
      'py-components-dropdown-input-padding-y',
      'pl-components-dropdown-input-padding-x',
      'font-bodySans-medium',
      'leading-bodySans-medium',
      'text-components-dropdown-label-text-default',
      'border-[1px]',
      'border-color-grayscale-cool-400',
      'rounded',
      'outline-none',
      'focus:border-2',
      'focus:border-components-dropdown-border-focused',
      'rounded-themes-radius-large-form-field-input',
      'hover:border-components-dropdown-border-hover',
    ],
    sortFilterIconWrapper: ['h-[48px]', 'w-[48px]', 'justify-center'],
    sortFilterIcon: ['fill-color-grayscale-cool-400'],
    sortFilterContentWrapper: [
      '[&>*]:!absolute',
      '[&>*]:!top-[85%]',
      '[&>*]:!left-0',
      '[&>*]:!w-[95%]',
    ],
    sortFilterContent: ['bg-components-dropdown-bg', 'shadow-dropdown', 'py-spacing-spacing-2'],
    sortFilterOptionWrapper: [
      'flex',
      'px-components-dropdown-item-padding-x',
      'py-components-dropdown-item-padding-y',
      'font-bodySans-medium',
      'text-bodySans-medium',
      'text-components-dropdown-item-text-default',
      'leading-bodySans-medium',
      'cursor-pointer',
      'focus:border-2',
      'focus:border-components-dropdown-border-focused',
      'md:hover:bg-components-dropdown-item-bg-hover',
      'md:hover:outline-none',
      'md:focus:outline-none',
      'md:hover:border-none',
      '[&>*:first-child]:flex-1',
    ],
    selectionFilterWrapper: ['selection-filter-wrapper'],
    selectedFilterWrapper: [
      'selected-filter-wrapper',
      'gap-2',
      'flex-wrap',
      'mb-spacing-spacing-5',
    ],
    selectedFilter: [
      'justify-center',
      'gap-x-components-tag-spacing-space-between',
      'bg-components-tag-color-bg',
      'py-components-tag-spacing-padding-y',
      'px-components-tag-spacing-padding-x',
      'border-[1px]',
      'border-components-tag-color-stroke',
      'rounded-themes-radius-large-tag',
      'cursor-pointer',
      'hover:ease-in',
      'hover:bg-components-tag-color-bg-hover',
      '[&_span]:hover:text-components-tag-color-text-hover',
      '[&_svg]:hover:fill-components-tag-color-text-hover',
    ],
    selectedFilterText: [
      'font-bodySans-xSmall',
      'text-bodySans-xSmall',
      'text-components-tag-color-text',
      'leading-bodySans-xSmall',
    ],
    selectedFilterIcon: [
      'fill-components-tag-color-text',
      'justify-center',
      '[&_svg]:w-[12px]',
      '[&_svg]:h-[12px]',
    ],
    accordionFacetsWrapper: ['border-t-[1px]', 'border-components-site-search-facet-stroke'],
    accordionFacetTriggerClass: ['w-full'],
    accordionFacetsHeaderWrapper: [
      'justify-between',
      'py-components-site-search-facet-group-label-padding-y',
      'px-components-site-search-facet-group-label-padding-x',
    ],
    accordionFacetsHeader: [
      'font-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'text-components-site-search-facet-group-label-text',
    ],
    accordionFacetsHeaderIconWrapper: ['h-[24px]', 'w-[24px]', 'pt-0.5'],
    accordionFacetsHeaderIcon: ['fill-components-site-search-facet-group-label-text'],
    accordionFacetsContentWrapper: [
      'bg-components-site-search-facet-group-label-bg',
      'space-y-components-site-search-facet-label-space-between',
    ],
    accordionFacetsContentItem: [
      'py-components-site-search-filter-padding-y',
      'px-components-site-search-filter-padding-x',
      'bg-components-dropdown-item-bg-default',
      'rounded-components-site-search-filter-radius',
    ],
    accordionFacetsContentItemIconWrapper: [
      'w-[20px]',
      'h-[20px]',
      'mr-forms-checkbox-spacing-space-between',
      'justify-center',
      'border-[1px]',
      'border-forms-checkbox-color-stroke-default',
      'rounded-forms-checkbox-spacing-radius',
      '[&_svg]:bg-forms-checkbox-color-checkbox-selected',
      '[&_svg]:rounded-forms-checkbox-spacing-radius',
    ],
    accordionFacetsContentItemIcon: ['fill-forms-checkbox-color-checkmark-selected'],
    accordionFacetsItemLabelContainer: ['flex-1'],
    accordionFacetsItemLabelWrapper: ['justify-between'],
    accordionFacetsItemLabel: [
      'font-bodySans-medium',
      'text-bodySans-medium',
      'text-components-dropdown-item-text-default',
      'leading-bodySans-medium',
    ],
    searchResultsRightWrapper: ['w-full', 'md:w-3/4', 'md:pl-spacing-spacing-5'],
    resultMsgTextclass: [
      'font-bodySans-small',
      'text-bodySans-small',
      'text-components-site-search-results-text',
      'leading-bodySans-small',
      'md:mb-spacing-spacing-5',
      'md:font-bodySans-large',
      'md:text-bodySans-large',
      'md:leading-bodySans-large',
    ],
    resultMsgHighlightedTextclass: [
      'font-bodySans-small-semibold',
      'leading-bodySans-small-semibold',
      'md:font-bodySans-large-semibold',
      'md:leading-bodySans-large-semibold',
    ],
    loaderWrapper: [
      'h-screen',
      'opacity-85',
      'fixed',
      'top-0',
      'right-0',
      'left-0',
      'bottom-0',
      'bg-white',
      'z-50',
    ],
    searchLoaderAnimation: [
      'animate-spin',
      'block',
      'h-[80px]',
      'w-[80px]',
      'm-auto',
      'bg-components-omnibox-color-loader-icon-fill-default',
      '[clip-path:path("M73.3333_40.0038C73.3333_58.4115_58.411_73.3338_40.0033_73.3338C21.5957_73.3338_6.67334_58.4115_6.67334_40.0038C6.67334_21.5962_21.5957_6.67383_40.0033_6.67383C58.411_6.67383_73.3333_21.5962_73.3333_40.0038ZM13.3393_40.0038C13.3393_54.73_25.2772_66.6678_40.0033_66.6678C54.7295_66.6678_66.6673_54.73_66.6673_40.0038C66.6673_25.2777_54.7295_13.3398_40.0033_13.3398C25.2772_13.3398_13.3393_25.2777_13.3393_40.0038Z")]',
    ],
    backgroundOverlayClass: [
      'fixed',
      'content-[""]',
      'h-screen',
      'w-screen',
      'top-0',
      'left-0',
      'z-[999]',
      'bg-colors-grayscale-700',
      'opacity-90',
    ],
    noResultClass: ['flex', 'justify-center', 'w-full'],
    refineBtnClass: [
      'mt-spacing-spacing-5',
      'mb-spacing-spacing-7',
      'pb-3',
      'border-themes-rule-line',
      'border-b-4',
    ],
  },
  variants: {
    deviceDetect: {
      Desktop: {
        searchComponentWrapper: ['px-[80px]', 'py-[40px]'],
        searchMainSection: ['flex-row'],
        searchResultsLeftWrapper: ['w-1/4', 'pr-spacing-spacing-5'],
        refineTitleWrapper: ['mb-spacing-spacing-5'],
        refineTitle: [
          'font-header-large-medium',
          'text-header-large-medium',
          'leading-header-large-medium',
        ],
        sortFilter: ['w-[95%]'],
      },
      Mobile: {
        searchComponentWrapper: ['p-spacing-spacing-5'],
        searchMainSection: ['flex-col'],
        searchResultsLeftWrapper: [
          'fixed',
          'h-screen',
          'w-[90%]',
          'px-spacing-spacing-4',
          'top-0',
          'z-[999]',
          'duration-700',
        ],
        searchResultsLeftWrapperFocused: ['right-0'],
        searchResultsLeftWrapperWithoutFocused: ['-right-full'],
        refineTitleWrapper: ['my-spacing-spacing-3'],
        refineTitle: [
          'font-header-small-medium',
          'text-header-small-medium',
          'leading-header-small-medium',
        ],
        sortFilter: ['w-full'],
      },
    },
    isFilterVisible: {
      true: {
        searchResultsLeftWrapper: ['right-0', 'md:right-auto'],
      },
      false: {
        searchResultsLeftWrapper: ['-right-full', 'md:right-auto'],
      },
    },
    isDropdownOpen: {
      true: {
        sortFilter: ['border-components-dropdown-border-focused'],
      },
      false: {
        sortFilter: [],
      },
    },
    isAccordionOpen: {
      true: {
        accordionFacetsWrapper: ['first:border-none'],
      },
      false: {
        accordionFacetsWrapper: [],
      },
    },
    isSelectedOption: {
      true: {
        sortFilterOptionWrapper: ['bg-components-dropdown-item-bg-hover'],
      },
    },
  },
});

export const tailwindVariantResultPage = tv({
  compoundSlots: [
    {
      slots: [
        'base',
        'paginationContainer',
        'paginationRoot',
        'paginationDisplayPage',
        'paginationGroup',
        'paginationIcon',
        'paginationPage',
      ],
      class: ['flex'],
    },
  ],
  slots: {
    base: [],
    paginationContainer: [
      'flex-col',
      'border-t',
      'border-solid',
      'border-components-pagination-on-white-stroke',
      'md:gap-spacing-spacing-5',
      'md:pt-spacing-spacing-7',
      'pt-spacing-spacing-5',
    ],
    paginationRoot: ['justify-between', 'items-center'],
    paginationGroup: ['flex-col', 'items-center', 'gap-spacing-spacing-5'],
    paginationIcon: [
      'p-0',
      'min-h-[40px]',
      ['[&>button]:flex'],
      ['[&>button]:p-0'],
      ['[&>button]:min-h-min'],
      'self-start',
      'font-tbodySans-medium-semibold',
      'text-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'text-components-pagination-on-white-stroke',
    ],
    paginationPageWrapper: [
      'md:flex',
      'flex-row',
      'items-center',
      'gap-components-button-spacing-compressed-default-space-between',
      'hidden',
      '[&_span]:pb-[3px]',
      '[&_span]:text-components-pagination-on-white-text-default',
    ],
    paginationPage: [
      'py-components-button-spacing-compressed-default-padding-y',
      'px-components-button-spacing-compressed-default-padding-x',
      'rounded-themes-radius-large-button-icon',
      'font-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'text-components-pagination-on-white-text-default',
    ],
    paginationDisplayPage: [
      'flex-row',
      'min-h-1',
      'justify-center',
      'font-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
      'text-components-pagination-on-white-text-default',
      'leading-bodySans-medium-semibold',
      'md:font-bodySans-small',
      'md:text-bodySans-small',
      'md:leading-bodySans-small',
    ],
  },
  variants: {
    isActive: {
      true: {
        paginationPage: [
          'bg-components-pagination-on-white-bg-active',
          '!text-components-pagination-on-white-text-active',
        ],
      },
      false: {
        paginationPage: [],
      },
    },
  },
});

export const tailwindVariantResultCard = tv({
  slots: {
    cardComponentWrapper: ['cardComponent-wrapper', 'outline-none'],
    cardItemWrapper: [
      'flex',
      'justify-between',
      'w-full',
      'py-components-card-search-result-spacing-small-padding-y',
      'md:py-components-card-search-result-spacing-large-padding-y',
      'border-b-[1px]',
      'border-components-card-search-result-color-divider-line',
    ],
    cardContentWrapper: [
      'w-[70%]',
      'bg-components-card-search-result-color-bg',
      'md:mr-components-card-search-result-spacing-large-image-margin-right',
      'mr-components-card-search-result-spacing-small-image-margin-right',
      'md:w-4/5',
      'space-y-components-card-search-result-spacing-large-eyebrow-margin-bottom',
    ],
    cardLabel: [
      'font-bodySans-small',
      'text-bodySans-small',
      'text-components-card-search-result-color-label',
      'leading-bodySans-small',
    ],
    cardTitle: [
      'md:font-header-large-xSmall',
      'font-header-small-xSmall',
      'md:text-header-large-xSmall',
      'text-header-small-xSmall',
      'md:leading-header-large-xSmall',
      'leading-header-small-xSmall',
      // 'mb-components-card-search-result-spacing-large-eyebrow-margin-bottom',
    ],
    cardTitleTextColor: ['text-components-product-card-color-title'],
    cardDescription: [
      'font-bodySans-small',
      'text-bodySans-small',
      'leading-bodySans-small',
      'text-components-card-search-result-color-body',
      'md:font-bodySans-medium',
      'md:text-bodySans-medium',
      'md:leading-bodySans-medium',
      '!line-clamp-3',
      'md:!line-clamp-2',
      'py-[3px]',
    ],
    cardImageWrapper: ['w-[30%]', 'md:w-1/5'],
    cardImage: ['w-full', 'h-auto', 'object-cover', 'rounded-themes-radius-large-image-card'],
  },
});

const buildRangeLabel = (min: number | undefined, max: number | undefined): string => {
  return typeof min === 'undefined'
    ? `< $${max}`
    : typeof max === 'undefined'
    ? ` > $${min}`
    : `$${min} - $${max}`;
};

const buildFacetLabel = (
  selectedFacet: SearchResultsStoreSelectedFacet & { type: FacetPayloadType }
) => {
  if ('min' in selectedFacet || 'max' in selectedFacet) {
    return `${buildRangeLabel(selectedFacet.min, selectedFacet.max)}`;
  }
  return `${selectedFacet.valueLabel}`;
};

export const SearchResultsComponent = ({
  defaultSortType = 'featured_desc',
  defaultPage = 1,
  defaultKeyphrase = '',
  defaultItemsPerPage = 24,
  filterTitle,
  noSearchResult,
  resultsPerPage,
  searchTitle,
  sortDropdownLabel,
  sortLabel,
}: ArticlesSearchResultsProps) => {
  const { sitecoreContext } = useSitecoreContext();
  const searchSettings = sitecoreContext?.searchDetails as SearchSettings;
  //const searchSourceIds = searchSettings?.source?.split('|') || [];
  const searchSourceIds = process.env.IS_PREVIEW_SITE
    ? searchSettings?.previewSource?.split('|') || []
    : searchSettings?.source?.split('|') || [];
  const i18n = useI18n();
  let DisplayingCountForSearch = i18n.t('DisplayingCountForSearch');
  let DisplayingMobileCountForSearch = i18n.t('DisplayingMobileCountForSearch');
  const Next = i18n.t('Next');
  const Previous = i18n.t('Previous');
  const ClearFilters = i18n.t('ClearFilters');
  const {
    widgetRef,
    actions: {
      onPageNumberChange,
      onItemClick,
      onRemoveFilter,
      onSortChange,
      onFacetClick,
      onClearFilters,
    },
    state: { sortType, page, itemsPerPage },
    queryResult: {
      isLoading,
      isFetching,
      data: {
        total_item: totalItems = 0,
        sort: { choices: sortChoices = [] } = {},
        facet: facets = [],
        content: articles = [],
      } = {},
    },
  } = useSearchResults<ArticleModel, InitialState>({
    query: (query) => {
      query
        .getRequest()
        .setSources(searchSourceIds)
        .setSearchFacetAll(false)
        .setSearchFacetTypes([
          {
            name: 'pagetype',
          },
        ])
        .getSearchFacet();
    },
    state: {
      sortType: defaultSortType,
      page: defaultPage,
      itemsPerPage: (resultsPerPage?.value as number) || defaultItemsPerPage,
      keyphrase: defaultKeyphrase,
    },
  });

  const [windowSize, setWindowSize] = useState<number>();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWindowSize(window.innerWidth);
    }
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowSize]);

  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const selectedSortIndex = sortChoices.findIndex((s) => s.name === sortType);
  const selectedOption = sortChoices.find((s) => s.name === sortType);
  const selectedFacetsFromApi = useSearchResultsSelectedFilters();

  const deviceName = windowSize && windowSize > 990 ? 'Desktop' : 'Mobile';

  const searchParams = useSearchParams();
  const searchQuery = searchParams?.get('q') || '';

  const {
    searchComponentWrapper,
    searchMainSection,
    searchResultsLeftWrapper,
    searchResultsLeft,
    refineTitleWrapper,
    refineTitle,
    refineTitleIconWrapper,
    refineTitleIcon,
    sortFilterWrapper,
    sortEyebrowText,
    sortFilter,
    sortFilterIconWrapper,
    sortFilterIcon,
    sortFilterContentWrapper,
    sortFilterContent,
    sortFilterOptionWrapper,
    selectionFilterWrapper,
    selectedFilterWrapper,
    selectedFilter,
    selectedFilterText,
    selectedFilterIcon,
    accordionFacetsWrapper,
    accordionFacetTriggerClass,
    accordionFacetsHeaderWrapper,
    accordionFacetsHeader,
    accordionFacetsHeaderIconWrapper,
    accordionFacetsHeaderIcon,
    accordionFacetsContentWrapper,
    accordionFacetsContentItem,
    accordionFacetsContentItemIconWrapper,
    accordionFacetsContentItemIcon,
    accordionFacetsItemLabelContainer,
    accordionFacetsItemLabelWrapper,
    accordionFacetsItemLabel,
    searchResultsRightWrapper,
    resultMsgTextclass,
    resultMsgHighlightedTextclass,
    loaderWrapper,
    searchLoaderAnimation,
    backgroundOverlayClass,
    noResultClass,
    refineBtnClass,
  } = tailwindVariants({
    deviceDetect: deviceName as WindowType,
  });

  const {
    paginationContainer,
    paginationGroup,
    paginationIcon,
    paginationRoot,
    paginationPage,
    paginationPageWrapper,
    paginationDisplayPage,
  } = tailwindVariantResultPage();

  const {
    cardComponentWrapper,
    cardItemWrapper,
    cardContentWrapper,
    cardLabel,
    cardTitle,
    cardTitleTextColor,
    cardDescription,
    cardImageWrapper,
    cardImage,
  } = tailwindVariantResultCard();
  const refLeftMobile = useRef<HTMLDivElement>(null);

  const toggleFilterMenu = useCallback(() => {
    setIsFilterVisible(!isFilterVisible);
  }, [isFilterVisible]);

  useEffect(() => {
    if (isFilterVisible) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [isFilterVisible]);

  useEffect(() => {
    const previouslyFocused = document?.activeElement;
    let focusTrapFunction: () => void;
    if (isFilterVisible && refLeftMobile?.current) {
      focusTrapFunction = focusTrap(refLeftMobile.current, toggleFilterMenu);
    }

    return () => {
      if (focusTrapFunction) {
        focusTrapFunction();
      }

      previouslyFocused && (previouslyFocused as HTMLElement).focus();
    };
  }, [isFilterVisible, toggleFilterMenu]);

  const paginationOf = `${itemsPerPage * (page - 1) + 1} - ${
    itemsPerPage * (page - 1) + articles.length
  }`;
  const totalProductPages = totalItems as unknown as string;

  DisplayingCountForSearch = DisplayingCountForSearch.replace('{0}', paginationOf).replace(
    '{1}',
    totalProductPages
  );
  DisplayingMobileCountForSearch = DisplayingMobileCountForSearch.replace('{0}', `${page}`).replace(
    '{1}',
    `${totalPages}`
  );

  const [isPreviousButtonDisabled, setPreviousButtonDisabled] = useState<boolean>(false);
  const [isNextButtonDisabled, setNextButtonDisabled] = useState<boolean>(false);

  useEffect(() => {
    const initPreviousButton = (): void => {
      if (page === 1) {
        return setPreviousButtonDisabled(true);
      } else if (page !== 1 && totalPages > 1) {
        return setPreviousButtonDisabled(false);
      } else {
        return setPreviousButtonDisabled(true);
      }
    };

    const initNextButton = (): void => {
      if ((page === 1 && totalPages === 1) || (page !== 1 && page === totalPages)) {
        return setNextButtonDisabled(true);
      } else if ((page === 1 && totalPages > 1) || (page > 1 && page !== totalPages)) {
        return setNextButtonDisabled(false);
      } else {
        return setNextButtonDisabled(true);
      }
    };
    initPreviousButton();
    initNextButton();
  }, [page, totalPages]);

  const refSearchResults = useRef<HTMLDivElement>(null);
  const firstCardFocus = useRef<HTMLDivElement>(null);
  const header = typeof window !== 'undefined' ? window.document.getElementById('header') : null;

  const scrollToSearchResults = (page: number) => {
    onPageNumberChange({ page });
    const { current } = refSearchResults;

    if (firstCardFocus.current) {
      firstCardFocus.current.tabIndex = 0; // Set tabIndex to make it focusable
      firstCardFocus.current.focus();
    }
    if (current !== null) {
      setTimeout(() => {
        const currentScrollPosition = window.scrollY || window.pageYOffset;
        const elementTop = current.getBoundingClientRect().top;
        if (header) {
          const newPosition = currentScrollPosition + elementTop - header?.offsetHeight;
          window.scrollTo({ top: newPosition, behavior: 'smooth' });
        }
      }, 300);
    }
  };

  return (
    <Container fullWidth>
      <div className={searchComponentWrapper()} ref={refSearchResults}>
        {isLoading && (
          <div className={loaderWrapper()}>
            <Presence present={isLoading} key={defaultKeyphrase}>
              <div className={searchLoaderAnimation()} />
            </Presence>
          </div>
        )}
        {!isLoading && (
          <div ref={widgetRef}>
            {isFetching && (
              <div className={loaderWrapper()}>
                <Presence present={true}>
                  <div className={searchLoaderAnimation()} />
                </Presence>
              </div>
            )}
            {totalItems > 0 && (
              <div className={searchMainSection()}>
                {!searchQuery.length && <div>{noSearchResult?.value}</div>}
                {searchQuery.length > 0 && totalItems > 0 && (
                  <>
                    {isFilterVisible && windowSize && windowSize <= 990 && (
                      <span className={backgroundOverlayClass()} onClick={toggleFilterMenu}></span>
                    )}
                    <section
                      className={searchResultsLeftWrapper({ isFilterVisible: isFilterVisible })}
                      ref={refLeftMobile}
                    >
                      <div className={searchResultsLeft()}>
                        <div className={refineTitleWrapper()}>
                          {filterTitle?.value && (
                            <Text field={filterTitle} tag="p" className={refineTitle()} />
                          )}
                          {isFilterVisible && windowSize && windowSize <= 990 && (
                            <button className={refineTitleIconWrapper()} onClick={toggleFilterMenu}>
                              <GoogleMaterialSymbol
                                className={refineTitleIcon()}
                                icon={'close'}
                                variant="outlined"
                              />
                            </button>
                          )}
                        </div>
                        <div className={sortFilterWrapper()}>
                          <SortSelect.Root
                            onValueChange={onSortChange}
                            onOpenChange={() => {
                              setIsDropdownOpen(!isDropdownOpen);
                            }}
                          >
                            {sortLabel?.value && (
                              <Text field={sortLabel} tag="span" className={sortEyebrowText()} />
                            )}
                            <SortSelect.Trigger
                              className={sortFilter({ isDropdownOpen: isDropdownOpen })}
                              tabIndex={
                                (isFilterVisible && windowSize && windowSize <= 990) ||
                                (windowSize && windowSize > 990)
                                  ? 0
                                  : -1
                              }
                            >
                              <SortSelect.SelectValue placeholder={sortDropdownLabel?.value}>
                                {selectedSortIndex > -1 ? sortChoices[selectedSortIndex].label : ''}
                              </SortSelect.SelectValue>
                              <div className={sortFilterIconWrapper()}>
                                <GoogleMaterialSymbol
                                  className={sortFilterIcon()}
                                  icon={isDropdownOpen ? 'arrow_drop_up' : 'arrow_drop_down'}
                                  variant="outlined"
                                />
                              </div>
                            </SortSelect.Trigger>
                            <div className={sortFilterContentWrapper()}>
                              <SortSelect.Content className={sortFilterContent()}>
                                <SortSelect.Viewport>
                                  {sortChoices?.map((option) => (
                                    <SortSelect.Option
                                      value={option}
                                      key={option?.name}
                                      className={sortFilterOptionWrapper({
                                        isSelectedOption: selectedOption?.name === option?.name,
                                      })}
                                    >
                                      <SortSelect.OptionText>{option?.label}</SortSelect.OptionText>
                                      {selectedOption?.name === option?.name && (
                                        <GoogleMaterialSymbol icon="done" />
                                      )}
                                    </SortSelect.Option>
                                  ))}
                                </SortSelect.Viewport>
                              </SortSelect.Content>
                            </div>
                          </SortSelect.Root>
                        </div>
                        <div className={selectionFilterWrapper()}>
                          {!!selectedFacetsFromApi.length && (
                            <div className={selectedFilterWrapper()}>
                              {selectedFacetsFromApi.map((selectedFacet, index) => (
                                <div
                                  key={`${selectedFacet.facetId}${selectedFacet.facetLabel}${selectedFacet.valueLabel}`}
                                  className={selectedFilter()}
                                  onClick={() => onRemoveFilter(selectedFacet)}
                                  onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                      onRemoveFilter(selectedFacet);
                                    }
                                  }}
                                  tabIndex={0}
                                  data-index={index}
                                >
                                  <span className={selectedFilterText()}>
                                    {buildFacetLabel(selectedFacet)}
                                  </span>
                                  <GoogleMaterialSymbol
                                    className={selectedFilterIcon()}
                                    icon={'close'}
                                    variant="outlined"
                                  />
                                </div>
                              ))}
                              {selectedFacetsFromApi.length > 0 && (
                                <div
                                  onClick={onClearFilters}
                                  className={selectedFilter()}
                                  onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                      onClearFilters();
                                    }
                                  }}
                                  tabIndex={0}
                                >
                                  <span className={selectedFilterText()}>{ClearFilters}</span>
                                  <GoogleMaterialSymbol
                                    className={selectedFilterIcon()}
                                    icon={'close'}
                                    variant="outlined"
                                  />
                                </div>
                              )}
                            </div>
                          )}
                          <SearchResultsAccordionFacets
                            defaultFacetTypesExpandedList={[]}
                            onFacetTypesExpandedListChange={() =>
                              setIsAccordionOpen(!isAccordionOpen)
                            }
                            onFacetValueClick={onFacetClick}
                          >
                            {facets.map((f) => (
                              <AccordionFacets.Facet
                                facetId={f.name}
                                key={f.name}
                                className={accordionFacetsWrapper({
                                  isAccordionOpen: isAccordionOpen,
                                })}
                              >
                                <AccordionFacets.Trigger
                                  className={accordionFacetTriggerClass()}
                                  tabIndex={
                                    (isFilterVisible && windowSize && windowSize <= 990) ||
                                    (windowSize && windowSize > 990)
                                      ? 0
                                      : -1
                                  }
                                >
                                  <div className={accordionFacetsHeaderWrapper()}>
                                    <AccordionFacets.Header className={accordionFacetsHeader()}>
                                      {f.label}
                                    </AccordionFacets.Header>
                                    <div className={accordionFacetsHeaderIconWrapper()}>
                                      <GoogleMaterialSymbol
                                        className={accordionFacetsHeaderIcon()}
                                        icon={isAccordionOpen ? 'expand_less' : 'expand_more'}
                                        variant="outlined"
                                      />
                                    </div>
                                  </div>
                                </AccordionFacets.Trigger>
                                <AccordionFacets.Content>
                                  <AccordionFacets.ValueList
                                    className={accordionFacetsContentWrapper()}
                                  >
                                    {f.value.map((v, index) => (
                                      <FacetItem
                                        {...{
                                          index,
                                          facetValueId: v.id,
                                        }}
                                        key={v.id}
                                        className={accordionFacetsContentItem()}
                                      >
                                        <AccordionFacets.ItemCheckbox
                                          className={accordionFacetsContentItemIconWrapper()}
                                          key={`${v.id}-facet-item-checkbox`}
                                          tabIndex={0}
                                        >
                                          <AccordionFacets.ItemCheckboxIndicator
                                            key={`${v.id}-facet-item-checkbox-indicator`}
                                          >
                                            <GoogleMaterialSymbol
                                              className={accordionFacetsContentItemIcon()}
                                              icon={'check_small'}
                                              variant="rounded"
                                            />
                                          </AccordionFacets.ItemCheckboxIndicator>
                                        </AccordionFacets.ItemCheckbox>
                                        <AccordionFacets.ItemLabel
                                          className={accordionFacetsItemLabelContainer()}
                                          key={`${v.id}-facet-item-label`}
                                        >
                                          <div className={accordionFacetsItemLabelWrapper()}>
                                            <span className={accordionFacetsItemLabel()}>
                                              {' '}
                                              {i18n.t(v.text) == '' ? v.text : i18n.t(v.text)}
                                            </span>
                                            <span className={accordionFacetsItemLabel()}>
                                              {' '}
                                              {v.count && `(${v.count})`}
                                            </span>
                                          </div>
                                        </AccordionFacets.ItemLabel>
                                      </FacetItem>
                                    ))}
                                  </AccordionFacets.ValueList>
                                </AccordionFacets.Content>
                              </AccordionFacets.Facet>
                            ))}
                          </SearchResultsAccordionFacets>
                        </div>
                      </div>
                    </section>
                    <section className={searchResultsRightWrapper()}>
                      {totalItems && (
                        <div className={resultMsgTextclass()}>
                          <TextTokenReplacer
                            field={searchTitle}
                            tokens={['{SearchKey}', '{Count}']}
                            tokenValues={[searchQuery, totalItems.toString()]}
                            tokenValuesClass={resultMsgHighlightedTextclass()}
                            isSpanQuoteContain={false}
                            indexValueOfQuoteContainSpan={1}
                          />
                        </div>
                      )}
                      {windowSize && windowSize <= 990 && (
                        <div className={refineBtnClass()}>
                          <Button
                            fullWidth
                            type="outline"
                            label={filterTitle?.value}
                            onClick={toggleFilterMenu}
                          />
                        </div>
                      )}
                      <div className={cardComponentWrapper()} ref={firstCardFocus}>
                        {articles?.map((a, index) => (
                          <div key={a.id} className={cardItemWrapper()}>
                            <div className={cardContentWrapper()}>
                              {a?.pagetype && (
                                <div>
                                  <Text
                                    encode={false}
                                    tag="span"
                                    field={{
                                      value:
                                        i18n.t(a.pagetype) == '' ? a.pagetype : i18n.t(a.pagetype),
                                    }}
                                    className={cardLabel()}
                                  />
                                </div>
                              )}
                              {a?.name && (
                                <h2 className={cardTitle()}>
                                  <LinkWrapper
                                    field={{ value: { href: a.url } }}
                                    onClick={() => {
                                      onItemClick({
                                        id: a.id,
                                        index,
                                        sourceId: a.source_id,
                                      });
                                    }}
                                    className={cardTitleTextColor()}
                                  >
                                    <Text encode={false} field={{ value: a.name }} />
                                  </LinkWrapper>
                                </h2>
                              )}
                              {a?.description && (
                                <RichText
                                  field={{ value: a?.description }}
                                  className={cardDescription()}
                                />
                              )}
                            </div>
                            {a?.image_url && (
                              <div className={cardImageWrapper()}>
                                <img src={a.image_url} className={cardImage()} alt="" />
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                      <div className={paginationContainer()}>
                        <Pagination.Root
                          currentPage={page}
                          defaultCurrentPage={1}
                          totalPages={totalPages}
                          onPageChange={(pagination) => {
                            scrollToSearchResults(pagination);
                          }}
                          className={paginationRoot()}
                        >
                          <Pagination.PrevPage
                            onClick={(e) => {
                              e.preventDefault();
                              scrollToSearchResults(page - 1);
                            }}
                            className={paginationIcon()}
                            tabIndex={-1}
                          >
                            <Button
                              type="text"
                              label={windowSize && windowSize > 990 ? Previous : ''}
                              iconLeft="chevron_left"
                              disabled={isPreviousButtonDisabled}
                              tabIndex={isPreviousButtonDisabled ? -1 : 0}
                            />
                          </Pagination.PrevPage>
                          <div className={paginationGroup()}>
                            <Pagination.Pages className={paginationPageWrapper()}>
                              {(pagination) =>
                                Pagination.paginationLayout(pagination, {
                                  boundaryCount: 1,
                                  siblingCount: 1,
                                }).map(({ page, type }) =>
                                  type === 'page' ? (
                                    <Pagination.Page
                                      key={page}
                                      aria-label={`Page ${page}`}
                                      page={page as number}
                                      onClick={(e) => e.preventDefault()}
                                      className={paginationPage({
                                        isActive: page == pagination.currentPage,
                                      })}
                                    >
                                      {page}
                                    </Pagination.Page>
                                  ) : (
                                    <span key={type}>...</span>
                                  )
                                )
                              }
                            </Pagination.Pages>
                            {totalItems && (
                              <div className={paginationDisplayPage()}>
                                {windowSize && windowSize > 990
                                  ? DisplayingCountForSearch
                                  : DisplayingMobileCountForSearch}
                              </div>
                            )}
                          </div>
                          <Pagination.NextPage
                            onClick={(e) => {
                              e.preventDefault();
                              scrollToSearchResults(page + 1);
                            }}
                            className={paginationIcon()}
                            tabIndex={-1}
                          >
                            <Button
                              type="text"
                              label={windowSize && windowSize > 990 ? Next : ''}
                              iconRight="chevron_right"
                              disabled={isNextButtonDisabled}
                              tabIndex={isNextButtonDisabled ? -1 : 0}
                            />
                          </Pagination.NextPage>
                        </Pagination.Root>
                      </div>
                    </section>
                  </>
                )}
              </div>
            )}
            {totalItems <= 0 && !isFetching && (
              <div className={noResultClass()}>
                <h3 className={resultMsgTextclass()}>
                  <TextTokenReplacer
                    field={searchTitle}
                    tokens={['{SearchKey}', '{Count}']}
                    tokenValues={[searchQuery, totalItems.toString()]}
                    tokenValuesClass={resultMsgHighlightedTextclass()}
                    isSpanQuoteContain={true}
                    indexValueOfQuoteContainSpan={1}
                  />
                </h3>
              </div>
            )}
          </div>
        )}
      </div>
    </Container>
  );
};
const SearchResultsWidget = widget(
  SearchResultsComponent,
  WidgetDataType.SEARCH_RESULTS,
  'content'
);
export default SearchResultsWidget;

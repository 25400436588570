// Global
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import { tv } from 'tailwind-variants';

// Lib
import { ComponentProps } from 'lib/component-props';
import { CompositeComponents } from 'lib/templates/Feature.Dart.model';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import MultiColorBorder from 'helpers/MultiColorBorder/MultiColorBorder';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import { ComponentVariants } from 'lib/context/ComponentVariants';

export type StatsListProps = ComponentProps & CompositeComponents.Stats.StatsList;
export type StatsList = CompositeComponents.Stats.Stats;

type InvertStyling = true | false | undefined;
type Theme = 'Dark' | 'Light' | undefined;

//Tailwind
const tailwindVariants = tv({
  slots: {
    base: ['flex'],
    statsInnerWrapper: [
      'md:py-components-stats-spacing-large-inner-padding-y',
      'md:px-components-stats-spacing-large-inner-padding-x',
      'py-components-stats-spacing-small-inner-padding-y',
      'px-components-stats-spacing-small-inner-padding-x',
      'bg-components-icon-card-listing-color-inner-bg',
      'rounded-themes-radius-large-general',
      'flex',
      'flex-col',
      'items-center',
      'relative',
      'z-10',
    ],
    statsTitle: [
      'md:font-header-large-large',
      'md:leading-header-large-large',
      'md:text-header-large-large',
      'font-header-small-large',
      'leading-header-small-large',
      'text-header-small-large',
      'text-components-stats-title',
      'text-center',
    ],
    statsDescription: [
      'md:text-bodySans-medium',
      'md:font-bodySans-medium',
      'md:leading-bodySans-medium',
      'text-bodySans-small',
      'font-bodySans-small',
      'leading-bodySans-small',
      'text-center',
      'text-components-stats-copy',
    ],
    statsTopWrapper: [
      'mt-spacing-spacing-2',
      'md:mt-0',
      'md:gap-components-top-title-spacing-large-title-margin-bottom',
      'gap-components-top-title-spacing-small-title-margin-bottom',
      'flex',
      'flex-col',
      'items-center',
    ],
    statsBottomWrapper: [
      'w-full',
      'mt-components-top-title-spacing-small-margin-bottom',
      'md:mt-components-top-title-spacing-large-margin-bottom',
    ],
    stateItem: [
      'flex',
      'items-center',
      'flex-1',
      'gap-components-stats-spacing-stat-text-space-between',
      'justify-between',
    ],
    stateWrapper: [
      'stats-wrapper',
      'flex',
      'md:flex-row',
      'flex-wrap',
      'gap-components-stats-spacing-large-container-space-between',
      'text-center',
      'justify-center',
      'flex-col',
      'items-start',
    ],
    stateCallOut: [
      'text-header-medium-xxSmall',
      'font-header-medium-xxSmall',
      'leading-header-medium-xxSmall',
      'p-spacing-spacing-2',
      'text-components-stats-supporting-text',
    ],
    stateLabel: [
      'md:text-header-large-xLarge',
      'md:font-header-large-xLarge',
      'md:leading-header-large-xLarge',
      'text-header-large-large',
      'font-header-large-large',
      'leading-header-large-large',
      'p-spacing-spacing-2',
      'text-components-stats-stat-text',
    ],
  },
  variants: {
    theme: {
      Dark: {
        base: [
          'bg-components-stats-bg-section-inner',
          'md:py-components-stats-spacing-large-padding-y',
          'md:px-components-stats-spacing-large-padding-x',
          'py-components-stats-spacing-small-padding-y',
          'px-components-stats-spacing-small-padding-x',
          'overflow-hidden',
        ],
      },
      Light: {
        base: ['bg-components-stats-bg-default'],
      },
    },
    invertStyling: {
      true: {
        stateItem: ['flex-col-reverse'],
        stateLabel: ['block'],
      },
      false: {
        stateCallOut: ['block'],
        stateItem: ['flex-col'],
      },
    },
  },
});

const Stats = (props: StatsListProps): JSX.Element => {
  const { title, description, enablePattern, stats, invertStyling, backgroundImage } =
    props?.fields || {};
  const componentVariants = ComponentVariants();
  const { theme } = props?.params || {};

  const {
    base,
    statsInnerWrapper,
    statsTitle,
    statsDescription,
    statsTopWrapper,
    statsBottomWrapper,
    stateItem,
    stateWrapper,
    stateCallOut,
    stateLabel,
  } = tailwindVariants({
    theme: theme as Theme,
    invertStyling: invertStyling?.value as InvertStyling,
  });
  // Unique id for component
  const id = props?.params?.RenderingIdentifier;
  return (
    <div data-component="authorable/stats">
      {/* Render top part of the border */}
      {enablePattern?.value && componentVariants?.multipleBar?.top && (
        <MultiColorBorder
          multipleBar="top"
          skewXdeg={componentVariants?.multipleBar?.skewX?.value}
        />
      )}
      <div className={`${base()} relative`} id={id ? id : undefined} tabIndex={id ? -1 : 1}>
        <Container>
          {backgroundImage && (
            <ImageWrapper
              className="absolute left-0 top-0 hidden md:block"
              layout="responsive"
              priority
              field={backgroundImage}
            />
          )}
          <div className={statsInnerWrapper()}>
            {(title?.value || description?.value) && (
              <div className={statsTopWrapper()}>
                {title?.value && (
                  <div className={statsTitle()}>
                    <Text encode={false} field={title} tag="h2" />
                  </div>
                )}
                {description?.value && (
                  <div className={statsDescription()}>
                    <RichTextA11yWrapper field={description} />
                  </div>
                )}
              </div>
            )}
            {stats && stats?.length > 0 && (
              <div className={statsBottomWrapper()}>
                <div className={stateWrapper()}>
                  {stats?.map((state: StatsList, index) => (
                    <div key={index} className={stateItem()}>
                      {state?.fields?.statCallout?.value && (
                        <div className={stateCallOut()}>
                          <Text encode={false} field={state?.fields?.statCallout} tag="p" />
                        </div>
                      )}
                      <div className={stateLabel()}>
                        <Text encode={false} field={state?.fields?.statLabel} tag="span" />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </Container>
      </div>

      {/* Render bottom part of the border */}
      {enablePattern?.value && componentVariants?.multipleBar?.bottom && (
        <MultiColorBorder
          multipleBar="bottom"
          skewXdeg={componentVariants?.multipleBar?.skewX?.value}
        />
      )}
    </div>
  );
};

export default Stats;

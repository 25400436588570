// Global
import React from 'react';
import { ImageField, LinkField, Text, TextField } from '@sitecore-jss/sitecore-jss-nextjs';
import { tv } from 'tailwind-variants';

// Lib
import { ComponentProps } from 'lib/component-props';
import { DartComponents } from 'lib/templates/Feature.Dart.model';
import FlocklerEmbed from '@flockler/react-flockler-embed';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import SVG from 'helpers/SVG/SVG';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';

export type FlocklerProps = ComponentProps & DartComponents.Flockler.Flockler;

type BackgroundColor = 'BrandColor' | undefined;

// Tailwind variables
const tailwindVariants = tv({
  slots: {
    componentBG: [],
    base: [
      'md:px-components-media-gallery-spacing-large-padding-x',
      'md:py-components-media-gallery-spacing-large-padding-y',
      'px-components-media-gallery-spacing-small-padding-x',
      'py-components-media-gallery-spacing-small-padding-y',
      'flex',
      'flex-col',
      'gap-2',
      'items-start',
      'justify-start',
      'relative',
      'overflow-hidden',
    ],
    contentWrapper: [
      'flex',
      'flex-col',
      'gap-components-top-title-spacing-large-title-margin-bottom',
      'items-start',
      'justify-start',
      'self-stretch',
      'relative',
    ],
    iconContainer: ['flex', 'flex-row', 'gap-spacing-spacing-5'],
    socialIcon: [
      'fill-components-button-color-filled-brand-default-bg',
      'active:fill-components-footer-brand-color-social-icon-pressed',
      'duration-300',
      'hover:!fill-components-share-list-color-icon-hover',
      '[&_svg]:!h-[32px]',
      '[&_svg]:!w-[32px]',
    ],
    headlineText: [
      'font-header-small-large',
      'leading-header-small-large',
      'md:text-header-large-small',
      'sm:font-header-large-large',
      'sm:font-header-large-large',
      'sm:leading-header-large-large',
      'text-header-large-xSmall',
    ],
    headlineAlignment: [
      'flex',
      'md:flex-row',
      'flex-col',
      'justify-between',
      'gap-spacing-spacing-6',
      'items-center',
      'md:px-spacing-padding-large-1',
      'sm:px-spacing-padding-large-7',
      'px-spacing-padding-large-1',
      'self-stretch',
    ],
  },
});

const Flockler = (props: FlocklerProps): JSX.Element => {
  const { backgroundColor = 'Default' } = props?.params || {};

  const { siteUuid, embedUuid, socialLinks } = props?.fields || {};

  const {
    base,
    contentWrapper,
    headlineText,
    headlineAlignment,
    iconContainer,
    socialIcon,
    componentBG,
  } = tailwindVariants({
    background: backgroundColor as BackgroundColor,
  });

  const id = props?.params?.RenderingIdentifier;
  const HeadlineText = props?.fields?.headline as TextField;
  const SiteUuidValue = siteUuid?.value as string;
  const EmbedUuidValue = embedUuid?.value as string;

  return (
    <div
      className={componentBG()}
      data-component="authorable/flockler"
      id={id ? id : undefined}
      tabIndex={id ? -1 : 1}
    >
      <Container>
        <div className={base()}>
          <div className={contentWrapper()}>
            {props?.fields ? (
              <>
                <div className={headlineAlignment()}>
                  {/* Headline */}
                  {HeadlineText && (
                    <Text className={headlineText()} encode={false} field={HeadlineText} tag="h2" />
                  )}

                  {/* Social links */}
                  <div className={iconContainer()}>
                    {socialLinks?.length !== 0 &&
                      socialLinks?.map((socialLink, index) => {
                        const { socialIcon: socialIconImage, socialUrl } = socialLink?.fields || {};
                        const url = socialIconImage as ImageField;

                        return (
                          <>
                            {socialUrl && (
                              <LinkWrapper
                                className={socialIcon()}
                                field={socialUrl as LinkField}
                                key={index}
                                showLinkTextWithChildrenPresent={false}
                              >
                                <SVG url={url?.value?.src} />
                              </LinkWrapper>
                            )}
                          </>
                        );
                      })}
                  </div>
                </div>

                {/* Flockler */}
                {SiteUuidValue && EmbedUuidValue && (
                  <FlocklerEmbed
                    style={{ minHeight: '120vh' }}
                    siteUuid={SiteUuidValue}
                    embedUuid={EmbedUuidValue}
                  />
                )}
              </>
            ) : null}
            {!props?.fields ? (
              <>
                <Text
                  className={headlineText()}
                  encode={false}
                  field={props?.fields?.headline as TextField}
                  tag="h2"
                />
              </>
            ) : null}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Flockler;

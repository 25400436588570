import { GraphQLRequestClient } from '@sitecore-jss/sitecore-jss-nextjs/graphql';

/**
 * Data needed to paginate results
 */
export interface PageInfo {
  /**
   * string token that can be used to fetch the next page of results
   */
  endCursor: string;
  /**
   * a value that indicates whether more pages of results are available
   */
  hasNext: boolean;
}

/**
 * Schema of data returned in response to a "ingredient" query request
 * @template T The type of objects being requested.
 */
export type IngredientQueryResult<T> = {
  ingredient: {
    name: string;
    id: string;
    accordions: {
      targetItems: {
        name: string;
        id: string;
        ingredients: {
          id: string;
          targetItems: {
            id: string;
            children: {
              total: string;
              pageInfo: PageInfo;
              results: T[];
            };
          }[];
        };
      }[];
    };
  };
};

/**
 * Describes the variables used by the 'ingredient' query. Language should always be specified.
 * The other predicates are optional.
 */
export interface IngredientQueryVariables {
  /**
   * The datasource of the Glossary Tab datasource item. Fetch ingredients based on this item.
   */
  datasource?: string;

  /**
   * Language of the item.
   */
  language?: string;

  /** common variable for all GraphQL queries
   * it will be used for every type of query to regulate result batch size
   * Optional. How many result items to fetch in each GraphQL call. This is needed for pagination.
   * @default 10
   */

  /**
   * After varaible to fetch all the ingredients.
   */
  after?: string;
}

/**
 * Configuration options for service classes that extend @see IngredientQueryService.
 * This extends @see ingredientQueryVariables because properties that can be passed to the ingredient query
 * as predicates should be configurable. 'language' is excluded because, normally, all properties
 * except 'language' are consistent across languages so they are passed to constructors, and
 * 'language' can vary so it is passed to methods.
 *
/**
 * Provides functionality for performing GraphQL 'ingredient' operations, including handling pagination.
 * This class is meant to be extended or used as a mixin; it's not meant to be used directly.
 * @template T The type of objects being requested.
 * @mixin
 */
export class IngredientQueryService<T> {
  /**
   * Creates an instance of ingredient query service.
   * @param {GraphQLClient} client that fetches data from a GraphQL endpoint.
   */
  constructor(protected client: GraphQLRequestClient) {}

  /**
   * 1. Validates mandatory ingredient query arguments
   * 2. Executes ingredient query with pagination
   * 3. Aggregates pagination results into a single result-set.
   * @template T The type of objects being requested.
   * @param {string | DocumentNode} query the ingredient query.
   * @param {ingredientQueryVariables} args ingredient query arguments.
   * @returns {T[]} array of result objects.
   * @throws {RangeError} if a valid root item ID is not provided.
   * @throws {RangeError} if the provided language(s) is(are) not valid.
   */
  async fetch(query: string, args: IngredientQueryVariables): Promise<T[]> {
    if (!args.datasource) {
      throw new RangeError('"datasource" must be non-empty strings');
    }

    const accordionData: T[] = [];

    const fetchAccordionData = async (accordionId: string, ingredientId: string) => {
      let results: T[] = [];
      let hasNext = true;
      let after = '';

      while (hasNext) {
        // API call for getting all the ingreients for a particular accordion
        const fetchResponse = await this.client.request<IngredientQueryResult<T>>(query, {
          ...args,
          after,
        });

        const currentIngredient = fetchResponse?.ingredient?.accordions?.targetItems
          ?.find((aId) => aId.id === accordionId)
          ?.ingredients?.targetItems?.find((iId) => iId.id === ingredientId);

        results = currentIngredient?.children?.results
          ? results.concat(currentIngredient.children.results as T)
          : [];

        // Test Log for Ingredients
        console.log('Ingredient Items: ', currentIngredient?.children?.total);

        hasNext = currentIngredient?.children?.pageInfo?.hasNext
          ? currentIngredient.children.pageInfo.hasNext
          : false;
        after = currentIngredient?.children?.pageInfo?.endCursor
          ? currentIngredient.children.pageInfo.endCursor
          : '';
      }

      return results.length > 0 ? results : [];
    };

    // Initial API call to fetch all tabs and accordions
    const initialFetchResponse = await this.client.request<IngredientQueryResult<T>>(query, {
      ...args,
      after: '',
    });

    const accordions = initialFetchResponse?.ingredient?.accordions?.targetItems;
    if (accordions && Array.isArray(accordions)) {
      for (const accordion of accordions) {
        let ingredients: T[] = [];

        for (const ingredient of accordion?.ingredients?.targetItems) {
          const ingredientResult = await fetchAccordionData(accordion.id, ingredient.id);
          if (ingredientResult && ingredientResult.length > 0) {
            ingredients = ingredients.concat(ingredientResult);
          }
        }

        // Store accordion data along with its ingredients
        accordionData.push({
          ...accordion,
          ingredients: ingredients,
        } as T);
      }
    }

    return accordionData;
  }
}

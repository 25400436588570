// Lib
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { tv } from 'tailwind-variants';

// Lib
import { ComponentProps } from 'lib/component-props';
import { ALL_THEMES, useTheme } from 'lib/context/ThemeContext';
import { CardComponents } from 'lib/templates/Feature.Dart.model';
import { ItemEx } from 'lib/templates/_.Sitecore.Override';
import { stripHtml, stripHtmlTags } from 'lib/utils/regex';

// Local
import Button from 'helpers/Button/Button';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import LegalDisclaimer from 'helpers/LegalDisclaimer/LegalDisclaimer';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import SVG from 'helpers/SVG/SVG';
import tokensTheme from 'src/theme/tokens.theme';
import fallback from 'lib/fallback/fallback';

interface ObjectStructure {
  bgcolor: string;
}
interface backgroundColor {
  backgroundColor: string;
}

export type CategoryCard = ItemEx & CardComponents.CategoryCard.CategoryCardItem;
export type CategoryCardProps = ComponentProps & CardComponents.CategoryCard.CategoryCardsList;

export type CategoryCardItem = backgroundColor &
  CardComponents.CategoryCard.CategoryCardItem & {
    id?: string;
  };

type grid = '2column' | '3column' | '4column' | undefined;
type AlignCTA = 'BottomCenter' | 'TopRight' | undefined;

const themeVariants = ALL_THEMES.reduce(
  (acc, curr) => ((acc[curr] = {}), acc),
  {} as Record<string, object>
);

const cardSVG = tv({
  base: [
    'absolute',
    'duration-400',
    'ease-in',
    'group-hover:duration-500',
    'group-hover:transition',
    'group-hover:translate-y-[2%]',
    '-top-[2%]',
    'transition-transform',
    'transition',
    'translate-y-[0]',
    'w-full',
    '[&_svg]:h-auto',
    '[&_svg]:w-full',
  ],
  variants: {
    brand: {
      ...themeVariants,
      Off: [
        'left-1/2',
        '-translate-x-[50%]',
        'lg:bottom-[50%]',
        'md:bottom-[58%]',
        'md:-ml-[14px]',
        'md:-mt-[8px]',
        'md:w-[635px]',
      ],
      Autan: [
        'left-1/2',
        '-translate-x-[50%]',
        'lg:bottom-[50%]',
        'md:bottom-[58%]',
        'md:-ml-[14px]',
        'md:-mt-[8px]',
        'md:w-[635px]',
      ],
      AutanDefense: [],
      Ziploc: [
        'right-0',
        '!w-[110%]',
        '[&_svg]:md:h-auto',
        '[&_svg]:md:w-auto',
        'md:left-[-193px]',
        'md:top-[-16px]',
      ],
    },
    size: {
      large: ['hidden', 'md:block'],
      small: ['block', 'md:hidden'],
    },
  },
  compoundVariants: [
    {
      brand: 'Ziploc',
      grid: '2column',
      size: 'large',
      className: ['md:left-auto'],
    },
    {
      brand: 'Off',
      grid: '2column',
      size: 'large',
      className: ['lg:bottom-auto', 'lg:-ml-[0px]', 'lg:top-0', 'lg:w-[165%]', 'md:bottom-[52%]'],
    },
    {
      brand: 'Off',
      grid: '4column',
      size: 'large',
      className: ['lg:bottom-[55%]', 'md:bottom-[63%]'],
    },
  ],
});

const tailwindVariants = tv({
  slots: {
    base: [
      'bg-components-category-card-listing-color-bg',
      'flex',
      'flex-col',
      'px-components-category-card-listing-spacing-small-padding-x',
      'py-components-category-card-listing-spacing-small-padding-y',
      'md:p-components-category-card-listing-spacing-large-padding-y',
    ],
    backgroundColor: [
      'fill-components-category-card-color-category-bg',
      'fill-components-category-card-color-category-bg-2',
      'fill-components-category-card-color-category-bg-3',
      'fill-components-category-card-color-category-bg-4',
      'fill-components-category-card-color-category-bg-5',
      'fill-components-category-card-color-category-bg-6',
      'fill-components-category-card-color-category-bg-7',
      'fill-components-category-card-color-category-bg-8',
      'fill-components-category-card-color-category-bg-9',
      'fill-components-category-card-color-category-bg-10',
      'fill-components-category-card-color-category-bg-11',
      'fill-components-category-card-color-category-bg-12',
      'fill-components-category-card-color-category-bg-13',
      'fill-components-category-card-color-category-bg-14',
      'fill-components-category-card-color-category-bg-15',
      'fill-components-category-card-color-category-bg-16',
      'fill-components-category-card-color-category-bg-17',
      'fill-components-category-card-color-category-bg-18',
      'fill-components-category-card-color-category-bg-19',
      'fill-components-category-card-color-category-bg-20',
    ],
    btn: [
      'hidden',
      '[&>button]:w-[24px]',
      '[&>button>div>span>svg]:!w-[20px]',
      '[&>button>div>span>svg]:!top-0',
      '[&>button]:md:group-hover:bg-components-button-color-filled-brand-hover-bg',
      '[&>button]:md:group-hover:border-components-button-color-filled-brand-hover-stroke',
      'md:block',
    ],
    card: [
      'bg-components-category-card-color-bg',
      'border-2',
      'border-components-category-card-color-stroke',
      'border-solid',
      'flex',
      'flex-col',
      'gap-0',
      'group',
      'items-center',
      'justify-start',
      'overflow-hidden',
      'relative',
      'rounded-themes-radius-small-card',
      'md:rounded-themes-radius-large-card',
      'md:hover:border-components-category-card-color-stroke-hover',
    ],
    cardLinkWrapper: ['flex', 'flex-1', 'flex-col', 'w-full'],
    cardContainer: [
      'flex',
      'flex-row',
      'flex-wrap',
      'gap-components-category-card-listing-spacing-large-card-space-between',
      'h-full',
      'items-center',
      'items-stretch',
      'justify-start',
      'relative',
      'self-stretch',
    ],
    cardSvgWrapper: ['flex', 'w-full', 'flex-1'],
    cardContentContainer: [
      'flex',
      'flex-1',
      'flex-col',
      'h-full',
      'items-center',
      'items-stretch',
      'justify-start',
      'md:gap-spacing-space-between-large-3',
      'relative',
    ],
    cardContentWrapper: [
      'bg-components-category-card-color-content-bg',
      'flex-row',
      'flex',
      'gap-spacing-padding-large-3',
      'items-end',
      'justify-start',
      'p-spacing-spacing-4',
      'relative',
      'self-stretch',
      'w-full',
      'z-10',
      'md:p-spacing-spacing-5',
    ],
    cardDescription: [
      'font-bodySans-small',
      'leading-bodySans-small',
      'text-bodySans-small',
      'line-clamp-1',
      // line-clamp conflicts with the Capsize leading trim
      // these 2 classes resolve the issue and can be removed
      // when leading-trim gets implemented in browser spec
      // https://caniuse.com/?search=leading-trim
      'mb-[-4px]',
      'pb-[4px]',
      'border-b-[4px]',
      'border-transparent',
      'text-components-category-card-color-copy',
      'relative',
      'self-stretch',
    ],
    cardMediaWrapper: [
      'aspect-picture',
      'duration-500',
      'flex-row',
      'flex',
      'gap-2',
      'items-center',
      'justify-center',
      'm-spacing-spacing-4',
      'overflow-hidden',
      'relative',
      'scale-100',
      'self-stretch',
      'transition',
      'z-10',
      'md:group-hover:duration-500',
      'md:group-hover:scale-105',
      'md:group-hover:transition',
      'md:m-8',
    ],
    cardText: ['hidden', 'md:block'],
    cardTitle: [
      'flex-1',
      'font-header-small-xxSmall',
      'leading-header-small-xxSmall',
      'text-header-small-xxSmall',
      'md:font-header-large-xSmall',
      'md:leading-header-large-xSmall',
      'md:text-header-large-xSmall',
      'relative',
      'self-stretch',
      'text-components-category-card-color-title',
      'text-center',
      'md:text-left',
      'tracking-cardSmall',
      'md:tracking-cardLarge',
    ],
    ctaContainer: ['flex', 'md:items-center'],
    headerContainer: [
      'flex',
      'flex-col',
      'md:flex-row',
      'md:gap-components-top-title-spacing-large-body-margin-right',
      'justify-between',
      'w-full',
      'mb-components-top-title-spacing-small-margin-bottom',
      'md:mb-components-top-title-spacing-large-margin-bottom',
    ],
    headerDescriptionText: [
      'font-bodySans-small',
      'leading-bodySans-small',
      'text-bodySans-small',
      'max-md:mb-components-top-title-spacing-small-body-margin-bottom',
      'md:font-bodySans-medium',
      'md:leading-bodySans-medium',
      'md:text-bodySans-medium',
      'text-components-category-card-listing-color-copy',
    ],
    headerTitleContainer: ['flex', 'flex-col'],
    headerTitleText: [
      'font-header-small-large',
      'leading-header-small-large',
      'text-header-small-large',
      'md:font-header-large-large',
      'md:leading-header-large-large',
      'md:text-header-large-large',
      'mb-components-top-title-spacing-small-title-margin-bottom',
      'md:mb-components-top-title-spacing-large-title-margin-bottom',
      'text-components-category-card-listing-color-title',
    ],
    legalDisclaimerText: [
      'mt-spacing-spacing-5',
      'self-start',
      'text-components-category-card-listing-color-copy',
    ],
    media: [],
    svgLarge: ['hidden', 'md:block'],
    svgSmall: ['block', 'md:hidden'],
    title: [
      'font-header-small-large',
      'leading-header-small-large',
      'text-components-category-card-listing-color-title',
      'text-header-small-large',
      'tracking-header-small-large',
      'md:font-header-large-large',
      'md:leading-header-large-large',
      'md:text-header-large-large',
      'md:tracking-header-large-large',
    ],
    titleContainer: [
      'align-center',
      'flex',
      'flex-col',
      'items-stretch',
      'md:gap-components-top-title-spacing-large-body-margin-bottom',
      'md:pb-components-top-title-spacing-large-margin-bottom',
    ],
  },
  variants: {
    alignCTA: {
      BottomCenter: {
        ctaContainer: [
          'align-center',
          'flex',
          'justify-center',
          'w-full',
          '[&>a]:w-full',
          'md:mt-components-category-card-listing-spacing-large-button-margin-top',
          'mt-components-category-card-listing-spacing-small-button-margin-top',
          'md:[&>a]:w-auto',
        ],
        headerTitleContainer: ['text-center', 'w-full'],
      },
      TopRight: {
        ctaContainer: [],
      },
    },
    grid: {
      '2column': {
        card: ['w-[calc(theme(width.1/2)-12px)]'],
        cardMediaWrapper: ['lg:min-h-[480px]'],
      },
      '3column': {
        card: ['w-[calc(theme(width.1/2)-12px)]', 'md:w-[calc(theme(width.1/3)-18px)]'],
      },
      '4column': {
        card: ['w-[calc(theme(width.1/2)-12px)]', 'md:w-[calc(theme(width.1/4)-18px)]'],
      },
    },
  },
});

const CategoryCardListingGrid = ({ ...props }: CategoryCardProps) => {
  const {
    title,
    description,
    primaryCTA,
    enablePattern,
    disclaimerText,
    primaryCTAType,
    primaryCTAColor,
  } = props?.fields || {};
  const { alignCTA, grid } = props?.params || '';
  const { componentName, dataSource } = props?.rendering || {};

  // Add fallback component variant color
  const fallbackComponentVariantColor = fallback?.componentVariants?.value;
  const fallbackComponentVariantType = fallback?.componentVariants?.type;

  /**
   * Filter background colors for the category card background.
   * 1. Get App name
   * 2. Get Colors
   * 3. filter out components-category-card-color-category
   */

  const { themeName, tailwindThemeName } = useTheme();

  // Get colors for specific App name
  const variantGroup = tokensTheme?.brands?.[tailwindThemeName]?.colors;

  // Filter out background
  const filterBackground = (obj: typeof variantGroup) => {
    const result = [];

    for (const key in obj) {
      if (key.includes('components-category-card-color-category')) {
        result.push(`fill-${key}`);
      }
    }

    return result;
  };

  // Call the function
  const variants = filterBackground(variantGroup);

  // map through the key and create new array
  const result: ObjectStructure[] = variants.map((item) => ({ bgcolor: item }));

  // Combine Background Color and Category Props into a single array
  const combinedArray = props?.fields?.categoryCards?.map((category, index) => ({
    ...category,
    backgroundColor: result[index % result.length]?.bgcolor || '',
  }));

  const {
    base,
    headerContainer,
    headerTitleText,
    headerDescriptionText,
    headerTitleContainer,
    ctaContainer,
    btn,
    card,
    cardLinkWrapper,
    cardContainer,
    cardMediaWrapper,
    cardSvgWrapper,
    cardContentWrapper,
    cardContentContainer,
    media,
    cardTitle,
    cardText,
    cardDescription,
    legalDisclaimerText,
    /* eslint-disable  @typescript-eslint/ban-ts-comment */
    // @ts-ignore
  } = tailwindVariants({ grid: grid as grid, alignCTA: alignCTA as AlignCTA, size: 'small' });

  return (
    <div className={base()} data-component="authorable/categorycard/grid">
      {title && (
        <div className={headerContainer()}>
          {title && (
            <div className={headerTitleContainer()}>
              {title?.value && (
                <Text className={headerTitleText()} encode={false} field={title} tag="h2" />
              )}
              {description?.value && (
                <Text
                  className={headerDescriptionText()}
                  encode={false}
                  field={description}
                  tag="p"
                />
              )}
            </div>
          )}
          {primaryCTA?.value?.text && alignCTA == 'TopRight' && (
            <div className={ctaContainer()}>
              <Button
                href={primaryCTA?.value?.href}
                label={primaryCTA?.value.text}
                tag="a"
                target={primaryCTA?.value?.target}
                // The design requires an outline CTA but field name is primaryCTA,
                // so for that we have added a fallback as an outline value,
                // so if there is no value in sitecore field, it will take the outline value.
                type={primaryCTAType?.value || fallbackComponentVariantType}
                color={primaryCTAColor?.value || fallbackComponentVariantColor}
                gtmEvent={{
                  event: 'cta_click',
                  type: 'primary',
                  'gtm.element.dataset.gtmLinkUrl': primaryCTA?.value?.href,
                  'gtm.element.dataset.gtmLinkName': primaryCTA?.value?.text,
                  'gtm.element.dataset.gtmDatasourceId': dataSource,
                  'gtm.element.dataset.gtmComponentName': componentName,
                }}
              />
            </div>
          )}
        </div>
      )}
      <div className={cardContainer()}>
        {combinedArray?.map((cards: CategoryCardItem) => (
          <div className={card()} key={cards?.id}>
            <LinkWrapper
              aria-label={`${stripHtml(cards?.fields?.title?.value as string)}${
                cards?.fields?.description?.value
                  ? ` - ${stripHtmlTags(cards?.fields?.description?.value as string)}`
                  : ''
              }`}
              className={cardLinkWrapper()}
              // className="flex flex-1 flex-col w-full flex-1"
              field={cards?.fields?.ctaLink}
              suppressLinkText
              ignoreEE
            >
              <div className={cardMediaWrapper()}>
                {cards?.fields?.image && (
                  <ImageWrapper
                    field={cards?.fields?.image}
                    layout="responsive"
                    className={media()}
                  />
                )}
              </div>
              <div className={cardSvgWrapper()}>
                <div className={cardContentWrapper()}>
                  <div className={cardContentContainer()}>
                    <div className={cardTitle()}>
                      <Text encode={false} field={cards?.fields?.title} />
                    </div>

                    <div className={cardDescription()}>
                      <RichTextA11yWrapper
                        field={cards?.fields?.description}
                        className={cardText()}
                      />
                    </div>
                  </div>
                  <div className={btn()}>
                    <Button
                      title={stripHtml(cards?.fields?.title?.value as string)}
                      type="filled"
                      iconLeft="arrow_forward"
                    />
                  </div>
                </div>
                {enablePattern?.value && (
                  <>
                    <SVG
                      svg={`CategoryCard/Breakpoint=Small,Brand=${themeName}`}
                      className={`${cardSVG({
                        /* eslint-disable  @typescript-eslint/ban-ts-comment */
                        // @ts-ignore
                        brand: themeName,
                        grid: grid as grid,
                        size: 'small',
                      })} ${cards?.backgroundColor}`}
                    />
                    <SVG
                      svg={`CategoryCard/Breakpoint=Large,Brand=${themeName}`}
                      className={`${cardSVG({
                        /* eslint-disable  @typescript-eslint/ban-ts-comment */
                        // @ts-ignore
                        brand: themeName,
                        grid: grid as grid,
                        size: 'large',
                      })} ${cards?.backgroundColor}`}
                    />
                  </>
                )}
              </div>
            </LinkWrapper>
          </div>
        ))}
      </div>
      {primaryCTA?.value?.text && alignCTA == 'BottomCenter' && (
        <div className={ctaContainer()}>
          <Button
            href={primaryCTA?.value?.href}
            label={primaryCTA?.value.text}
            tag="a"
            target={primaryCTA?.value?.target}
            // The design requires an outline CTA but field name is primaryCTA,
            // so for that we have added a fallback as an outline value,
            // so if there is no value in sitecore field, it will take the outline value.
            type={primaryCTAType?.value || fallbackComponentVariantType}
            color={primaryCTAColor?.value || fallbackComponentVariantColor}
            gtmEvent={{
              event: 'cta_click',
              type: 'primary',
              'gtm.element.dataset.gtmLinkUrl': primaryCTA?.value?.href,
              'gtm.element.dataset.gtmLinkName': primaryCTA?.value?.text,
              'gtm.element.dataset.gtmDatasourceId': dataSource,
              'gtm.element.dataset.gtmComponentName': componentName,
            }}
          />
        </div>
      )}
      {disclaimerText?.value != '' && (
        <LegalDisclaimer
          disclaimerText={disclaimerText}
          disclaimerClasses={legalDisclaimerText()}
        />
      )}
    </div>
  );
};
export default CategoryCardListingGrid;

// Global
import { Options, Splide, SplideSlide } from '@splidejs/react-splide';
import React, { useEffect, useState, forwardRef, ForwardedRef } from 'react';
import { tv } from 'tailwind-variants';

// Lib
import { ProductAndArticleComponent } from 'lib/templates/Feature.Dart.model';

// Local
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import Button from 'helpers/Button/Button';
import SVG from 'helpers/SVG/SVG';
import Video from 'helpers/Video/Video';

export type ProductInformationProps =
  ProductAndArticleComponent.ProductInformation.ProductInformation;

export type SecondaryImages = ProductAndArticleComponent.ProductInformation.SecondaryImage &
  ProductAndArticleComponent.ProductInformation.SecondaryVideo;

interface productCarouselProps {
  mainSliderRef?: React.RefObject<Splide>;
  thumbnailSliderRef?: React.RefObject<Splide>;
  props?: ProductInformationProps;
  mainSlider?: Options;
  thumbSlider?: Options;
}

type Props = productCarouselProps;

const tailwindconstiants = tv({
  slots: {
    productInformationTopSlider: [
      'md:max-w-[630px]',
      'w-full',
      'mb-components-product-information-spacing-small-image-margin-bottom',
      'md:mb-components-product-information-spacing-large-image-margin-bottom',
    ],
    ProductCarousel: ['product-carousel'],
    productInformationBottomSlider: [
      'md:max-w-[630px]',
      'w-full',
      'mb-spacing-space-between-large-4',
      'thumbnail-slider',
    ],
    productInformationProgressBar: [
      'progress-bar',
      'h-[4px]',
      'w-[160px]',
      'bg-components-pagination-on-white-bg',
      'rounded-components-pagination-scrollbar-radius',
    ],
    productInformationProgress: [
      'bg-components-pagination-on-white-accent-scroll',
      'h-[4px]',
      'rounded-components-pagination-scrollbar-radius',
    ],
    productRoundedImages: [
      'product-primary-image',
      'article-author-image',
      'rounded-themes-radius-small-image',
      'md:rounded-themes-radius-large-image',
      '!h-full',
      'block',
      'object-cover',
      '!w-full',
      'aspect-[5/4]',
    ],
    productInformationImages: [
      '!h-full',
      'block',
      'object-cover',
      'rounded-themes-radius-small-image',
    ],
    productInformationGradient: [
      'absolute',
      'inset-0',
      'bg-components-media-video-thumbnail-overlay-bg',
    ],
    productInformationVideoThumbnail: [
      'absolute',
      'top-1/2',
      'left-1/2',
      'transform -translate-x-1/2 -translate-y-1/2',
      'h-[50px]',
      'w-[50px]',
      '[&>*]:h-full',
      '[&>*]:w-full',
      'fill-components-product-information-color-play-icon',
    ],
    productInformationProgressbarWrapper: [
      'flex',
      'flex-row',
      'items-center',
      'justify-center',
      'gap-4',
    ],
    productInformationSplideControls: ['splide__arrows', 'flex', 'flex-row', 'gap-2'],
    productInformationVideo: [
      'h-full',
      '[&>*]:!h-full',
      '[&>*]:!w-full',
      '[&>*]:!rounded-themes-radius-small-image',
      'aspect-video',
      'rounded-themes-radius-small-image',
      'md:rounded-themes-radius-large-image',
      'w-full',
      'max-w-[1200px]',
      'my-0',
      'mx-auto',
    ],
    btn: [
      'md:[&>button]:w-8',
      'md:[&>button]:h-8',
      '[&>button]:w-[40px]',
      '[&>button]:h-[40px]',
      '[&>button]:sm:py-[0.1.5rem]',
      '[&>button]:sm:px-[0.1.5rem]',
      '[&>button]:py-[0.7rem]',
      '[&>button]:px-[0.7rem]',
      'group',
      '[&>button]:!min-h-0',
    ],
  },
});

const ProductCarousel = forwardRef(
  (
    { mainSliderRef, thumbnailSliderRef, props, mainSlider, thumbSlider }: Props,
    ref: ForwardedRef<HTMLDivElement>
  ): JSX.Element => {
    const { primaryImage, secondaryImages } = props?.fields || {};
    const [progressWidth, setProgressWidth] = useState('0%');
    const [firstSlideActive, setFirstSlideActive] = useState<boolean>(true);
    const [lastSlideActive, setLastSlideActive] = useState<boolean>(false);

    useEffect(() => {
      if (mainSliderRef?.current && thumbnailSliderRef?.current) {
        mainSliderRef.current.sync(thumbnailSliderRef?.current?.splide as never);
      }
    }, [mainSliderRef, thumbnailSliderRef]);

    const {
      ProductCarousel,
      productInformationTopSlider,
      productInformationBottomSlider,
      productInformationProgressBar,
      productInformationProgress,
      productInformationGradient,
      productRoundedImages,
      productInformationImages,
      productInformationVideoThumbnail,
      productInformationProgressbarWrapper,
      productInformationSplideControls,
      productInformationVideo,
      btn,
    } = tailwindconstiants({});

    return (
      <div className={ProductCarousel()} ref={ref}>
        <Splide
          ref={mainSliderRef}
          options={mainSlider}
          className={productInformationTopSlider()}
          onMounted={(splide) => {
            const end = splide?.Components?.Controller?.getEnd() + 1;
            const rate = Math.min((splide?.index + 1) / end, 1);
            setProgressWidth(`${100 * rate}%`);
            splide?.index === 0 ? setFirstSlideActive(true) : setFirstSlideActive(false);
            splide?.index === end - 1 ? setLastSlideActive(true) : setLastSlideActive(false);
          }}
          onMove={(splide) => {
            const end = splide?.Components?.Controller?.getEnd() + 1;
            const rate = Math.min((splide?.index + 1) / end, 1);
            setProgressWidth(`${100 * rate}%`);
            splide?.index === 0 ? setFirstSlideActive(true) : setFirstSlideActive(false);
            splide?.index === end - 1 ? setLastSlideActive(true) : setLastSlideActive(false);
          }}
        >
          {primaryImage && primaryImage?.value && (
            <SplideSlide>
              <ImageWrapper field={primaryImage} className={productRoundedImages()}></ImageWrapper>
            </SplideSlide>
          )}

          {secondaryImages?.map((item: SecondaryImages, index: number) =>
            item?.fields?.videoId?.value ? (
              <SplideSlide key={index}>
                <Video field={item?.fields?.videoId} class={productInformationVideo()} />
              </SplideSlide>
            ) : (
              item?.fields?.image && (
                <SplideSlide key={index}>
                  <ImageWrapper
                    field={item?.fields?.image}
                    className={productRoundedImages()}
                  ></ImageWrapper>
                </SplideSlide>
              )
            )
          )}
        </Splide>
        {secondaryImages && secondaryImages.length >= 1 && (
          <>
            <Splide
              ref={thumbnailSliderRef}
              options={thumbSlider}
              className={productInformationBottomSlider()}
            >
              {primaryImage && primaryImage?.value && (
                <SplideSlide>
                  <ImageWrapper
                    field={primaryImage}
                    className={productInformationImages()}
                  ></ImageWrapper>
                </SplideSlide>
              )}
              {secondaryImages &&
                secondaryImages?.map((item: SecondaryImages, index: number) => {
                  if (
                    item?.fields?.videoId?.value &&
                    item?.fields?.videoThumbnail?.value &&
                    !item?.fields?.videoThumbnail?.value.src
                  ) {
                    item.fields.videoThumbnail.value.src = `https://img.youtube.com/vi/${item.fields?.videoId?.value}/hqdefault.jpg`;
                  }

                  return item?.fields?.videoId ? (
                    <SplideSlide key={index}>
                      <ImageWrapper
                        field={item?.fields?.videoThumbnail}
                        layout="fill"
                        className={productInformationImages()}
                      ></ImageWrapper>
                      <div className={productInformationGradient()}></div>
                      <SVG
                        className={productInformationVideoThumbnail()}
                        svg={`play-icon/playicon`}
                      />
                    </SplideSlide>
                  ) : (
                    item?.fields?.image && (
                      <SplideSlide key={index}>
                        <ImageWrapper
                          field={item?.fields?.image}
                          className={productInformationImages()}
                        ></ImageWrapper>
                      </SplideSlide>
                    )
                  );
                })}
            </Splide>
            <div className={productInformationProgressbarWrapper()}>
              <div className={productInformationProgressBar()}>
                <div
                  className={productInformationProgress()}
                  style={{ width: progressWidth }}
                ></div>
              </div>
              <div className={productInformationSplideControls()}>
                <div className={`${btn()}`}>
                  <Button
                    onClick={() => mainSliderRef?.current?.splide?.go('-1')}
                    type={'outline'}
                    iconLeft="chevron_left"
                    disabled={firstSlideActive}
                    title="Previous"
                  ></Button>
                </div>
                <div className={`${btn()} ${lastSlideActive ? '[&>*]:disable' : ''}`}>
                  <Button
                    onClick={() => mainSliderRef?.current?.splide?.go('+1')}
                    type={'outline'}
                    iconLeft="chevron_right"
                    disabled={lastSlideActive}
                    title="Next"
                  ></Button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
);

export default ProductCarousel;

ProductCarousel.displayName = 'ProductCarousel';

// Global
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import { tv } from 'tailwind-variants';

// Lib
import { useTheme } from 'lib/context/ThemeContext';
import { CardComponents } from 'lib/templates/Feature.Dart.model';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import Button from 'helpers/Button/Button';
import LegalDisclaimer from 'helpers/LegalDisclaimer/LegalDisclaimer';
import MultiColorBorder from 'helpers/MultiColorBorder/MultiColorBorder';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import SVG from 'helpers/SVG/SVG';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import fallback from 'lib/fallback/fallback';
import { ComponentVariants } from 'lib/context/ComponentVariants';

export type IconCardListProps = CardComponents.IconCard.IconCardList & {
  params: { [key: string]: string };
};

export type IconCardItem = CardComponents.IconCard.IconCardItem & {
  id?: string;
};

type AlignCTA = 'BottomCenter' | 'TopRight' | undefined;
type IconLocation = 'SideLeft' | 'TopCenter' | 'TopLeft' | undefined;
type Grid = '2column' | '3column' | '4column' | undefined;
type Theme = 'Dark' | 'Light' | undefined;
type iConRatio = 'Large' | 'Small' | undefined;

const tailwindVariants = tv({
  defaultVariants: {
    grid: '4column',
    iconRatio: 'Small',
  },
  slots: {
    base: [
      'flex-col',
      'flex',
      'items-center',
      'justify-center',
      'text-components-icon-card-listing-color-bg',
      'px-components-icon-card-listing-spacing-small-padding-x',
      'py-components-icon-card-listing-spacing-small-padding-y',
      'md:px-components-icon-card-listing-spacing-large-padding-x',
      'md:py-components-icon-card-listing-spacing-large-padding-y',
      'relative',
    ],
    componentBG: ['bg-auto', 'bg-components-icon-card-listing-color-bg'],
    cardBodyContainer: ['flex', 'flex-col', 'h-full'],
    cardBodyTextContainer: [
      'flex',
      'flex-col',
      'flex-1',
      'gap-components-icon-card-spacing-large-title-margin-bottom',
      'mb-components-icon-card-spacing-large-copy-margin-bottom',
    ],
    cardContainer: ['flex', 'flex-col', 'bg-components-icon-card-listing-color-inner-bg'],
    cardCtaContainer: [
      'flex',
      'flex-wrap',
      'gap-components-icon-card-spacing-large-button-space-between',
    ],
    cardCtaPrimaryContainer: [],
    cards: ['flex-1'],
    cardIcon: [
      '[&>svg]:fill-components-icon-card-color-icon-bg',
      '[&>svg]:w-components-icon-card-dimensions-large-big-bg-width',
      '[&>svg]:h-components-icon-card-dimensions-large-big-bg-height',
    ],
    cardIconContainer: [
      'flex',
      'justify-center',
      'items-center',
      'mb-components-icon-card-spacing-small-icon-margin',
      'md:mb-components-icon-card-spacing-large-icon-margin',
      'min-h-components-icon-card-dimensions-large-big-bg-height',
      'w-components-icon-card-dimensions-large-big-bg-width',
      'rounded-themes-radius-large-icon',
    ],
    cardListContainer: [
      'gap-components-icon-card-listing-spacing-small-card-space-between',
      'grid',
      'w-full',
      'md:gap-components-icon-card-listing-spacing-large-card-space-between',
      'sm:grid-cols-2',
    ],
    cardTitleText: [
      'font-header-small-xSmall',
      'text-header-small-xSmall',
      'text-components-icon-card-color-title',
      'leading-header-small-xSmall',
      'md:font-header-large-xSmall',
      'md:leading-header-large-xSmall',
      'md:text-header-large-xSmall',
    ],
    cardSubtitleText: [
      'font-header-small-xxSmall',
      'text-header-small-xxSmall',
      'text-components-icon-card-color-subtitle',
      'leading-header-small-xxSmall',
      'md:font-header-large-xxSmall',
      'md:leading-header-large-xxSmall',
      'md:text-header-large-xxSmall',
      //'line-clamp-2', // Removing as of now as per https://horizontal.atlassian.net/browse/SJ-1120
      // line-clamp conflicts with the Capsize leading trim
      // these 2 classes resolve the issue and can be removed
      // when leading-trim gets implemented in browser spec
      // https://caniuse.com/?search=leading-trim
      'pb-[3px]',
      'mb-[-3px]',
    ],
    cardDescriptionText: [
      'font-bodySans-small',
      'text-bodySans-small',
      'text-components-icon-card-color-description',
      'leading-bodySans-small',
      //'line-clamp-4', // Removing as of now as per https://horizontal.atlassian.net/browse/SJ-1120
      'flex-1',
      // line-clamp conflicts with the Capsize leading trim
      // these 2 classes resolve the issue and can be removed
      // when leading-trim gets implemented in browser spec
      // https://caniuse.com/?search=leading-trim
      'pb-[4px]',
      'mb-[-4px]',
    ],
    container: [],
    ctaContainer: ['flex', 'items-center'],
    headerContainer: [
      'flex-wrap',
      'md:flex-nowrap',
      'flex',
      'justify-between',
      'mb-components-top-title-spacing-small-margin-bottom',
      'w-full',
      'md:gap-components-top-title-spacing-large-body-margin-right',
      'md:mb-components-top-title-spacing-large-margin-bottom',
    ],
    headerTitleContainer: [
      'flex-col',
      'flex',
      'lg:mb-0',
      'mb-components-top-title-spacing-small-body-margin-bottom',
    ],
    headerTitleText: [
      'font-header-small-large',
      'leading-header-small-large',
      'text-header-small-large',
      'text-components-icon-card-listing-color-title',
      'md:font-header-large-large',
      'md:leading-header-large-large',
      'md:text-header-large-large',
      'mb-components-top-title-spacing-small-title-margin-bottom',
      'md:mb-components-top-title-spacing-large-title-margin-bottom',
    ],
    headerDescriptionText: [
      'font-bodySans-small',
      'leading-bodySans-small',
      'text-bodySans-small',
      'text-components-icon-card-listing-color-copy',
      'md:font-bodySans-medium',
      'md:leading-bodySans-medium',
      'md:text-bodySans-medium',
    ],

    legalDisclaimerText: [
      'pt-spacing-spacing-5',
      'self-start',
      '!text-components-legal-disclaimer-color-default-body',
    ],
    svgBgLeft: ['absolute', 'hidden', 'left-0', 'top-0', 'md:block'],
    svgBgRight: ['absolute', 'hidden', 'right-0', 'top-0', 'md:block'],
    wrapper: ['flex', 'flex-col', 'items-center', 'self-stretch'],
  },
  variants: {
    alignCTA: {
      BottomCenter: {
        ctaContainer: [
          'pt-components-icon-card-listing-spacing-small-button-margin-top',
          'md:pt-components-icon-card-listing-spacing-large-button-margin-top',
        ],
        headerContainer: ['!justify-center', 'text-center'],
      },
      TopRight: {
        headerContainer: [''],
      },
    },
    bgImageVariant: {
      true: {
        wrapper: [
          'bg-components-icon-card-listing-color-inner-bg',
          'rounded-themes-radius-small-general',
          'sm:rounded-themes-radius-large-general',
          'md:py-components-icon-card-listing-spacing-large-padding-inner-y',
          'md:px-components-icon-card-listing-spacing-large-padding-inner-x',
          'py-components-icon-card-listing-spacing-small-padding-inner-y',
          'px-components-icon-card-listing-spacing-small-padding-inner-x',
        ],
      },
      false: {},
    },
    grid: {
      '2column': {
        cardListContainer: ['md:grid-cols-2'],
      },
      '3column': {
        cardListContainer: ['md:grid-cols-3'],
      },
      '4column': {
        cardListContainer: ['md:grid-cols-4'],
      },
    },
    iconLocation: {
      SideLeft: {
        cardContainer: ['sm:flex-row'],
        cardIconContainer: [
          'mr-components-icon-card-spacing-large-icon-margin',
          'sm:min-w-components-icon-card-dimensions-large-tiny-bg-width',
          'sm:max-w-components-icon-card-dimensions-large-tiny-bg-width',
          'sm:max-h-components-icon-card-dimensions-large-tiny-bg-width',
          'sm:min-h-0',
        ],
        cardIcon: [
          'sm:[&>svg]:w-components-icon-card-dimensions-large-tiny-bg-width',
          'sm:[&>svg]:h-components-icon-card-dimensions-large-tiny-bg-height',
        ],
        cardListContainer: ['md:!grid-cols-2'],
      },
      TopCenter: {
        cardBodyContainer: ['justify-top', '[&>*]:text-center'],
        cardCtaContainer: ['justify-center', '[&>*]:text-center'],
        cardIconContainer: ['justify-center', 'self-center'],
        componentBG: ['bg-center', 'bg-no-repeat'],
      },
      TopLeft: {},
    },
    theme: {
      Dark: {
        componentBG: ['bg-components-icon-card-listing-color-brand-bg'],
        wrapper: [
          'bg-components-icon-card-listing-color-inner-bg',
          'px-components-icon-card-listing-spacing-small-padding-inner-x',
          'py-components-icon-card-listing-spacing-small-padding-inner-y',
          'rounded-themes-radius-small-general',
          'md:px-components-icon-card-listing-spacing-large-padding-inner-x',
          'md:py-components-icon-card-listing-spacing-large-padding-inner-y',
          'md:rounded-themes-radius-large-general',
        ],
      },
      Light: {},
    },
    overrideSVGColor: {
      true: {
        cardIcon: '[&>svg>path]:fill-colors-brand-1-400',
      },
    },
    iconRatio: {
      Small: {
        cardIcon: [
          '[&>svg]:w-components-icon-card-dimensions-large-big-bg-width',
          '[&>svg]:h-components-icon-card-dimensions-large-big-bg-height',
        ],
      },
      Large: {
        cardIcon: [
          '[&>svg]:!w-[calc(theme(width.components-icon-card-dimensions-large-big-bg-width)*2)]',
        ],
        cardIconContainer: [
          '!w-[calc(theme(width.components-icon-card-dimensions-large-big-bg-width)*2)]',
          'min-h-[calc(theme(height.components-icon-card-dimensions-large-big-bg-height)*2)',
        ],
      },
    },
  },
});

// Add fallback component variant color
const fallbackComponentVariantColor = fallback?.componentVariants?.value;
const fallbackComponentVariantType = fallback?.componentVariants?.type;

const IconCard = (props: IconCardListProps): JSX.Element => {
  const {
    description,
    iconCards,
    primaryCTA,
    primaryCTAColor,
    primaryCTAType,
    title,
    multiColorBar,
    disclaimerText,
    overrideSVGWithBrandColor,

    enablePattern,
  } = props?.fields || {};
  const { alignCTA, alignIcon, backgroundImage, grid, theme } = props?.params || {};

  const componentVariants = ComponentVariants();
  const { themeName } = useTheme();

  if (!props.fields) return <>Icon Card Component</>;

  /**
   * check for the backgound image
   * 1. Apply background image if icon is set for TopCenter
   * 2. BackgroundImage is coming from the params as follows
   *  2.1 "backgroundImage": "<image mediaid=\"{942F5F16-8F99-484B-9487-FC4AAD14AB35}\" mediaurl=\"https://edge.sitecorecloud.io/ImageName.jpg\" />",
   *  2.2 extract mediaurl using regex
   */
  let bgImage;
  let bgImageVariant = false;

  if (backgroundImage && alignIcon === 'TopCenter') {
    const regex = /mediaurl="([^"]+)"/;
    const extractImage = backgroundImage.match(regex);
    if (extractImage) {
      bgImage = extractImage[1];
      bgImageVariant = true;
    } else {
      bgImage = '';
      bgImageVariant = false;
    }
  }
  // check if the configuration is set to override SVG with brand color.
  // If yes then override SVG color with the brand Color.
  const overrideSVGColor = overrideSVGWithBrandColor?.value;

  // check if icon ratio is set to large | small to set the width of the icon

  const {
    base,
    cards,
    cardBodyTextContainer,
    cardCtaPrimaryContainer,
    cardDescriptionText,
    cardListContainer,
    cardSubtitleText,
    cardTitleText,
    componentBG,
    container,
    ctaContainer,
    headerContainer,
    headerDescriptionText,
    headerTitleContainer,
    headerTitleText,
    legalDisclaimerText,
    svgBgLeft,
    svgBgRight,
    wrapper,
  } = tailwindVariants({
    alignCTA: alignCTA as AlignCTA,
    bgImageVariant,

    grid: grid as Grid,
    theme: theme as Theme,
  });

  const id = props?.params?.RenderingIdentifier;

  return (
    <div
      className={componentBG()}
      style={bgImage ? { backgroundImage: `url(${bgImage})` } : undefined}
      id={id ? id : undefined}
      tabIndex={id ? -1 : 1}
    >
      {/* Render top part of the border */}
      {multiColorBar?.value && componentVariants?.multipleBar?.top && (
        <MultiColorBorder
          multipleBar="top"
          skewXdeg={componentVariants?.multipleBar?.skewX?.value}
        />
      )}
      <Container className={container()}>
        <div data-component="authorable/cards/iconcard" data-testid="iconcard">
          <div className={base()}>
            {enablePattern?.value && (
              <>
                <SVG className={svgBgLeft()} svg={`IconCard/Bg=Left,Brand=${themeName}`} />
                <SVG className={svgBgRight()} svg={`IconCard/Bg=Right,Brand=${themeName}`} />
              </>
            )}
            <div className={wrapper()}>
              {/* Icon Card Title and Description Starts */}
              {title && (
                <div className={headerContainer()}>
                  {title && (
                    <div className={headerTitleContainer()}>
                      {title?.value && (
                        <Text className={headerTitleText()} encode={false} field={title} tag="h2" />
                      )}
                      {description?.value && (
                        <Text
                          className={headerDescriptionText()}
                          encode={false}
                          field={description}
                        />
                      )}
                    </div>
                  )}
                  {alignCTA == 'TopRight' && primaryCTA?.value?.href !== '' && (
                    <div className={ctaContainer()}>
                      <Button
                        href={primaryCTA?.value?.href}
                        label={primaryCTA?.value.text}
                        tag="a"
                        target={primaryCTA?.value?.target}
                        // The design requires an outline CTA but field name is primaryCTA,
                        // so for that we have added a fallback as an outline value,
                        // so if there is no value in sitecore field, it will take the outline value
                        type={primaryCTAType?.value || fallbackComponentVariantType}
                        color={primaryCTAColor?.value || fallbackComponentVariantColor}
                      />
                    </div>
                  )}
                </div>
              )}
              {/* Icon Card List Starts */}
              <div className={cardListContainer()}>
                {iconCards?.map((card: IconCardItem) => {
                  const iconRatioValue = card?.fields?.iconRatio?.value
                    ? card?.fields?.iconRatio?.value
                    : 'Small';
                  const {
                    cardIcon,
                    cardIconContainer,
                    cardBodyContainer,
                    cardCtaContainer,
                    cardContainer,
                  } = tailwindVariants({
                    iconRatio: iconRatioValue as iConRatio,
                    overrideSVGColor: overrideSVGColor,
                    iconLocation: alignIcon as IconLocation,
                  });
                  return (
                    <>
                      <div className={cardContainer()} key={card?.id}>
                        <div className={cardIconContainer()}>
                          {card?.fields?.icon?.value?.src &&
                            card?.fields?.icon?.value?.extension === 'svg' && (
                              <SVG
                                className={cardIcon()}
                                svg="outline"
                                url={card?.fields?.icon?.value?.src}
                              />
                            )}
                          {card?.fields?.icon?.value?.src &&
                            card?.fields?.icon?.value?.extension === 'png' && (
                              <ImageWrapper field={card?.fields?.icon} />
                            )}
                        </div>
                        <div className={cardBodyContainer()}>
                          <div className={cardBodyTextContainer()}>
                            {card?.fields?.title?.value && (
                              <Text
                                className={cardTitleText()}
                                encode={false}
                                field={card.fields?.title}
                                tag="p"
                              />
                            )}
                            {card?.fields?.subtitle?.value && (
                              <Text
                                className={cardSubtitleText()}
                                encode={false}
                                field={card.fields?.subtitle}
                                tag="p"
                              />
                            )}
                            {card?.fields?.description?.value && (
                              <div className={cards()}>
                                <RichTextA11yWrapper
                                  className={cardDescriptionText()}
                                  field={card.fields?.description}
                                />
                              </div>
                            )}
                          </div>
                          <div className={cardCtaContainer()}>
                            {card?.fields?.primaryCTA?.value?.text && (
                              <div className={cardCtaPrimaryContainer()}>
                                <Button
                                  label={card?.fields?.primaryCTA?.value?.text}
                                  // The design requires an outline CTA but field name is primaryCTA,
                                  // so for that we have added a fallback as an outline value,
                                  // so if there is no value in sitecore field, it will take the outline value
                                  type={
                                    card?.fields?.primaryCTAType?.value ||
                                    fallbackComponentVariantType
                                  }
                                  color={
                                    card?.fields?.primaryCTAColor?.value ||
                                    fallbackComponentVariantColor
                                  }
                                  tag="a"
                                  target={card?.fields?.primaryCTA?.value?.target}
                                  href={card?.fields?.primaryCTA?.value?.href}
                                  gtmEvent={{
                                    event: 'cta_click',
                                    type: 'primary',
                                    'gtm.element.dataset.gtmLinkUrl':
                                      card?.fields?.primaryCTA?.value?.href,
                                    'gtm.element.dataset.gtmLinkName':
                                      card?.fields?.primaryCTA?.value?.title,
                                    'gtm.element.dataset.gtmDatasourceId': card.id,
                                    'gtm.element.dataset.gtmComponentName':
                                      card?.fields?.title?.value,
                                  }}
                                />
                              </div>
                            )}
                            {card?.fields?.secondaryCTA?.value.text && (
                              <Button
                                label={card?.fields?.secondaryCTA?.value?.text}
                                type={card?.fields?.secondaryCTAType?.value || 'text'}
                                color={
                                  card?.fields?.secondaryCTAColor?.value ||
                                  fallbackComponentVariantColor
                                }
                                tag="a"
                                target={card?.fields?.secondaryCTA?.value?.target}
                                href={card?.fields?.secondaryCTA?.value?.href}
                                gtmEvent={{
                                  event: 'cta_click',
                                  type: 'secondary',
                                  'gtm.element.dataset.gtmLinkUrl':
                                    card?.fields?.secondaryCTA?.value?.href,
                                  'gtm.element.dataset.gtmLinkName':
                                    card?.fields?.secondaryCTA?.value?.title,
                                  'gtm.element.dataset.gtmDatasourceId': card.id,
                                  'gtm.element.dataset.gtmComponentName':
                                    card?.fields?.title?.value,
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
              {alignCTA == 'BottomCenter' && (
                <div>
                  <>
                    {primaryCTA?.value?.href !== '' && (
                      <div className={ctaContainer()}>
                        <Button
                          href={primaryCTA?.value?.href}
                          label={primaryCTA?.value.text}
                          tag="a"
                          target={primaryCTA?.value?.target}
                          // The design requires an outline CTA but field name is primaryCTA,
                          // so for that we have added a fallback as an outline value,
                          // so if there is no value in sitecore field, it will take the outline value
                          type={primaryCTAType?.value || fallbackComponentVariantType}
                          color={primaryCTAColor?.value || fallbackComponentVariantColor}
                          gtmEvent={{
                            event: 'cta_click',
                            type: 'primary',
                            'gtm.element.dataset.gtmLinkUrl': primaryCTA?.value?.href,
                            'gtm.element.dataset.gtmLinkName': primaryCTA?.value?.text,
                          }}
                        />
                      </div>
                    )}
                  </>
                </div>
              )}
            </div>
            {disclaimerText?.value != '' && (
              <LegalDisclaimer
                disclaimerText={disclaimerText}
                disclaimerClasses={legalDisclaimerText()}
              />
            )}
          </div>
        </div>
      </Container>

      {/* Render bottom part of the border */}
      {multiColorBar?.value && componentVariants?.multipleBar?.bottom && (
        <MultiColorBorder
          multipleBar="bottom"
          skewXdeg={componentVariants?.multipleBar?.skewX?.value}
        />
      )}
    </div>
  );
};

export default IconCard;
